"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderTo = exports.Workspace = void 0;
var tslib_1 = require("tslib");
var react_1 = require("react");
var ReactDOM = require("react-dom");
var saveAs = require("file-saverjs");
var commands_1 = require("../diagram/commands");
var history_1 = require("../diagram/history");
var view_1 = require("../diagram/view");
var asyncModel_1 = require("../editor/asyncModel");
var authoringState_1 = require("../editor/authoringState");
var editorController_1 = require("../editor/editorController");
var events_1 = require("../viewUtils/events");
var toSvg_1 = require("../viewUtils/toSvg");
var navigator_1 = require("../widgets/navigator");
var toolbar_1 = require("./toolbar");
var workspaceMarkup_1 = require("./workspaceMarkup");
var workspaceContext_1 = require("./workspaceContext");
var layout_1 = require("../viewUtils/layout");
var sparqlDataProvider_1 = require("../data/sparql/sparqlDataProvider");
var GRAPH_EXPLORER_WEBSITE = 'https://graph-explorer.org/';
var GRAPH_EXPLORER_LOGO_SVG = undefined;
function saveLayoutToLocalStorage(diagram) {
    var randomKey = Math.floor((1 + Math.random()) * 0x10000000000)
        .toString(16)
        .substring(1);
    localStorage.setItem(randomKey, JSON.stringify(diagram));
    return randomKey;
}
var Workspace = /** @class */ (function (_super) {
    tslib_1.__extends(Workspace, _super);
    function Workspace(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        _this.lastMousePosition = { x: 75, y: 40 };
        _this._watermarkSvg = GRAPH_EXPLORER_LOGO_SVG;
        _this._watermarkUrl = GRAPH_EXPLORER_WEBSITE;
        _this.onBodyPaste = function (e) {
            if (e.target === document.body) {
                //e.preventDefault();
                var paste = (e.clipboardData || window.clipboardData).getData("text");
                var editor = _this.editor;
                editor.onDragDrop([paste], _this.lastMousePosition);
                //this.props.view.model.createElement(paste);
            }
        };
        _this.zoomToFit = function () {
            _this.markup.paperArea.zoomToFit();
        };
        _this.zoomToFitRect = function (bbox) {
            _this.markup.paperArea.zoomToFitRect(bbox);
        };
        _this.clearAll = function () {
            _this.editor.removeItems(tslib_1.__spreadArray([], _this.model.elements, true));
        };
        _this.forceLayout = function () {
            var batch = _this.model.history.startBatch('Force layout');
            batch.history.registerToUndo(commands_1.RestoreGeometry.capture(_this.model));
            (0, layout_1.applyLayout)(_this.model, (0, layout_1.forceLayout)({ model: _this.model }));
            for (var _i = 0, _a = _this.model.links; _i < _a.length; _i++) {
                var link = _a[_i];
                link.setVertices([]);
            }
            batch.store();
        };
        _this.exportSvg = function (fileName) {
            _this.markup.paperArea.exportSVG().then(function (svg) {
                fileName = fileName || 'diagram.svg';
                var xmlEncodingHeader = '<?xml version="1.0" encoding="UTF-8"?>';
                var blob = new Blob([xmlEncodingHeader + svg], {
                    type: 'image/svg+xml',
                });
                saveAs(blob, fileName);
            });
        };
        _this.exportPng = function (fileName) {
            fileName = fileName || 'diagram.png';
            _this.markup.paperArea
                .exportPNG({ backgroundColor: 'white' })
                .then(function (dataUri) {
                var blob = (0, toSvg_1.dataURLToBlob)(dataUri);
                saveAs(blob, fileName);
            });
        };
        _this.undo = function () {
            _this.model.history.undo();
        };
        _this.redo = function () {
            _this.model.history.redo();
        };
        _this.zoomBy = function (value) {
            _this.markup.paperArea.zoomBy(value);
        };
        _this.zoomIn = function () {
            _this.markup.paperArea.zoomIn();
        };
        _this.zoomOut = function () {
            _this.markup.paperArea.zoomOut();
        };
        _this.print = function () {
            _this.markup.paperArea.exportSVG().then(function (svg) {
                var printWindow = window.open('', undefined, 'width=1280,height=720');
                printWindow.document.write(svg);
                printWindow.document.close();
                printWindow.print();
            });
        };
        _this.changeLanguage = function (language) {
            var onLanguageChange = _this.props.onLanguageChange;
            // if language is in controlled mode we'll just forward the change
            if (onLanguageChange) {
                onLanguageChange(language);
            }
            else {
                _this.view.setLanguage(language);
                // since we have toolbar dependent on language, we're forcing update here
                _this.forceUpdate();
            }
        };
        _this.centerTo = function (paperPosition) {
            _this.markup.paperArea.centerTo(paperPosition);
        };
        var _a = _this.props, hideHalo = _a.hideHalo, history = _a.history, _b = _a.viewOptions, viewOptions = _b === void 0 ? {} : _b, metadataApi = _a.metadataApi, validationApi = _a.validationApi, propertyEditor = _a.propertyEditor, elementTemplateResolver = _a.elementTemplateResolver, linkTemplateResolver = _a.linkTemplateResolver, typeStyleResolver = _a.typeStyleResolver, selectLabelLanguage = _a.selectLabelLanguage;
        var linkRouter = viewOptions.linkRouter, onIriClick = viewOptions.onIriClick, disableDefaultHalo = viewOptions.disableDefaultHalo, suggestProperties = viewOptions.suggestProperties, groupBy = viewOptions.groupBy;
        _this.model = new asyncModel_1.AsyncModel(history || new history_1.NonRememberingHistory(), groupBy || []);
        _this.view = new view_1.DiagramView(_this.model, {
            elementTemplateResolver: elementTemplateResolver,
            linkTemplateResolver: linkTemplateResolver,
            typeStyleResolver: typeStyleResolver,
            selectLabelLanguage: selectLabelLanguage,
            linkRouter: linkRouter,
            onIriClick: onIriClick,
        });
        _this.editor = new editorController_1.EditorController({
            model: _this.model,
            view: _this.view,
            disableHalo: hideHalo || disableDefaultHalo,
            suggestProperties: suggestProperties,
            validationApi: validationApi,
            propertyEditor: propertyEditor,
        });
        _this.editor.setMetadataApi(metadataApi);
        _this.view.setLanguage(_this.props.language);
        _this.state = {};
        return _this;
    }
    Workspace.prototype._getPaperArea = function () {
        return this.markup ? this.markup.paperArea : undefined;
    };
    Workspace.prototype._setWatermark = function (watermarkSvg, watermarkUrl) {
        this._watermarkSvg = watermarkSvg;
        this._watermarkUrl = watermarkUrl;
        this.forceUpdate();
    };
    Workspace.prototype.render = function () {
        var _this = this;
        var _a = this.props, hidePanels = _a.hidePanels, hideToolbar = _a.hideToolbar, metadataApi = _a.metadataApi, hideScrollBars = _a.hideScrollBars, onWorkspaceEvent = _a.onWorkspaceEvent, _elementsSearchPanel = _a._elementsSearchPanel;
        return (0, react_1.createElement)(workspaceMarkup_1.WorkspaceMarkup, {
            ref: function (markup) {
                _this.markup = markup;
            },
            hidePanels: hidePanels,
            hideToolbar: hideToolbar,
            hideScrollBars: hideScrollBars,
            model: this.model,
            view: this.view,
            editor: this.editor,
            metadataApi: metadataApi,
            leftPanelInitiallyOpen: this.props.leftPanelInitiallyOpen,
            rightPanelInitiallyOpen: this.props.rightPanelInitiallyOpen,
            searchCriteria: this.state.criteria,
            onSearchCriteriaChanged: function (criteria) { return _this.setState({ criteria: criteria }); },
            zoomOptions: this.props.zoomOptions,
            onZoom: this.props.onZoom,
            isLeftPanelOpen: this.props.leftPanelInitiallyOpen,
            isRightPanelOpen: this.props.rightPanelInitiallyOpen,
            toolbar: (0, react_1.createElement)(ToolbarWrapper, { workspace: this }),
            onWorkspaceEvent: onWorkspaceEvent,
            watermarkSvg: this._watermarkSvg,
            watermarkUrl: this._watermarkUrl,
            elementsSearchPanel: _elementsSearchPanel,
        });
    };
    Workspace.prototype.componentDidMount = function () {
        var _this = this;
        var onWorkspaceEvent = this.props.onWorkspaceEvent;
        this.editor._initializePaperComponents(this.markup.paperArea);
        this.updateNavigator(!this.props.hideNavigator);
        this.listener.listen(this.model.events, 'loadingSuccess', function () {
            _this.view.performSyncUpdate();
            _this.markup.paperArea.centerContent();
        });
        this.listener.listen(this.model.events, 'elementEvent', function (_a) {
            var key = _a.key, data = _a.data;
            if (!data.requestedAddToFilter) {
                return;
            }
            var _b = data.requestedAddToFilter, source = _b.source, linkType = _b.linkType, direction = _b.direction;
            _this.setState({
                criteria: {
                    refElement: source,
                    refElementLink: linkType,
                    linkDirection: direction,
                },
            });
            if (onWorkspaceEvent) {
                onWorkspaceEvent(workspaceContext_1.WorkspaceEventKey.searchUpdateCriteria);
            }
        });
        this.listener.listen(this.markup.paperArea.events, 'pointerUp', function (e) {
            _this.lastMousePosition = _this.markup.paperArea.pageToPaperCoords(e.sourceEvent.pageX, e.sourceEvent.pageY);
            if (_this.props.onPointerUp) {
                _this.props.onPointerUp(e);
            }
        });
        this.listener.listen(this.markup.paperArea.events, 'pointerMove', function (e) {
            if (_this.props.onPointerMove) {
                _this.props.onPointerMove(e);
            }
        });
        this.listener.listen(this.markup.paperArea.events, 'pointerDown', function (e) {
            _this.lastMousePosition = _this.markup.paperArea.pageToPaperCoords(e.sourceEvent.pageX, e.sourceEvent.pageY);
            if (_this.props.onPointerDown) {
                _this.props.onPointerDown(e);
            }
        });
        if (onWorkspaceEvent) {
            this.listener.listen(this.editor.events, 'changeSelection', function () {
                return onWorkspaceEvent(workspaceContext_1.WorkspaceEventKey.editorChangeSelection);
            });
            this.listener.listen(this.editor.events, 'toggleDialog', function () {
                return onWorkspaceEvent(workspaceContext_1.WorkspaceEventKey.editorToggleDialog);
            });
            this.listener.listen(this.editor.events, 'addElements', function () {
                return onWorkspaceEvent(workspaceContext_1.WorkspaceEventKey.editorAddElements);
            });
        }
        document.body.addEventListener('paste', this.onBodyPaste);
    };
    Workspace.prototype.componentDidUpdate = function (nextProps) {
        var controlledLanguage = Boolean(nextProps.onLanguageChange);
        if (controlledLanguage && nextProps.language !== this.view.getLanguage()) {
            this.view.setLanguage(nextProps.language);
        }
        if (nextProps.metadataApi !== this.editor.metadataApi) {
            this.editor.setMetadataApi(nextProps.metadataApi);
        }
        if (nextProps.hideNavigator !== this.props.hideNavigator) {
            this.updateNavigator(!nextProps.hideNavigator);
        }
    };
    Workspace.prototype.componentWillUnmount = function () {
        document.body.removeEventListener('paste', this.onBodyPaste);
        this.listener.stopListening();
        this.view.dispose();
    };
    Workspace.prototype.updateNavigator = function (showNavigator) {
        if (showNavigator) {
            var widget = (0, react_1.createElement)(navigator_1.Navigator, {
                view: this.view,
                expanded: !this.props.collapseNavigator,
            });
            this.view.setPaperWidget({
                key: 'navigator',
                widget: widget,
                attachment: view_1.WidgetAttachment.Viewport,
            });
        }
        else {
            this.view.setPaperWidget({
                key: 'navigator',
                widget: undefined,
                attachment: view_1.WidgetAttachment.Viewport,
            });
        }
    };
    Workspace.prototype.getModel = function () {
        return this.model;
    };
    Workspace.prototype.getDiagram = function () {
        return this.view;
    };
    Workspace.prototype.getEditor = function () {
        return this.editor;
    };
    Workspace.prototype.preventTextSelectionUntilMouseUp = function () {
        this.markup.preventTextSelection();
    };
    Workspace.prototype.showWaitIndicatorWhile = function (operation) {
        var _this = this;
        this.editor.setSpinner({});
        if (operation) {
            operation
                .then(function () {
                _this.editor.setSpinner(undefined);
            })
                .catch(function (error) {
                // tslint:disable-next-line:no-console
                console.error(error);
                _this.editor.setSpinner({
                    statusText: 'Unknown error occured',
                    errorOccured: true,
                });
            });
        }
    };
    Workspace.defaultProps = {
        hideTutorial: true,
        collapseNavigator: false,
        leftPanelInitiallyOpen: true,
        rightPanelInitiallyOpen: false,
        languages: [
            { code: 'en', label: 'English' },
            { code: 'de', label: 'German' },
            { code: 'ru', label: 'Russian' },
        ],
        language: 'en',
        onSaveDiagram: function (workspace) {
            var diagram = workspace.getModel().exportLayout();
            window.location.hash = saveLayoutToLocalStorage(diagram);
            fetch('https://diagramstore.aksw.org', {
                method: 'POST',
                body: JSON.stringify(diagram)
            })
                .then(function (res) { return res.json(); })
                .then(function (json) {
                window.location.hash = '!' + json.data.frag;
            })
                .finally(function () {
                window.location.reload();
            });
        },
        onDownloadSource: function (workspace) {
            var _a;
            var fileName = prompt("diagram name", (_a = workspace.fileName) !== null && _a !== void 0 ? _a : "Ontodia Diagram");
            var diagram = workspace.getModel().exportLayout();
            var blobData = new Blob([JSON.stringify(diagram, null, 2)], { type: 'application/ld+json' });
            var urlToBlob = window.URL.createObjectURL(blobData);
            if (!fileName)
                return;
            if (fileName !== "Ontodia Diagram") {
                workspace.fileName = fileName;
            }
            var a = document.createElement('a');
            a.style.setProperty('display', 'none');
            document.body.appendChild(a);
            a.href = urlToBlob;
            a.download = "".concat(fileName, ".json");
            a.click();
            window.URL.revokeObjectURL(urlToBlob);
            a.remove();
        },
        onUploadSource: function (workspace) {
            var input = document.createElement('input');
            input.style.setProperty('display', 'none');
            input.accept = 'application/json';
            input.type = 'file';
            input.addEventListener('change', function () {
                if (input.files.length) {
                    var file_1 = input.files[0];
                    file_1.text()
                        .then(function (text) {
                        var diagram = JSON.parse(text);
                        workspace.getModel().importLayout({
                            diagram: diagram,
                            validateLinks: true,
                            dataProvider: workspace.getModel().dataProvider
                        });
                        workspace.fileName = file_1.name.replace(/\.json$/, '').replace(/\(\d+\)$/, '').trim();
                    })
                        .catch(function (error) {
                        alert("Could not load diagram: ".concat(error));
                    });
                }
            });
            input.click();
            input.remove();
        },
        onReloadClassTree: function (workspace) {
            var provider = workspace.getModel().dataProvider;
            if (provider instanceof sparqlDataProvider_1.SparqlDataProvider) {
                var endpointUrl = provider.options.endpointUrl;
                localStorage.removeItem("classTree#".concat(endpointUrl));
                var diagram = workspace.getModel().exportLayout();
                window.location.hash = saveLayoutToLocalStorage(diagram);
                window.location.reload();
            }
        },
    };
    return Workspace;
}(react_1.Component));
exports.Workspace = Workspace;
var ToolbarWrapper = /** @class */ (function (_super) {
    tslib_1.__extends(ToolbarWrapper, _super);
    function ToolbarWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.listener = new events_1.EventObserver();
        return _this;
    }
    ToolbarWrapper.prototype.render = function () {
        var workspace = this.props.workspace;
        var view = workspace.getDiagram();
        var editor = workspace.getEditor();
        var _a = workspace.props, languages = _a.languages, onSaveDiagram = _a.onSaveDiagram, onDownloadSource = _a.onDownloadSource, onUploadSource = _a.onUploadSource, onReloadClassTree = _a.onReloadClassTree, onPersistChanges = _a.onPersistChanges, hidePanels = _a.hidePanels, toolbar = _a.toolbar;
        var canPersistChanges = onPersistChanges
            ? !authoringState_1.AuthoringState.isEmpty(editor.authoringState)
            : undefined;
        var canSaveDiagram = !canPersistChanges;
        var defaultToolbarProps = {
            onZoomIn: workspace.zoomIn,
            onZoomOut: workspace.zoomOut,
            onZoomToFit: workspace.zoomToFit,
            onPrint: workspace.print,
            onExportSVG: workspace.exportSvg,
            onExportPNG: workspace.exportPng,
            canSaveDiagram: canSaveDiagram,
            onSaveDiagram: onSaveDiagram ? function () { return onSaveDiagram(workspace); } : undefined,
            onDownloadSource: onDownloadSource ? function () { return onDownloadSource(workspace); } : undefined,
            onUploadSource: onUploadSource ? function () { return onUploadSource(workspace); } : undefined,
            onReloadClassTree: onReloadClassTree ? function () { return onReloadClassTree(workspace); } : undefined,
            canPersistChanges: canPersistChanges,
            onPersistChanges: onPersistChanges
                ? function () { return onPersistChanges(workspace); }
                : undefined,
            onForceLayout: function () {
                workspace.forceLayout();
                workspace.getDiagram().performSyncUpdate();
                workspace.zoomToFit();
            },
            onClearAll: workspace.clearAll,
            languages: languages,
            selectedLanguage: view.getLanguage(),
            onChangeLanguage: workspace.changeLanguage,
            hidePanels: hidePanels,
        };
        if (toolbar) {
            var toolbarProps = tslib_1.__assign(tslib_1.__assign({}, defaultToolbarProps), toolbar.props);
            return (0, react_1.cloneElement)(toolbar, toolbarProps);
        }
        else {
            return (0, react_1.createElement)(toolbar_1.DefaultToolbar, defaultToolbarProps);
        }
    };
    ToolbarWrapper.prototype.componentDidMount = function () {
        var _this = this;
        var workspace = this.props.workspace;
        var editor = workspace.getEditor();
        this.listener.listen(editor.events, 'changeAuthoringState', function () {
            _this.forceUpdate();
        });
    };
    ToolbarWrapper.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
    };
    return ToolbarWrapper;
}(react_1.Component));
function renderTo(workspace, container, props) {
    ReactDOM.render((0, react_1.createElement)(workspace, props), container);
}
exports.renderTo = renderTo;
