"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkStateWidget = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var geometry_1 = require("../diagram/geometry");
var paper_1 = require("../diagram/paper");
var view_1 = require("../diagram/view");
var async_1 = require("../viewUtils/async");
var events_1 = require("../viewUtils/events");
var spinner_1 = require("../viewUtils/spinner");
var authoringState_1 = require("./authoringState");
var CLASS_NAME = "graph-explorer-authoring-state";
var LINK_LABEL_MARGIN = 5;
var LinkStateWidget = /** @class */ (function (_super) {
    tslib_1.__extends(LinkStateWidget, _super);
    function LinkStateWidget() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.listener = new events_1.EventObserver();
        _this.delayedUpdate = new async_1.Debouncer();
        _this.scheduleUpdate = function () {
            _this.delayedUpdate.call(_this.performUpdate);
        };
        _this.performUpdate = function () {
            _this.forceUpdate();
        };
        return _this;
    }
    LinkStateWidget.prototype.componentDidMount = function () {
        this.listenEvents();
    };
    LinkStateWidget.prototype.componentDidUpdate = function (prevProps) {
        var sameEventSources = this.props.view === prevProps.view;
        if (!sameEventSources) {
            this.listener.stopListening();
            this.listenEvents();
        }
    };
    LinkStateWidget.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
    };
    LinkStateWidget.prototype.listenEvents = function () {
        var _this = this;
        var _a = this.props, editor = _a.editor, view = _a.view;
        this.listener.listen(editor.model.events, 'elementEvent', function (_a) {
            var data = _a.data;
            if (data.changeSize || data.changePosition) {
                _this.scheduleUpdate();
            }
        });
        this.listener.listen(editor.model.events, 'linkEvent', function (_a) {
            var data = _a.data;
            if (data.changeVertices || data.changeLabelBounds) {
                _this.scheduleUpdate();
            }
        });
        this.listener.listen(editor.model.events, 'changeCells', this.scheduleUpdate);
        this.listener.listen(editor.events, 'changeAuthoringState', this.scheduleUpdate);
        this.listener.listen(editor.events, 'changeTemporaryState', this.scheduleUpdate);
        this.listener.listen(editor.events, 'changeValidationState', this.scheduleUpdate);
        this.listener.listen(view.events, 'syncUpdate', function (_a) {
            var layer = _a.layer;
            if (layer === view_1.RenderingLayer.Editor) {
                _this.delayedUpdate.runSynchronously();
            }
        });
    };
    LinkStateWidget.prototype.calculateLinkPath = function (link) {
        var polyline = this.calculatePolyline(link);
        return 'M' + polyline.map(function (_a) {
            var x = _a.x, y = _a.y;
            return "".concat(x, ",").concat(y);
        }).join(' L');
    };
    LinkStateWidget.prototype.calculatePolyline = function (link) {
        var _a = this.props, editor = _a.editor, view = _a.view;
        var source = editor.model.getElement(link.sourceId);
        var target = editor.model.getElement(link.targetId);
        var route = view.getRouting(link.id);
        var verticesDefinedByUser = link.vertices || [];
        var vertices = route ? route.vertices : verticesDefinedByUser;
        return (0, geometry_1.computePolyline)(source, target, vertices);
    };
    LinkStateWidget.prototype.renderLinkStateLabels = function () {
        var _this = this;
        var editor = this.props.editor;
        return editor.model.links.map(function (link) {
            var renderedState = null;
            var state = editor.authoringState.links.get(link.data);
            if (state) {
                var onCancel = function () { return editor.discardChange(state); };
                var statusText = void 0;
                var title = void 0;
                if (state.deleted) {
                    statusText = 'Delete';
                    title = 'Revert deletion of the link';
                }
                else if (!state.before) {
                    statusText = 'New';
                    title = 'Revert creation of the link';
                }
                else {
                    statusText = 'Change';
                    title = 'Revert all changes in properties of the link';
                }
                if (statusText && title) {
                    renderedState = (React.createElement("span", null,
                        React.createElement("span", { className: "".concat(CLASS_NAME, "__state-label") }, statusText),
                        "[",
                        React.createElement("span", { className: "".concat(CLASS_NAME, "__state-cancel"), onClick: onCancel, title: title }, "cancel"),
                        "]"));
                }
            }
            var renderedErrors = _this.renderLinkErrors(link.data);
            if (renderedState || renderedErrors) {
                var labelPosition = _this.getLinkStateLabelPosition(link);
                if (!labelPosition) {
                    return null;
                }
                var style = { left: labelPosition.x, top: labelPosition.y };
                return (React.createElement("div", { className: "".concat(CLASS_NAME, "__state-indicator"), key: link.id, style: style },
                    React.createElement("div", { className: "".concat(CLASS_NAME, "__state-indicator-container") },
                        React.createElement("div", { className: "".concat(CLASS_NAME, "__state-indicator-body") },
                            renderedState,
                            renderedErrors))));
            }
            else {
                return null;
            }
        });
    };
    LinkStateWidget.prototype.renderLinkStateHighlighting = function () {
        var _this = this;
        var editor = this.props.editor;
        return editor.model.links.map(function (link) {
            if (editor.temporaryState.links.has(link.data)) {
                var path = _this.calculateLinkPath(link);
                return (React.createElement("path", { key: link.id, d: path, fill: 'none', stroke: 'grey', strokeWidth: 5, strokeOpacity: 0.5, strokeDasharray: '8 8' }));
            }
            var event = editor.authoringState.links.get(link.data);
            var isDeletedLink = authoringState_1.AuthoringState.isDeletedLink(editor.authoringState, link.data);
            var isUncertainLink = authoringState_1.AuthoringState.isUncertainLink(editor.authoringState, link.data);
            if (event || isDeletedLink || isUncertainLink) {
                var path = _this.calculateLinkPath(link);
                var color = void 0;
                if (isDeletedLink) {
                    color = 'red';
                }
                else if (isUncertainLink) {
                    color = 'blue';
                }
                else if (event && event.type === authoringState_1.AuthoringKind.ChangeLink) {
                    color = event.before ? 'blue' : 'green';
                }
                return (React.createElement("path", { key: link.id, d: path, fill: 'none', stroke: color, strokeWidth: 5, strokeOpacity: 0.5 }));
            }
            return null;
        });
    };
    LinkStateWidget.prototype.getLinkStateLabelPosition = function (link) {
        if (link.labelBounds) {
            var _a = link.labelBounds, x = _a.x, y = _a.y;
            return { x: x, y: y - LINK_LABEL_MARGIN / 2 };
        }
        else {
            var polyline = this.calculatePolyline(link);
            var polylineLength = (0, geometry_1.computePolylineLength)(polyline);
            return (0, geometry_1.getPointAlongPolyline)(polyline, polylineLength / 2);
        }
    };
    LinkStateWidget.prototype.renderLinkErrors = function (linkModel) {
        var editor = this.props.editor;
        var validationState = editor.validationState;
        var validation = validationState.links.get(linkModel);
        if (!validation) {
            return null;
        }
        var title = validation.errors.map(function (error) { return error.message; }).join('\n');
        return this.renderErrorIcon(title, validation);
    };
    LinkStateWidget.prototype.renderErrorIcon = function (title, validation) {
        return (React.createElement("div", { className: "".concat(CLASS_NAME, "__item-error"), title: title },
            validation.loading ? (React.createElement(spinner_1.HtmlSpinner, { width: 15, height: 17 })) : (React.createElement("div", { className: "".concat(CLASS_NAME, "__item-error-icon") })),
            !validation.loading && validation.errors.length > 0
                ? validation.errors.length
                : undefined));
    };
    LinkStateWidget.prototype.render = function () {
        var _a = this.props, editor = _a.editor, paperTransform = _a.paperTransform;
        var scale = paperTransform.scale, originX = paperTransform.originX, originY = paperTransform.originY;
        if (!editor.inAuthoringMode) {
            return null;
        }
        var htmlTransformStyle = {
            position: 'absolute',
            left: 0,
            top: 0,
            transform: "scale(".concat(scale, ",").concat(scale, ")translate(").concat(originX, "px,").concat(originY, "px)"),
        };
        return (React.createElement("div", { className: "".concat(CLASS_NAME) },
            React.createElement(paper_1.TransformedSvgCanvas, { paperTransform: paperTransform, style: { overflow: 'visible', pointerEvents: 'none' } }, this.renderLinkStateHighlighting()),
            React.createElement("div", { className: "".concat(CLASS_NAME, "__validation-layer"), style: htmlTransformStyle }, this.renderLinkStateLabels())));
    };
    return LinkStateWidget;
}(React.Component));
exports.LinkStateWidget = LinkStateWidget;
