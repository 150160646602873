"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProgressBar = exports.ProgressState = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var ProgressState;
(function (ProgressState) {
    ProgressState["none"] = "none";
    ProgressState["loading"] = "loading";
    ProgressState["error"] = "error";
    ProgressState["completed"] = "completed";
})(ProgressState = exports.ProgressState || (exports.ProgressState = {}));
var CLASS_NAME = 'graph-explorer-progress-bar';
var ProgressBar = /** @class */ (function (_super) {
    tslib_1.__extends(ProgressBar, _super);
    function ProgressBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProgressBar.prototype.render = function () {
        var _a = this.props, state = _a.state, _b = _a.percent, percent = _b === void 0 ? 100 : _b, _c = _a.height, height = _c === void 0 ? 20 : _c;
        var className = "".concat(CLASS_NAME, " ").concat(CLASS_NAME, "--").concat(state);
        var showBar = state === ProgressState.loading || state === ProgressState.error;
        return (React.createElement("div", { className: className, style: { height: showBar ? height : 0 } },
            React.createElement("div", { className: "".concat(CLASS_NAME, "__bar"), role: "progressbar", style: { width: "".concat(percent, "%") }, "aria-valuemin": 0, "aria-valuemax": 100, "aria-valuenow": percent })));
    };
    return ProgressBar;
}(React.Component));
exports.ProgressBar = ProgressBar;
