"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateProperties = exports.GenerateID = exports.PLACEHOLDER_LINK_TYPE = exports.PLACEHOLDER_ELEMENT_TYPE = exports.DIAGRAM_CONTEXT_URL_V1 = void 0;
var utils_1 = require("./utils");
// context could be imported directly from NPM package, e.g.
exports.DIAGRAM_CONTEXT_URL_V1 = 'https://graph-explorer.org/context/v1.json';
exports.PLACEHOLDER_ELEMENT_TYPE = 'http://graph-explorer.org/NewEntity';
exports.PLACEHOLDER_LINK_TYPE = 'http://graph-explorer.org/NewLink';
var GRAPH_EXPLORER_ID_URL_PREFIX = 'http://graph-explorer.org/data/';
exports.GenerateID = {
    forElement: function () {
        return "".concat(GRAPH_EXPLORER_ID_URL_PREFIX, "e_").concat((0, utils_1.generate128BitID)());
    },
    forLink: function () {
        return "".concat(GRAPH_EXPLORER_ID_URL_PREFIX, "l_").concat((0, utils_1.generate128BitID)());
    },
};
exports.TemplateProperties = {
    PinnedProperties: 'graph-explorer:pinnedProperties',
    CustomLabel: 'graph-explorer:customLabel',
};
