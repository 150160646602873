"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HaloLink = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var geometry_1 = require("../diagram/geometry");
var events_1 = require("../viewUtils/events");
var async_1 = require("../viewUtils/async");
var spinner_1 = require("../viewUtils/spinner");
var CLASS_NAME = 'graph-explorer-halo-link';
var BUTTON_SIZE = 20;
var BUTTON_MARGIN = 5;
var HaloLink = /** @class */ (function (_super) {
    tslib_1.__extends(HaloLink, _super);
    function HaloLink(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        _this.targetListener = new events_1.EventObserver();
        _this.queryDebouncer = new async_1.Debouncer();
        _this.queryCancellation = new async_1.Cancellation();
        _this.updateAll = function () { return _this.forceUpdate(); };
        _this.onSourceMove = function (e) {
            var point = _this.props.paperArea.pageToPaperCoords(e.pageX, e.pageY);
            _this.props.onSourceMove(point);
        };
        _this.onTargetMove = function (e) {
            var point = _this.props.paperArea.pageToPaperCoords(e.pageX, e.pageY);
            _this.props.onTargetMove(point);
        };
        _this.state = {};
        return _this;
    }
    HaloLink.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, target = _a.target, editor = _a.editor;
        this.listener.listen(editor.events, 'changeAuthoringState', function () {
            _this.queryAllowedActions();
        });
        this.listenToTarget(target);
        this.queryAllowedActions();
    };
    HaloLink.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.target !== this.props.target) {
            this.listenToTarget(this.props.target);
            this.queryAllowedActions();
        }
    };
    HaloLink.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.listenToTarget(undefined);
        this.queryDebouncer.dispose();
        this.queryCancellation.abort();
    };
    HaloLink.prototype.queryAllowedActions = function () {
        var _this = this;
        this.queryDebouncer.call(function () {
            _this.queryCancellation.abort();
            _this.queryCancellation = new async_1.Cancellation();
            _this.queryCanDelete(_this.props.target);
            _this.queryCanEdit(_this.props.target);
        });
    };
    HaloLink.prototype.queryCanDelete = function (link) {
        var _this = this;
        var _a = this.props, metadataApi = _a.metadataApi, editor = _a.editor, view = _a.view;
        if (!metadataApi) {
            this.setState({ canDelete: false });
            return;
        }
        if (isSourceOrTargetDeleted(editor.authoringState, link)) {
            this.setState({ canDelete: false });
        }
        else {
            this.setState({ canDelete: undefined });
            var source = view.model.getElement(link.sourceId);
            var target = view.model.getElement(link.targetId);
            var signal = this.queryCancellation.signal;
            async_1.CancellationToken.mapCancelledToNull(signal, metadataApi.canDeleteLink(link.data, source.data, target.data, signal)).then(function (canDelete) {
                if (canDelete === null) {
                    return;
                }
                if (_this.props.target.id === link.id) {
                    _this.setState({ canDelete: canDelete });
                }
            });
        }
    };
    HaloLink.prototype.queryCanEdit = function (link) {
        var _this = this;
        var _a = this.props, metadataApi = _a.metadataApi, editor = _a.editor, view = _a.view;
        if (!metadataApi) {
            this.setState({ canEdit: false });
            return;
        }
        if (isDeletedLink(editor.authoringState, link)) {
            this.setState({ canEdit: false });
        }
        else {
            this.setState({ canEdit: undefined });
            var source = view.model.getElement(link.sourceId);
            var target = view.model.getElement(link.targetId);
            var signal = this.queryCancellation.signal;
            async_1.CancellationToken.mapCancelledToNull(signal, metadataApi.canEditLink(link.data, source.data, target.data, signal)).then(function (canEdit) {
                if (canEdit === null) {
                    return;
                }
                if (_this.props.target.id === link.id) {
                    _this.setState({ canEdit: canEdit });
                }
            });
        }
    };
    HaloLink.prototype.listenToTarget = function (link) {
        var _this = this;
        var view = this.props.view;
        this.targetListener.stopListening();
        if (link) {
            var source = view.model.getElement(link.sourceId);
            var target = view.model.getElement(link.targetId);
            var listenToElement = function (element) {
                _this.targetListener.listen(element.events, 'changePosition', _this.updateAll);
                _this.targetListener.listen(element.events, 'changeSize', _this.updateAll);
            };
            listenToElement(source);
            listenToElement(target);
            this.targetListener.listen(link.events, 'changeVertices', this.updateAll);
            this.targetListener.listen(link.events, 'changeLabelBounds', this.updateAll);
        }
    };
    HaloLink.prototype.paperToScrollablePaneCoords = function (point) {
        return this.props.paperArea.paperToScrollablePaneCoords(point.x, point.y);
    };
    HaloLink.prototype.computePolyline = function () {
        var _a = this.props, view = _a.view, target = _a.target;
        var sourceElement = view.model.getElement(target.sourceId);
        var targetElement = view.model.getElement(target.targetId);
        if (!(sourceElement && targetElement)) {
            return undefined;
        }
        var route = view.getRouting(target.id);
        var verticesDefinedByUser = target.vertices || [];
        var vertices = route ? route.vertices : verticesDefinedByUser;
        return (0, geometry_1.computePolyline)(sourceElement, targetElement, vertices);
    };
    HaloLink.prototype.calculateDegree = function (source, target) {
        var x = target.x - source.x;
        var y = target.y - source.y;
        var r = Math.sqrt(x * x + y * y);
        var unit = { x: x / r, y: y / r };
        return Math.atan2(unit.y, unit.x) * (180 / Math.PI);
    };
    HaloLink.prototype.renderSourceButton = function (polyline) {
        var _a = this.props, editor = _a.editor, target = _a.target;
        var point = (0, geometry_1.getPointAlongPolyline)(polyline, 0);
        var _b = this.paperToScrollablePaneCoords(point), x = _b.x, y = _b.y;
        var style = { top: y - BUTTON_SIZE / 2, left: x - BUTTON_SIZE / 2 };
        return (React.createElement("button", { className: "".concat(CLASS_NAME, "__button"), style: style, disabled: isDeletedLink(editor.authoringState, target), onMouseDown: this.onSourceMove },
            React.createElement("svg", { width: BUTTON_SIZE, height: BUTTON_SIZE },
                React.createElement("g", { transform: "scale(".concat(BUTTON_SIZE, ")") },
                    React.createElement("circle", { r: 0.5, cx: 0.5, cy: 0.5, fill: "#198AD3" })))));
    };
    HaloLink.prototype.getButtonPosition = function (polyline, index) {
        var polylineLength = (0, geometry_1.computePolylineLength)(polyline);
        var point = (0, geometry_1.getPointAlongPolyline)(polyline, polylineLength - (BUTTON_SIZE + BUTTON_MARGIN) * index);
        var _a = this.paperToScrollablePaneCoords(point), x = _a.x, y = _a.y;
        return { top: y - BUTTON_SIZE / 2, left: x - BUTTON_SIZE / 2 };
    };
    HaloLink.prototype.renderTargetButton = function (polyline) {
        var _a = this.props, editor = _a.editor, target = _a.target;
        var style = this.getButtonPosition(polyline, 0);
        var length = polyline.length;
        var degree = this.calculateDegree(polyline[length - 1], polyline[length - 2]);
        return (React.createElement("button", { className: "".concat(CLASS_NAME, "__button"), style: style, disabled: isDeletedLink(editor.authoringState, target), onMouseDown: this.onTargetMove },
            React.createElement("svg", { width: BUTTON_SIZE, height: BUTTON_SIZE, style: { transform: "rotate(".concat(degree, "deg)") } },
                React.createElement("g", { transform: "scale(".concat(BUTTON_SIZE, ")") },
                    React.createElement("polygon", { points: '0,0.5 1,1 1,0', fill: "#198AD3" })))));
    };
    HaloLink.prototype.renderEditButton = function (polyline) {
        var canEdit = this.state.canEdit;
        var style = this.getButtonPosition(polyline, 1);
        if (canEdit === undefined) {
            return (React.createElement("div", { className: "".concat(CLASS_NAME, "__spinner"), style: style },
                React.createElement(spinner_1.HtmlSpinner, { width: 20, height: 20 })));
        }
        var title = canEdit
            ? 'Edit link'
            : 'Editing is unavailable for the selected link';
        return (React.createElement("button", { className: "".concat(CLASS_NAME, "__button ").concat(CLASS_NAME, "__edit"), style: style, title: title, onClick: this.props.onEdit, disabled: !canEdit }));
    };
    HaloLink.prototype.renderDeleteButton = function (polyline) {
        var canDelete = this.state.canDelete;
        var style = this.getButtonPosition(polyline, 2);
        if (canDelete === undefined) {
            return (React.createElement("div", { className: "".concat(CLASS_NAME, "__spinner"), style: style },
                React.createElement(spinner_1.HtmlSpinner, { width: 20, height: 20 })));
        }
        var title = canDelete
            ? 'Delete link'
            : 'Deletion is unavailable for the selected link';
        return (React.createElement("button", { className: "".concat(CLASS_NAME, "__button ").concat(CLASS_NAME, "__delete"), style: style, title: title, onClick: this.props.onDelete, disabled: !canDelete }));
    };
    // Link editing implementation could be rethought in the future.
    HaloLink.prototype.renderEditLabelButton = function () {
        var _a = this.props, view = _a.view, target = _a.target, paperArea = _a.paperArea, onEditLabel = _a.onEditLabel;
        var linkType = view.model.getLinkType(target.typeId);
        var template = view.createLinkTemplate(linkType);
        if (!template.setLinkLabel || !target.labelBounds) {
            return null;
        }
        var _b = target.labelBounds, x = _b.x, y = _b.y, width = _b.width, height = _b.height;
        var _c = paperArea.paperToScrollablePaneCoords(x + width, y + height / 2), left = _c.x, top = _c.y;
        var size = { width: 15, height: 17 };
        var style = {
            width: size.width,
            height: size.height,
            top: top - size.height / 2,
            left: left,
        };
        return (React.createElement("button", { className: "".concat(CLASS_NAME, "__edit-label-button"), style: style, onClick: function () { return onEditLabel(); }, title: 'Edit Link Label' }));
    };
    HaloLink.prototype.render = function () {
        var _a = this.props, editor = _a.editor, target = _a.target, metadataApi = _a.metadataApi;
        var polyline = this.computePolyline();
        if (!polyline) {
            return null;
        }
        var isAuthoringMode = Boolean(metadataApi);
        var deleteButton = isDeletedByItself(editor.authoringState, target) ||
            isSourceOrTargetDeleted(editor.authoringState, target)
            ? null
            : this.renderDeleteButton(polyline);
        return (React.createElement("div", { className: "".concat(CLASS_NAME) },
            isAuthoringMode ? this.renderTargetButton(polyline) : null,
            isAuthoringMode ? this.renderSourceButton(polyline) : null,
            !isAuthoringMode || isDeletedLink(editor.authoringState, target)
                ? null
                : this.renderEditButton(polyline),
            isAuthoringMode ? deleteButton : null,
            this.renderEditLabelButton()));
    };
    return HaloLink;
}(React.Component));
exports.HaloLink = HaloLink;
function isDeletedLink(state, link) {
    return isDeletedByItself(state, link) || isSourceOrTargetDeleted(state, link);
}
function isDeletedByItself(state, link) {
    var event = state.links.get(link.data);
    return event && event.deleted;
}
function isSourceOrTargetDeleted(state, link) {
    var sourceEvent = state.elements.get(link.data.sourceId);
    var targetEvent = state.elements.get(link.data.targetId);
    return ((sourceEvent && sourceEvent.deleted) || (targetEvent && targetEvent.deleted));
}
