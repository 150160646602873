"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkMarkers = exports.LinkLayer = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var react_1 = require("react");
var async_1 = require("../viewUtils/async");
var events_1 = require("../viewUtils/events");
var commands_1 = require("./commands");
var elements_1 = require("./elements");
var geometry_1 = require("./geometry");
var view_1 = require("./view");
var UpdateRequest;
(function (UpdateRequest) {
    /** Some part of layer requested an update */
    UpdateRequest[UpdateRequest["Partial"] = 1] = "Partial";
    /** Full update requested */
    UpdateRequest[UpdateRequest["All"] = 2] = "All";
})(UpdateRequest || (UpdateRequest = {}));
var CLASS_NAME = 'graph-explorer-link-layer';
var LinkLayer = /** @class */ (function (_super) {
    tslib_1.__extends(LinkLayer, _super);
    function LinkLayer(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.listener = new events_1.EventObserver();
        _this.delayedUpdate = new async_1.Debouncer();
        _this.updateState = UpdateRequest.Partial;
        /** List of link IDs to update at the next flush event */
        _this.scheduledToUpdate = new Set();
        _this.scheduleUpdateAll = function () {
            if (_this.updateState !== UpdateRequest.All) {
                _this.updateState = UpdateRequest.All;
                _this.scheduledToUpdate = new Set();
            }
            _this.delayedUpdate.call(_this.performUpdate);
        };
        _this.performUpdate = function () {
            _this.forceUpdate();
        };
        _this.getLinks = function () {
            var _a = _this.props, view = _a.view, links = _a.links, group = _a.group;
            if (!group) {
                return links;
            }
            var grouping = (0, geometry_1.computeGrouping)(view.model.elements);
            var nestedElements = computeDeepNestedElements(grouping, group);
            return links.filter(function (link) {
                var sourceId = link.sourceId, targetId = link.targetId;
                var source = view.model.getElement(sourceId);
                var target = view.model.getElement(targetId);
                if (!source || !target) {
                    return false;
                }
                var sourceGroup = source.group;
                var targetGroup = target.group;
                return nestedElements[sourceGroup] || nestedElements[targetGroup];
            });
        };
        return _this;
    }
    LinkLayer.prototype.componentDidMount = function () {
        var _this = this;
        var view = this.props.view;
        var scheduleUpdateElementLinks = function (element) {
            for (var _i = 0, _a = element.links; _i < _a.length; _i++) {
                var link = _a[_i];
                _this.scheduleUpdateLink(link.id);
            }
        };
        this.listener.listen(view.events, 'changeLanguage', this.scheduleUpdateAll);
        this.listener.listen(view.events, 'changeHighlight', this.scheduleUpdateAll);
        var updateChangedRoutes = function (changed, previous) {
            changed.forEach(function (routing, linkId) {
                if (previous.get(linkId) !== routing) {
                    _this.scheduleUpdateLink(linkId);
                }
            });
        };
        this.listener.listen(view.events, 'updateRoutings', function (_a) {
            var previous = _a.previous;
            var newRoutes = view.getRoutings();
            updateChangedRoutes(newRoutes, previous);
            updateChangedRoutes(previous, newRoutes);
        });
        this.listener.listen(view.model.events, 'changeCells', function (e) {
            if (e.updateAll) {
                _this.scheduleUpdateAll();
            }
            else {
                if (e.changedElement) {
                    scheduleUpdateElementLinks(e.changedElement);
                }
                if (e.changedLinks) {
                    for (var _i = 0, _a = e.changedLinks; _i < _a.length; _i++) {
                        var link = _a[_i];
                        _this.scheduleUpdateLink(link.id);
                    }
                }
            }
        });
        this.listener.listen(view.model.events, 'elementEvent', function (_a) {
            var data = _a.data;
            var elementEvent = data.changePosition || data.changeSize;
            if (!elementEvent) {
                return;
            }
            scheduleUpdateElementLinks(elementEvent.source);
        });
        this.listener.listen(view.model.events, 'linkEvent', function (_a) {
            var data = _a.data;
            var linkEvent = data.changeData ||
                data.changeLayoutOnly ||
                data.changeVertices ||
                data.changeLinkState;
            if (linkEvent) {
                _this.scheduleUpdateLink(linkEvent.source.id);
            }
        });
        this.listener.listen(view.model.events, 'linkTypeEvent', function (_a) {
            var data = _a.data;
            var linkTypeEvent = data.changeLabel || data.changeVisibility;
            if (!linkTypeEvent) {
                return;
            }
            var linkTypeId = linkTypeEvent.source.id;
            for (var _i = 0, _b = view.model.linksOfType(linkTypeId); _i < _b.length; _i++) {
                var link = _b[_i];
                _this.scheduleUpdateLink(link.id);
            }
        });
        this.listener.listen(view.events, 'syncUpdate', function (_a) {
            var layer = _a.layer;
            if (layer !== view_1.RenderingLayer.Link) {
                return;
            }
            _this.delayedUpdate.runSynchronously();
        });
    };
    LinkLayer.prototype.shouldComponentUpdate = function () {
        return false;
    };
    LinkLayer.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.delayedUpdate.dispose();
    };
    LinkLayer.prototype.scheduleUpdateLink = function (linkId) {
        if (this.updateState === UpdateRequest.Partial) {
            this.scheduledToUpdate.add(linkId);
        }
        this.delayedUpdate.call(this.performUpdate);
    };
    LinkLayer.prototype.popShouldUpdatePredicate = function () {
        var _a = this, updateState = _a.updateState, scheduledToUpdate = _a.scheduledToUpdate;
        this.scheduledToUpdate = new Set();
        this.updateState = UpdateRequest.Partial;
        return updateState === UpdateRequest.All
            ? function () { return true; }
            : function (link) { return scheduledToUpdate.has(link.id); };
    };
    LinkLayer.prototype.render = function () {
        var view = this.props.view;
        var shouldUpdate = this.popShouldUpdatePredicate();
        return (React.createElement("g", { className: CLASS_NAME }, this.getLinks().map(function (model) { return (React.createElement(LinkView, { key: model.id, view: view, model: model, shouldUpdate: shouldUpdate(model), route: view.getRouting(model.id) })); })));
    };
    return LinkLayer;
}(react_1.Component));
exports.LinkLayer = LinkLayer;
function computeDeepNestedElements(grouping, groupId) {
    var deepChildren = {};
    function collectNestedItems(parentId) {
        deepChildren[parentId] = true;
        var children = grouping.get(parentId);
        if (!children) {
            return;
        }
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var element = children_1[_i];
            if (element.group !== parentId) {
                continue;
            }
            collectNestedItems(element.id);
        }
    }
    collectNestedItems(groupId);
    return deepChildren;
}
var LINK_CLASS = 'graph-explorer-link';
var LABEL_GROUPING_PRECISION = 100;
// temporary, cleared-before-render map to hold line numbers for labels
// grouped on the same link offset
var TEMPORARY_LABEL_LINES = new Map();
var LinkView = /** @class */ (function (_super) {
    tslib_1.__extends(LinkView, _super);
    function LinkView(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.onRemoveLinkVertex = function (vertex) {
            var model = _this.props.view.model;
            model.history.registerToUndo((0, commands_1.restoreCapturedLinkGeometry)(vertex.link));
            vertex.remove();
        };
        _this.onBoundsUpdate = function (newBounds) {
            var model = _this.props.model;
            model.setLabelBounds(newBounds);
        };
        _this.grabLinkTemplate(_this.props);
        return _this;
    }
    LinkView.prototype.componentDidUpdate = function (nextProps) {
        if (this.linkType.id !== nextProps.model.typeId) {
            this.grabLinkTemplate(nextProps);
        }
    };
    LinkView.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return nextProps.shouldUpdate;
    };
    LinkView.prototype.grabLinkTemplate = function (props) {
        this.linkType = props.view.model.getLinkType(props.model.typeId);
        this.template = props.view.createLinkTemplate(this.linkType);
    };
    LinkView.prototype.render = function () {
        var _a = this.props, view = _a.view, model = _a.model, route = _a.route;
        var source = view.model.getElement(model.sourceId);
        var target = view.model.getElement(model.targetId);
        if (!(source && target)) {
            return null;
        }
        var verticesDefinedByUser = model.vertices || [];
        var vertices = route ? route.vertices : verticesDefinedByUser;
        var polyline = (0, geometry_1.computePolyline)(source, target, vertices);
        var path = 'M' + polyline.map(function (_a) {
            var x = _a.x, y = _a.y;
            return "".concat(x, ",").concat(y);
        }).join(' L');
        var _b = this.linkType, typeIndex = _b.index, showLabel = _b.showLabel;
        var style = this.template.renderLink(model);
        var pathAttributes = getPathAttributes(model, style);
        var isBlurred = view.highlighter && !view.highlighter(model);
        var className = "".concat(LINK_CLASS, " ").concat(isBlurred ? "".concat(LINK_CLASS, "--blurred") : '');
        return (React.createElement("g", { className: className, "data-link-id": model.id, "data-source-id": source.id, "data-target-id": target.id },
            React.createElement("path", tslib_1.__assign({ className: "".concat(LINK_CLASS, "__connection"), d: path }, pathAttributes, { markerStart: "url(#".concat((0, elements_1.linkMarkerKey)(typeIndex, true), ")"), markerEnd: "url(#".concat((0, elements_1.linkMarkerKey)(typeIndex, false), ")") })),
            React.createElement("path", { className: "".concat(LINK_CLASS, "__wrap"), d: path }),
            showLabel ? this.renderLabels(polyline, style) : undefined,
            this.renderVertices(verticesDefinedByUser, pathAttributes.stroke)));
    };
    LinkView.prototype.renderVertices = function (vertices, fill) {
        var elements = [];
        var vertexClass = "".concat(LINK_CLASS, "__vertex");
        var vertexRadius = 10;
        var index = 0;
        for (var _i = 0, vertices_1 = vertices; _i < vertices_1.length; _i++) {
            var _a = vertices_1[_i], x = _a.x, y = _a.y;
            elements.push(React.createElement("circle", { key: index * 2, "data-vertex": index, className: vertexClass, cx: x, cy: y, r: vertexRadius, fill: fill }));
            elements.push(React.createElement(VertexTools, { key: index * 2 + 1, className: "".concat(LINK_CLASS, "__vertex-tools"), model: this.props.model, vertexIndex: index, vertexRadius: vertexRadius, x: x, y: y, onRemove: this.onRemoveLinkVertex }));
            index++;
        }
        return React.createElement("g", { className: "".concat(LINK_CLASS, "__vertices") }, elements);
    };
    LinkView.prototype.renderLabels = function (polyline, style) {
        var _this = this;
        var _a = this.props, view = _a.view, model = _a.model, route = _a.route;
        var labels = computeLinkLabels(model, style, view);
        var textAnchor = 'middle';
        if (route && route.labelTextAnchor) {
            textAnchor = route.labelTextAnchor;
        }
        var polylineLength = (0, geometry_1.computePolylineLength)(polyline);
        TEMPORARY_LABEL_LINES.clear();
        return (React.createElement("g", { className: "".concat(LINK_CLASS, "__labels") }, labels.map(function (label, index) {
            var _a = (0, geometry_1.getPointAlongPolyline)(polyline, polylineLength * label.offset), x = _a.x, y = _a.y;
            var groupKey = Math.round(label.offset * LABEL_GROUPING_PRECISION) /
                LABEL_GROUPING_PRECISION;
            var line = TEMPORARY_LABEL_LINES.get(groupKey) || 0;
            TEMPORARY_LABEL_LINES.set(groupKey, line + 1);
            return (React.createElement(LinkLabel, { key: index, x: x, y: y, line: line, label: label, textAnchor: textAnchor, onBoundsUpdate: index === 0 ? _this.onBoundsUpdate : undefined }));
        })));
    };
    return LinkView;
}(react_1.Component));
function computeLinkLabels(model, style, view) {
    var labels = [];
    var labelStyle = style.label || {};
    var labelTexts = labelStyle.attrs && labelStyle.attrs.text
        ? labelStyle.attrs.text.text
        : undefined;
    var text;
    var title = labelStyle.title;
    if (labelTexts && labelTexts.length > 0) {
        text = view.selectLabel(labelTexts);
    }
    else {
        var type = view.model.getLinkType(model.typeId);
        text = view.selectLabel(type.label) || {
            value: view.formatLabel(type.label, type.id),
            language: '',
        };
        if (title === undefined) {
            title = "".concat(text.value, " ").concat(view.formatIri(model.typeId));
        }
    }
    labels.push({
        offset: labelStyle.position || 0.5,
        text: text,
        title: title,
        attributes: {
            text: getLabelTextAttributes(labelStyle),
            rect: getLabelRectAttributes(labelStyle),
        },
    });
    if (style.properties) {
        for (var _i = 0, _a = style.properties; _i < _a.length; _i++) {
            var property = _a[_i];
            if (!(property.attrs && property.attrs.text && property.attrs.text.text)) {
                continue;
            }
            labels.push({
                offset: property.position || 0.5,
                text: view.selectLabel(property.attrs.text.text),
                title: property.title,
                attributes: {
                    text: getLabelTextAttributes(property),
                    rect: getLabelRectAttributes(property),
                },
            });
        }
    }
    return labels;
}
function getPathAttributes(model, style) {
    var connectionAttributes = style.connection || {};
    var defaultStrokeDasharray = model.layoutOnly ? '5,5' : undefined;
    var _a = connectionAttributes.fill, fill = _a === void 0 ? 'none' : _a, _b = connectionAttributes.stroke, stroke = _b === void 0 ? 'black' : _b, strokeWidth = connectionAttributes["stroke-width"], _c = connectionAttributes["stroke-dasharray"], strokeDasharray = _c === void 0 ? defaultStrokeDasharray : _c;
    return { fill: fill, stroke: stroke, strokeWidth: strokeWidth, strokeDasharray: strokeDasharray };
}
function getLabelTextAttributes(label) {
    var _a = label.attrs ? label.attrs.text : {}, _b = _a.fill, fill = _b === void 0 ? 'black' : _b, _c = _a.stroke, stroke = _c === void 0 ? 'none' : _c, _d = _a["stroke-width"], strokeWidth = _d === void 0 ? 0 : _d, _e = _a["font-family"], fontFamily = _e === void 0 ? '"Helvetica Neue", "Helvetica", "Arial", sans-serif' : _e, _f = _a["font-size"], fontSize = _f === void 0 ? 'inherit' : _f, _g = _a["font-weight"], fontWeight = _g === void 0 ? 'bold' : _g;
    return {
        fill: fill,
        stroke: stroke,
        strokeWidth: strokeWidth,
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight,
    };
}
function getLabelRectAttributes(label) {
    var _a = label.attrs && label.attrs.rect ? label.attrs.rect : {}, _b = _a.fill, fill = _b === void 0 ? 'white' : _b, _c = _a.stroke, stroke = _c === void 0 ? 'none' : _c, _d = _a["stroke-width"], strokeWidth = _d === void 0 ? 0 : _d;
    return { fill: fill, stroke: stroke, strokeWidth: strokeWidth };
}
var GROUPED_LABEL_MARGIN = 2;
var LinkLabel = /** @class */ (function (_super) {
    tslib_1.__extends(LinkLabel, _super);
    function LinkLabel(props) {
        var _this = _super.call(this, props) || this;
        _this.shouldUpdateBounds = true;
        _this.onTextMount = function (text) {
            _this.text = text;
        };
        _this.state = { width: 0, height: 0 };
        return _this;
    }
    LinkLabel.prototype.render = function () {
        var _a = this.props, x = _a.x, y = _a.y, label = _a.label, line = _a.line, textAnchor = _a.textAnchor;
        var _b = this.state, width = _b.width, height = _b.height;
        var _c = this.getLabelRectangle(width, height), rectX = _c.x, rectY = _c.y;
        var transform = line === 0
            ? undefined
            : "translate(0, ".concat(line * (height + GROUPED_LABEL_MARGIN), "px)");
        // HACK: 'alignment-baseline' and 'dominant-baseline' are not supported in Edge and IE
        var dy = '0.6ex';
        return (React.createElement("g", { style: transform ? { transform: transform } : undefined },
            label.title ? React.createElement("title", null, label.title) : undefined,
            React.createElement("rect", { x: rectX, y: rectY, width: width, height: height, style: label.attributes.rect }),
            React.createElement("text", { ref: this.onTextMount, x: x, y: y, dy: dy, textAnchor: textAnchor, style: label.attributes.text }, label.text.value)));
    };
    LinkLabel.prototype.getLabelRectangle = function (width, height) {
        var _a = this.props, x = _a.x, y = _a.y, textAnchor = _a.textAnchor;
        var xOffset = 0;
        if (textAnchor === 'middle') {
            xOffset = -width / 2;
        }
        else if (textAnchor === 'end') {
            xOffset = -width;
        }
        return {
            x: x + xOffset,
            y: y - height / 2,
            width: width,
            height: height,
        };
    };
    LinkLabel.prototype.componentDidMount = function () {
        this.recomputeBounds(this.props);
    };
    LinkLabel.prototype.componentWillUnmount = function () {
        var onBoundsUpdate = this.props.onBoundsUpdate;
        onBoundsUpdate(undefined);
    };
    LinkLabel.prototype.componentDidUpdate = function (nextProps) {
        this.shouldUpdateBounds = true;
    };
    LinkLabel.prototype.UNSAFE_componentWillReceiveProps = function () {
        this.recomputeBounds(this.props);
    };
    LinkLabel.prototype.recomputeBounds = function (props) {
        if (this.shouldUpdateBounds) {
            var onBoundsUpdate = this.props.onBoundsUpdate;
            this.shouldUpdateBounds = false;
            var bounds = this.text.getBBox();
            if (onBoundsUpdate) {
                var labelBounds = this.getLabelRectangle(bounds.width, bounds.height);
                onBoundsUpdate(labelBounds);
            }
            this.setState({
                width: bounds.width,
                height: bounds.height,
            });
        }
    };
    return LinkLabel;
}(react_1.Component));
var VertexTools = /** @class */ (function (_super) {
    tslib_1.__extends(VertexTools, _super);
    function VertexTools() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onRemoveVertex = function (e) {
            if (e.button !== 0 /* left button */) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            var _a = _this.props, onRemove = _a.onRemove, model = _a.model, vertexIndex = _a.vertexIndex;
            onRemove(new elements_1.LinkVertex(model, vertexIndex));
        };
        return _this;
    }
    VertexTools.prototype.render = function () {
        var _a = this.props, className = _a.className, vertexIndex = _a.vertexIndex, vertexRadius = _a.vertexRadius, x = _a.x, y = _a.y;
        var transform = "translate(".concat(x + 2 * vertexRadius, ",").concat(y - 2 * vertexRadius, ")scale(").concat(vertexRadius, ")");
        return (React.createElement("g", { className: className, transform: transform, onMouseDown: this.onRemoveVertex },
            React.createElement("title", null, "Remove vertex"),
            React.createElement("circle", { r: 1 }),
            React.createElement("path", { d: "M-0.5,-0.5 L0.5,0.5 M0.5,-0.5 L-0.5,0.5", strokeWidth: 2 / vertexRadius })));
    };
    return VertexTools;
}(react_1.Component));
var LinkMarkers = /** @class */ (function (_super) {
    tslib_1.__extends(LinkMarkers, _super);
    function LinkMarkers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.listener = new events_1.EventObserver();
        _this.delayedUpdate = new async_1.Debouncer();
        return _this;
    }
    LinkMarkers.prototype.render = function () {
        var view = this.props.view;
        var markers = [];
        view.getLinkTemplates().forEach(function (template, linkTypeId) {
            var type = view.model.getLinkType(linkTypeId);
            if (!type) {
                return;
            }
            var typeIndex = type.index;
            if (template.markerSource) {
                markers.push(React.createElement(LinkMarker, { key: typeIndex * 2, linkTypeIndex: typeIndex, style: template.markerSource, isStartMarker: true }));
            }
            if (template.markerTarget) {
                markers.push(React.createElement(LinkMarker, { key: typeIndex * 2 + 1, linkTypeIndex: typeIndex, style: template.markerTarget, isStartMarker: false }));
            }
        });
        return React.createElement("defs", null, markers);
    };
    LinkMarkers.prototype.componentDidMount = function () {
        var _this = this;
        var view = this.props.view;
        this.listener.listen(view.events, 'syncUpdate', function (_a) {
            var layer = _a.layer;
            if (layer !== view_1.RenderingLayer.Link) {
                return;
            }
            _this.delayedUpdate.runSynchronously();
        });
        this.listener.listen(view.events, 'changeLinkTemplates', function () {
            _this.delayedUpdate.call(function () { return _this.forceUpdate(); });
        });
    };
    LinkMarkers.prototype.shouldComponentUpdate = function () {
        return false;
    };
    LinkMarkers.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.delayedUpdate.dispose();
    };
    return LinkMarkers;
}(react_1.Component));
exports.LinkMarkers = LinkMarkers;
var SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
var LinkMarker = /** @class */ (function (_super) {
    tslib_1.__extends(LinkMarker, _super);
    function LinkMarker() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onMarkerMount = function (marker) {
            if (!marker) {
                return;
            }
            var _a = _this.props, linkTypeIndex = _a.linkTypeIndex, isStartMarker = _a.isStartMarker, style = _a.style;
            marker.setAttribute('id', (0, elements_1.linkMarkerKey)(linkTypeIndex, isStartMarker));
            marker.setAttribute('markerWidth', style.width.toString());
            marker.setAttribute('markerHeight', style.height.toString());
            marker.setAttribute('orient', 'auto');
            var xOffset = isStartMarker ? 0 : style.width - 1;
            marker.setAttribute('refX', xOffset.toString());
            marker.setAttribute('refY', (style.height / 2).toString());
            marker.setAttribute('markerUnits', 'userSpaceOnUse');
            var path = document.createElementNS(SVG_NAMESPACE, 'path');
            path.setAttribute('d', style.d);
            if (style.fill !== undefined) {
                path.setAttribute('fill', style.fill);
            }
            if (style.stroke !== undefined) {
                path.setAttribute('stroke', style.stroke);
            }
            if (style.strokeWidth !== undefined) {
                path.setAttribute('stroke-width', style.strokeWidth);
            }
            marker.appendChild(path);
        };
        return _this;
    }
    LinkMarker.prototype.render = function () {
        return React.createElement("marker", { ref: this.onMarkerMount });
    };
    LinkMarker.prototype.shouldComponentUpdate = function () {
        return false;
    };
    return LinkMarker;
}(react_1.Component));
