"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeGrouping = exports.findElementAtPoint = exports.findNearestSegmentIndex = exports.getPointAlongPolyline = exports.computePolylineLength = exports.computePolyline = exports.isPolylineEqual = exports.boundsOf = exports.Rect = exports.Vector = void 0;
var tslib_1 = require("tslib");
exports.Vector = {
    equals: function (a, b) {
        return a.x === b.x && a.y === b.y;
    },
    length: function (_a) {
        var x = _a.x, y = _a.y;
        return Math.sqrt(x * x + y * y);
    },
    normalize: function (_a) {
        var x = _a.x, y = _a.y;
        if (x === 0 && y === 0) {
            return { x: x, y: y };
        }
        var inverseLength = 1 / Math.sqrt(x * x + y * y);
        return { x: x * inverseLength, y: y * inverseLength };
    },
    dot: function (_a, _b) {
        var x1 = _a.x, y1 = _a.y;
        var x2 = _b.x, y2 = _b.y;
        return x1 * x2 + y1 * y2;
    },
    cross2D: function (_a, _b) {
        var x1 = _a.x, y1 = _a.y;
        var x2 = _b.x, y2 = _b.y;
        return x1 * y2 - y1 * x2;
    },
};
exports.Rect = {
    center: function (_a) {
        var x = _a.x, y = _a.y, width = _a.width, height = _a.height;
        return { x: x + width / 2, y: y + height / 2 };
    },
};
function boundsOf(element) {
    var _a = element.position, x = _a.x, y = _a.y;
    var _b = element.size, width = _b.width, height = _b.height;
    return { x: x, y: y, width: width, height: height };
}
exports.boundsOf = boundsOf;
function intersectRayFromRectangleCenter(sourceRect, rayTarget) {
    var isTargetInsideRect = sourceRect.width === 0 ||
        sourceRect.height === 0 ||
        (rayTarget.x > sourceRect.x &&
            rayTarget.x < sourceRect.x + sourceRect.width &&
            rayTarget.y > sourceRect.y &&
            rayTarget.y < sourceRect.y + sourceRect.height);
    var halfWidth = sourceRect.width / 2;
    var halfHeight = sourceRect.height / 2;
    var center = {
        x: sourceRect.x + halfWidth,
        y: sourceRect.y + halfHeight,
    };
    if (isTargetInsideRect) {
        return center;
    }
    var direction = exports.Vector.normalize({
        x: rayTarget.x - center.x,
        y: rayTarget.y - center.y,
    });
    var rightDirection = { x: Math.abs(direction.x), y: direction.y };
    var isHorizontal = exports.Vector.cross2D({ x: halfWidth, y: -halfHeight }, rightDirection) > 0 &&
        exports.Vector.cross2D({ x: halfWidth, y: halfHeight }, rightDirection) < 0;
    if (isHorizontal) {
        return {
            x: center.x + halfWidth * Math.sign(direction.x),
            y: center.y + (halfWidth * direction.y) / Math.abs(direction.x),
        };
    }
    else {
        return {
            x: center.x + (halfHeight * direction.x) / Math.abs(direction.y),
            y: center.y + halfHeight * Math.sign(direction.y),
        };
    }
}
function isPolylineEqual(left, right) {
    if (left === right) {
        return true;
    }
    if (left.length !== right.length) {
        return false;
    }
    for (var i = 0; i < left.length; i++) {
        var a = left[i];
        var b = right[i];
        if (!(a.x === b.x && a.y === b.y)) {
            return false;
        }
    }
    return true;
}
exports.isPolylineEqual = isPolylineEqual;
function computePolyline(source, target, vertices) {
    var sourceRect = boundsOf(source);
    var targetRect = boundsOf(target);
    var startPoint = intersectRayFromRectangleCenter(sourceRect, vertices.length > 0 ? vertices[0] : exports.Rect.center(targetRect));
    var endPoint = intersectRayFromRectangleCenter(targetRect, vertices.length > 0
        ? vertices[vertices.length - 1]
        : exports.Rect.center(sourceRect));
    return tslib_1.__spreadArray(tslib_1.__spreadArray([startPoint], vertices, true), [endPoint], false);
}
exports.computePolyline = computePolyline;
function computePolylineLength(polyline) {
    var previous;
    return polyline.reduce(function (acc, point) {
        var segmentLength = previous
            ? exports.Vector.length({ x: point.x - previous.x, y: point.y - previous.y })
            : 0;
        previous = point;
        return acc + segmentLength;
    }, 0);
}
exports.computePolylineLength = computePolylineLength;
function getPointAlongPolyline(polyline, offset) {
    if (polyline.length === 0) {
        throw new Error('Cannot compute a point for empty polyline');
    }
    if (offset < 0) {
        return polyline[0];
    }
    var currentOffset = 0;
    for (var i = 1; i < polyline.length; i++) {
        var previous = polyline[i - 1];
        var point = polyline[i];
        var segment = { x: point.x - previous.x, y: point.y - previous.y };
        var segmentLength = exports.Vector.length(segment);
        var newOffset = currentOffset + segmentLength;
        if (offset < newOffset) {
            var leftover = (offset - currentOffset) / segmentLength;
            return {
                x: previous.x + leftover * segment.x,
                y: previous.y + leftover * segment.y,
            };
        }
        else {
            currentOffset = newOffset;
        }
    }
    return polyline[polyline.length - 1];
}
exports.getPointAlongPolyline = getPointAlongPolyline;
function findNearestSegmentIndex(polyline, location) {
    var minDistance = Infinity;
    var foundIndex = 0;
    for (var i = 0; i < polyline.length - 1; i++) {
        var pivot = polyline[i];
        var next = polyline[i + 1];
        var target = { x: location.x - pivot.x, y: location.y - pivot.y };
        var segment = { x: next.x - pivot.x, y: next.y - pivot.y };
        var segmentLength = exports.Vector.length(segment);
        var projectionToSegment = exports.Vector.dot(target, segment) / segmentLength;
        if (projectionToSegment < 0 || projectionToSegment > segmentLength) {
            continue;
        }
        var distanceToSegment = Math.abs(exports.Vector.cross2D(target, segment)) / segmentLength;
        if (distanceToSegment < minDistance) {
            minDistance = distanceToSegment;
            foundIndex = i;
        }
    }
    return foundIndex;
}
exports.findNearestSegmentIndex = findNearestSegmentIndex;
function findElementAtPoint(elements, point) {
    for (var i = elements.length - 1; i >= 0; i--) {
        var element = elements[i];
        var _a = boundsOf(element), x = _a.x, y = _a.y, width = _a.width, height = _a.height;
        if (element.temporary) {
            continue;
        }
        if (point.x >= x &&
            point.x <= x + width &&
            point.y >= y &&
            point.y <= y + height) {
            return element;
        }
    }
    return undefined;
}
exports.findElementAtPoint = findElementAtPoint;
function computeGrouping(elements) {
    var grouping = new Map();
    for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
        var element = elements_1[_i];
        var group = element.group;
        if (typeof group === 'string') {
            var children = grouping.get(group);
            if (!children) {
                children = [];
                grouping.set(group, children);
            }
            children.push(element);
        }
    }
    return grouping;
}
exports.computeGrouping = computeGrouping;
