"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemporaryState = void 0;
var tslib_1 = require("tslib");
var model_1 = require("../data/model");
var collections_1 = require("../viewUtils/collections");
exports.TemporaryState = {
    empty: {
        elements: new Map(),
        links: new collections_1.HashMap(model_1.hashLink, model_1.sameLink),
    },
    addElement: function (state, element) {
        var elements = (0, collections_1.cloneMap)(state.elements);
        elements.set(element.id, element);
        return tslib_1.__assign(tslib_1.__assign({}, state), { elements: elements });
    },
    deleteElement: function (state, element) {
        var elements = (0, collections_1.cloneMap)(state.elements);
        elements.delete(element.id);
        return tslib_1.__assign(tslib_1.__assign({}, state), { elements: elements });
    },
    addLink: function (state, link) {
        var links = state.links.clone();
        links.set(link, link);
        return tslib_1.__assign(tslib_1.__assign({}, state), { links: links });
    },
    deleteLink: function (state, link) {
        var links = state.links.clone();
        links.delete(link);
        return tslib_1.__assign(tslib_1.__assign({}, state), { links: links });
    },
};
