"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sameElement = exports.hashLink = exports.sameLink = exports.isLiteralProperty = exports.isIriProperty = void 0;
var utils_1 = require("./utils");
function isIriProperty(e) {
    return e && e.type === 'uri';
}
exports.isIriProperty = isIriProperty;
function isLiteralProperty(e) {
    return e && e.type === 'string';
}
exports.isLiteralProperty = isLiteralProperty;
function sameLink(left, right) {
    return (left.linkTypeId === right.linkTypeId &&
        left.sourceId === right.sourceId &&
        left.targetId === right.targetId);
}
exports.sameLink = sameLink;
function hashLink(link) {
    var linkTypeId = link.linkTypeId, sourceId = link.sourceId, targetId = link.targetId;
    var hash = (0, utils_1.hashFnv32a)(linkTypeId);
    hash = hash * 31 + (0, utils_1.hashFnv32a)(sourceId);
    hash = hash * 31 + (0, utils_1.hashFnv32a)(targetId);
    return hash;
}
exports.hashLink = hashLink;
function sameElement(left, right) {
    return (left.id === right.id &&
        isArraysEqual(left.types, right.types) &&
        isLiteralsEqual(left.label.values, right.label.values) &&
        left.image === right.image &&
        isPropertiesEqual(left.properties, right.properties) &&
        ((!left.sources && !right.sources) ||
            (left.sources &&
                right.sources &&
                isArraysEqual(left.sources, right.sources))));
}
exports.sameElement = sameElement;
function isArraysEqual(left, right) {
    if (left.length !== right.length) {
        return false;
    }
    for (var i = 0; i < left.length; i++) {
        if (left[i] !== right[i]) {
            return false;
        }
    }
    return true;
}
function isLiteralsEqual(left, right) {
    if (left.length !== right.length) {
        return false;
    }
    for (var i = 0; i < left.length; i++) {
        var leftValue = left[i];
        var rightValue = right[i];
        if (leftValue.value !== rightValue.value ||
            leftValue.language !== rightValue.language) {
            return false;
        }
    }
    return true;
}
function isIriPropertiesEqual(left, right) {
    if (!isIriProperty(left) || !isIriProperty(right)) {
        return false;
    }
    if (left.values.length !== right.values.length) {
        return false;
    }
    for (var i = 0; i < left.values.length; i++) {
        var leftValue = left.values[i];
        var rightValue = right.values[i];
        if (leftValue.value !== rightValue.value) {
            return false;
        }
    }
    return true;
}
function isLiteralPropertiesEqual(left, right) {
    if (!isLiteralProperty(left) || !isLiteralProperty(right)) {
        return false;
    }
    return isLiteralsEqual(left.values, right.values);
}
function isPropertiesEqual(left, right) {
    if (Object.keys(left).length !== Object.keys(right).length) {
        return false;
    }
    for (var key in left.properties) {
        if (key in left.properties) {
            var leftProperty = left[key];
            var rightProperty = right[key];
            if (!rightProperty) {
                return false;
            }
            if (!isIriPropertiesEqual(leftProperty, rightProperty) &&
                !isLiteralPropertiesEqual(leftProperty, rightProperty)) {
                return false;
            }
        }
    }
    return true;
}
