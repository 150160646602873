"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLinkData = exports.setElementData = exports.changeLinkTypeVisibility = exports.setElementExpanded = exports.restoreCapturedLinkGeometry = exports.RestoreGeometry = void 0;
var tslib_1 = require("tslib");
var model_1 = require("../data/model");
var geometry_1 = require("./geometry");
var history_1 = require("./history");
var RestoreGeometry = /** @class */ (function () {
    function RestoreGeometry(elementState, linkState) {
        this.elementState = elementState;
        this.linkState = linkState;
        this.title = 'Move elements and links';
    }
    RestoreGeometry.capture = function (model) {
        return RestoreGeometry.captureElementsAndLinks(model.elements, model.links);
    };
    RestoreGeometry.captureElementsAndLinks = function (elements, links) {
        return new RestoreGeometry(elements.map(function (element) { return ({ element: element, position: element.position }); }), links.map(function (link) { return ({ link: link, vertices: link.vertices }); }));
    };
    RestoreGeometry.prototype.hasChanges = function () {
        return this.elementState.length > 0 || this.linkState.length > 0;
    };
    RestoreGeometry.prototype.filterOutUnchanged = function () {
        return new RestoreGeometry(this.elementState.filter(function (_a) {
            var element = _a.element, position = _a.position;
            return !geometry_1.Vector.equals(element.position, position);
        }), this.linkState.filter(function (_a) {
            var link = _a.link, vertices = _a.vertices;
            return !(0, geometry_1.isPolylineEqual)(link.vertices, vertices);
        }));
    };
    RestoreGeometry.prototype.invoke = function () {
        var previous = RestoreGeometry.captureElementsAndLinks(this.elementState.map(function (state) { return state.element; }), this.linkState.map(function (state) { return state.link; }));
        // restore in reverse order to workaround position changed event
        // handling in EmbeddedLayer inside nested elements
        // (child's position change causes group to resize or move itself)
        for (var _i = 0, _a = tslib_1.__spreadArray([], this.elementState, true).reverse(); _i < _a.length; _i++) {
            var _b = _a[_i], element = _b.element, position = _b.position;
            element.setPosition(position);
        }
        for (var _c = 0, _d = this.linkState; _c < _d.length; _c++) {
            var _e = _d[_c], link = _e.link, vertices = _e.vertices;
            link.setVertices(vertices);
        }
        return previous;
    };
    return RestoreGeometry;
}());
exports.RestoreGeometry = RestoreGeometry;
function restoreCapturedLinkGeometry(link) {
    var vertices = link.vertices;
    return history_1.Command.create('Change link vertices', function () {
        var capturedInverse = restoreCapturedLinkGeometry(link);
        link.setVertices(vertices);
        return capturedInverse;
    });
}
exports.restoreCapturedLinkGeometry = restoreCapturedLinkGeometry;
function setElementExpanded(element, expanded) {
    var title = expanded ? 'Expand element' : 'Collapse element';
    return history_1.Command.create(title, function () {
        element.setExpanded(expanded);
        return setElementExpanded(element, !expanded);
    });
}
exports.setElementExpanded = setElementExpanded;
function changeLinkTypeVisibility(params) {
    var linkType = params.linkType, visible = params.visible, showLabel = params.showLabel, preventLoading = params.preventLoading;
    return history_1.Command.create('Change link type visibility', function () {
        var previousVisible = linkType.visible;
        var previousShowLabel = linkType.showLabel;
        linkType.setVisibility({ visible: visible, showLabel: showLabel, preventLoading: preventLoading });
        return changeLinkTypeVisibility({
            linkType: linkType,
            visible: previousVisible,
            showLabel: previousShowLabel,
            preventLoading: preventLoading,
        });
    });
}
exports.changeLinkTypeVisibility = changeLinkTypeVisibility;
function setElementData(model, target, data) {
    var elements = model.elements.filter(function (el) { return el.iri === target; });
    var previous = elements.length > 0 ? elements[0].data : undefined;
    return history_1.Command.create('Set element data', function () {
        for (var _i = 0, _a = model.elements.filter(function (el) { return el.iri === target; }); _i < _a.length; _i++) {
            var element = _a[_i];
            element.setData(data);
        }
        return setElementData(model, data.id, previous);
    });
}
exports.setElementData = setElementData;
function setLinkData(model, oldData, newData) {
    if (!(0, model_1.sameLink)(oldData, newData)) {
        throw new Error('Cannot change typeId, sourceId or targetId when changing link data');
    }
    return history_1.Command.create('Set link data', function () {
        for (var _i = 0, _a = model.links; _i < _a.length; _i++) {
            var link = _a[_i];
            if ((0, model_1.sameLink)(link.data, oldData)) {
                link.setData(newData);
            }
        }
        return setLinkData(model, newData, oldData);
    });
}
exports.setLinkData = setLinkData;
