"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultLinkRouter = void 0;
var geometry_1 = require("./geometry");
var DefaultLinkRouter = /** @class */ (function () {
    function DefaultLinkRouter(gap) {
        if (gap === void 0) { gap = 20; }
        this.gap = gap;
    }
    DefaultLinkRouter.prototype.route = function (model) {
        var routings = new Map();
        for (var _i = 0, _a = model.links; _i < _a.length; _i++) {
            var link = _a[_i];
            if (routings.has(link.id)) {
                continue;
            }
            // The cell is a link. Let's find its source and target models.
            var sourceId = link.sourceId, targetId = link.targetId;
            if (!sourceId || !targetId) {
                continue;
            }
            else if (sourceId === targetId) {
                this.routeFeedbackSiblingLinks(model, sourceId, routings);
            }
            else {
                this.routeNormalSiblingLinks(model, sourceId, targetId, routings);
            }
        }
        return routings;
    };
    DefaultLinkRouter.prototype.routeFeedbackSiblingLinks = function (model, elementId, routings) {
        var element = model.getElement(elementId);
        var _a = element.position, x = _a.x, y = _a.y;
        var _b = element.size, width = _b.width, height = _b.height;
        var index = 0;
        for (var _i = 0, _c = element.links; _i < _c.length; _i++) {
            var sibling = _c[_i];
            if (routings.has(sibling.id) || hasUserPlacedVertices(sibling)) {
                continue;
            }
            var sourceId = sibling.sourceId, targetId = sibling.targetId;
            if (sourceId === targetId) {
                var offset = this.gap * (index + 1);
                var vertices = [
                    { x: x - offset, y: y + height / 2 },
                    { x: x - offset, y: y - offset },
                    { x: x + width / 2, y: y - offset },
                ];
                routings.set(sibling.id, { linkId: sibling.id, vertices: vertices });
                index++;
            }
        }
    };
    DefaultLinkRouter.prototype.routeNormalSiblingLinks = function (model, sourceId, targetId, routings) {
        var _this = this;
        var source = model.getElement(sourceId);
        var target = model.getElement(targetId);
        var sourceCenter = centerOfElement(source);
        var targetCenter = centerOfElement(target);
        var midPoint = {
            x: (sourceCenter.x + targetCenter.x) / 2,
            y: (sourceCenter.y + targetCenter.y) / 2,
        };
        var direction = geometry_1.Vector.normalize({
            x: targetCenter.x - sourceCenter.x,
            y: targetCenter.y - sourceCenter.y,
        });
        var siblings = source.links.filter(function (link) {
            return (link.sourceId === targetId || link.targetId === targetId) &&
                !routings.has(link.id) &&
                !hasUserPlacedVertices(link);
        });
        if (siblings.length <= 1) {
            return;
        }
        var indexModifier = siblings.length % 2 ? 0 : 1;
        siblings.forEach(function (sibling, siblingIndex) {
            // For mor beautifull positioning
            var index = siblingIndex + indexModifier;
            // We want the offset values to be calculated as follows 0, 50, 50, 100, 100, 150, 150 ..
            var offset = _this.gap * Math.ceil(index / 2) - (indexModifier ? _this.gap / 2 : 0);
            // Now we need the vertices to be placed at points which are 'offset' pixels distant
            // from the first link and forms a perpendicular angle to it. And as index goes up
            // alternate left and right.
            //
            //  ^  odd indexes
            //  |
            //  |---->  index 0 line (straight line between a source center and a target center.
            //  |
            //  v  even indexes
            var offsetDirection = index % 2
                ? { x: -direction.y, y: direction.x } // rotate by 90 degrees counter-clockwise
                : { x: direction.y, y: -direction.x }; // rotate by 90 degrees clockwise
            // We found the vertex.
            var vertex = {
                x: midPoint.x + offsetDirection.x * offset,
                y: midPoint.y + offsetDirection.y * offset,
            };
            routings.set(sibling.id, {
                linkId: sibling.id,
                vertices: [vertex],
                labelTextAnchor: _this.getLabelAlignment(direction, siblingIndex, siblings.length),
            });
        });
    };
    DefaultLinkRouter.prototype.getLabelAlignment = function (connectionDirection, siblingIndex, siblingCount) {
        // offset direction angle in [0; 2 Pi] interval
        var angle = Math.atan2(connectionDirection.y, connectionDirection.x);
        var absoluteAngle = Math.abs(angle);
        var isHorizontal = absoluteAngle < (Math.PI * 1) / 8 || absoluteAngle > (Math.PI * 7) / 8;
        var isTop = angle < 0;
        var isBottom = angle > 0;
        var firstOuter = siblingCount - 2;
        var secondOuter = siblingCount - 1;
        if (!isHorizontal) {
            if ((isTop && siblingIndex === secondOuter) ||
                (isBottom && siblingIndex === firstOuter)) {
                return 'end';
            }
            else if ((isTop && siblingIndex === firstOuter) ||
                (isBottom && siblingIndex === secondOuter)) {
                return 'start';
            }
        }
        return 'middle';
    };
    return DefaultLinkRouter;
}());
exports.DefaultLinkRouter = DefaultLinkRouter;
function hasUserPlacedVertices(link) {
    var vertices = link.vertices;
    return vertices && vertices.length > 0;
}
function centerOfElement(element) {
    var _a = element.position, x = _a.x, y = _a.y;
    var _b = element.size, width = _b.width, height = _b.height;
    return { x: x + width / 2, y: y + height / 2 };
}
