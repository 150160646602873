"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeLayout = exports.makeGraphItems = exports.GraphBuilder = void 0;
var lodash_1 = require("lodash");
var schema_1 = require("../schema");
var serializedDiagram_1 = require("../../editor/serializedDiagram");
var layout_1 = require("../../viewUtils/layout");
var turtle_1 = require("./turtle");
var GREED_STEP = 150;
var GraphBuilder = /** @class */ (function () {
    function GraphBuilder(dataProvider) {
        this.dataProvider = dataProvider;
    }
    GraphBuilder.prototype.createGraph = function (graph) {
        return this.dataProvider
            .elementInfo({ elementIds: graph.elementIds })
            .then(function (elementsInfo) { return ({
            preloadedElements: elementsInfo,
            diagram: makeLayout(graph.elementIds, graph.links),
        }); });
    };
    GraphBuilder.prototype.getGraphFromRDFGraph = function (graph) {
        var _a = makeGraphItems(graph), elementIds = _a.elementIds, links = _a.links;
        return this.createGraph({ elementIds: elementIds, links: links });
    };
    GraphBuilder.prototype.getGraphFromTurtleGraph = function (graph) {
        var _this = this;
        return (0, turtle_1.parseTurtleText)(graph).then(function (triples) {
            return _this.getGraphFromRDFGraph(triples);
        });
    };
    return GraphBuilder;
}());
exports.GraphBuilder = GraphBuilder;
function makeGraphItems(response) {
    var elements = {};
    var links = [];
    for (var _i = 0, response_1 = response; _i < response_1.length; _i++) {
        var _a = response_1[_i], subject = _a.subject, predicate = _a.predicate, object = _a.object;
        if (subject.type === 'uri' && !elements[subject.value]) {
            elements[subject.value] = true;
        }
        if (object.type === 'uri' && !elements[object.value]) {
            elements[object.value] = true;
        }
        if (subject.type === 'uri' && object.type === 'uri') {
            links.push({
                linkTypeId: predicate.value,
                sourceId: subject.value,
                targetId: object.value,
            });
        }
    }
    return { elementIds: Object.keys(elements), links: links };
}
exports.makeGraphItems = makeGraphItems;
function makeLayout(elementsIds, linksInfo) {
    var rows = Math.ceil(Math.sqrt(elementsIds.length));
    var grid = (0, layout_1.uniformGrid)({
        rows: rows,
        cellSize: { x: GREED_STEP, y: GREED_STEP },
    });
    var elements = elementsIds.map(function (id, index) {
        var _a = grid(index), x = _a.x, y = _a.y;
        return {
            '@type': 'Element',
            '@id': schema_1.GenerateID.forElement(),
            iri: id,
            position: { x: x, y: y },
        };
    });
    var layoutElementsMap = (0, lodash_1.keyBy)(elements, 'iri');
    var links = [];
    linksInfo.forEach(function (link, index) {
        var source = layoutElementsMap[link.sourceId];
        var target = layoutElementsMap[link.targetId];
        if (!source || !target) {
            return;
        }
        links.push({
            '@type': 'Link',
            '@id': schema_1.GenerateID.forLink(),
            property: link.linkTypeId,
            source: { '@id': source['@id'] },
            target: { '@id': target['@id'] },
        });
    });
    return (0, serializedDiagram_1.makeSerializedDiagram)({
        layoutData: { '@type': 'Layout', elements: elements, links: links },
        linkTypeOptions: [],
    });
}
exports.makeLayout = makeLayout;
