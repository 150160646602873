"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditLinkForm = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var model_1 = require("../data/model");
var elements_1 = require("../diagram/elements");
var async_1 = require("../viewUtils/async");
var progressBar_1 = require("../widgets/progressBar");
var linkTypeSelector_1 = require("./linkTypeSelector");
var CLASS_NAME = 'graph-explorer-edit-form';
var EditLinkForm = /** @class */ (function (_super) {
    tslib_1.__extends(EditLinkForm, _super);
    function EditLinkForm(props) {
        var _this = _super.call(this, props) || this;
        _this.validationCancellation = new async_1.Cancellation();
        _this.state = {
            linkValue: {
                value: { link: props.link, direction: elements_1.LinkDirection.out },
                validated: true,
                allowChange: true,
            },
        };
        return _this;
    }
    EditLinkForm.prototype.componentDidMount = function () {
        this.validate();
    };
    EditLinkForm.prototype.componentDidUpdate = function (prevProps, prevState) {
        var linkValue = this.state.linkValue;
        if (!(0, model_1.sameLink)(linkValue.value.link, prevState.linkValue.value.link)) {
            this.validate();
        }
        if (linkValue !== prevState.linkValue &&
            linkValue.validated &&
            linkValue.allowChange) {
            this.props.onChange(linkValue.value.link);
        }
    };
    EditLinkForm.prototype.componentWillUnmount = function () {
        this.validationCancellation.abort();
    };
    EditLinkForm.prototype.validate = function () {
        var _this = this;
        var _a = this.props, editor = _a.editor, originalLink = _a.link;
        var value = this.state.linkValue.value;
        this.setState({ isValidating: true });
        this.validationCancellation.abort();
        this.validationCancellation = new async_1.Cancellation();
        var signal = this.validationCancellation.signal;
        (0, linkTypeSelector_1.validateLinkType)(editor, value.link, originalLink).then(function (error) {
            if (signal.aborted) {
                return;
            }
            _this.setState(function (_a) {
                var linkValue = _a.linkValue;
                return ({
                    linkValue: tslib_1.__assign(tslib_1.__assign(tslib_1.__assign({}, linkValue), error), { validated: true }),
                    isValidating: false,
                });
            });
        });
    };
    EditLinkForm.prototype.render = function () {
        var _this = this;
        var _a = this.props, editor = _a.editor, view = _a.view, metadataApi = _a.metadataApi, source = _a.source, target = _a.target;
        var _b = this.state, linkValue = _b.linkValue, isValidating = _b.isValidating;
        var isValid = !linkValue.error;
        return (React.createElement("div", { className: CLASS_NAME },
            React.createElement("div", { className: "".concat(CLASS_NAME, "__body") },
                React.createElement(linkTypeSelector_1.LinkTypeSelector, { editor: editor, view: view, metadataApi: metadataApi, linkValue: linkValue, source: source, target: target, onChange: function (value) {
                        return _this.setState({
                            linkValue: {
                                value: value,
                                error: undefined,
                                validated: false,
                                allowChange: false,
                            },
                        });
                    } }),
                isValidating ? (React.createElement("div", { className: "".concat(CLASS_NAME, "__progress") },
                    React.createElement(progressBar_1.ProgressBar, { state: progressBar_1.ProgressState.loading, height: 10 }))) : null),
            React.createElement("div", { className: "".concat(CLASS_NAME, "__controls") },
                React.createElement("button", { className: "graph-explorer-btn graph-explorer-btn-success ".concat(CLASS_NAME, "__apply-button"), onClick: function () { return _this.props.onApply(linkValue.value.link); }, disabled: !isValid || isValidating }, "Apply"),
                React.createElement("button", { className: "graph-explorer-btn graph-explorer-btn-danger", onClick: this.props.onCancel }, "Cancel"))));
    };
    return EditLinkForm;
}(React.Component));
exports.EditLinkForm = EditLinkForm;
