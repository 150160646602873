"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.easeInOutBezier = exports.animateInterval = exports.delay = exports.CancelledError = exports.CancellationToken = exports.Cancellation = exports.Debouncer = exports.BufferingQueue = exports.BatchingScheduler = void 0;
var tslib_1 = require("tslib");
var events_1 = require("./events");
var BatchingScheduler = /** @class */ (function () {
    function BatchingScheduler(waitingTime) {
        if (waitingTime === void 0) { waitingTime = 0; }
        this.waitingTime = waitingTime;
        this.useAnimationFrame = waitingTime === 0;
        this.runSynchronously = this.runSynchronously.bind(this);
    }
    BatchingScheduler.prototype.schedule = function () {
        if (typeof this.scheduled === "undefined") {
            if (this.useAnimationFrame) {
                this.scheduled = requestAnimationFrame(this.runSynchronously);
            }
            else {
                this.scheduled = Number(setTimeout(this.runSynchronously, this.waitingTime));
            }
        }
    };
    BatchingScheduler.prototype.runSynchronously = function () {
        var wasScheduled = this.cancelScheduledTimeout();
        if (wasScheduled) {
            this.run();
        }
    };
    BatchingScheduler.prototype.dispose = function () {
        this.cancelScheduledTimeout();
    };
    BatchingScheduler.prototype.cancelScheduledTimeout = function () {
        if (typeof this.scheduled !== "undefined") {
            if (this.useAnimationFrame) {
                cancelAnimationFrame(this.scheduled);
            }
            else {
                clearTimeout(this.scheduled);
            }
            this.scheduled = undefined;
            return true;
        }
        return false;
    };
    return BatchingScheduler;
}());
exports.BatchingScheduler = BatchingScheduler;
var BufferingQueue = /** @class */ (function (_super) {
    tslib_1.__extends(BufferingQueue, _super);
    function BufferingQueue(onFetch, waitingTime) {
        if (waitingTime === void 0) { waitingTime = 200; }
        var _this = _super.call(this, waitingTime) || this;
        _this.onFetch = onFetch;
        _this.fetchingQueue = Object.create(null);
        return _this;
    }
    BufferingQueue.prototype.push = function (key) {
        this.fetchingQueue[key] = true;
        this.schedule();
    };
    BufferingQueue.prototype.clear = function () {
        this.fetchingQueue = Object.create(null);
    };
    BufferingQueue.prototype.run = function () {
        var _a = this, fetchingQueue = _a.fetchingQueue, onFetch = _a.onFetch;
        this.fetchingQueue = Object.create(null);
        onFetch(Object.keys(fetchingQueue));
    };
    return BufferingQueue;
}(BatchingScheduler));
exports.BufferingQueue = BufferingQueue;
var Debouncer = /** @class */ (function (_super) {
    tslib_1.__extends(Debouncer, _super);
    function Debouncer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Debouncer.prototype.call = function (callback) {
        this.callback = callback;
        this.schedule();
    };
    Debouncer.prototype.run = function () {
        var callback = this.callback;
        callback();
    };
    return Debouncer;
}(BatchingScheduler));
exports.Debouncer = Debouncer;
var Cancellation = /** @class */ (function () {
    function Cancellation() {
        this.source = new events_1.EventSource();
        this.aborted = false;
        this.signal = new (/** @class */ (function () {
            function class_1(parent) {
                this.parent = parent;
            }
            Object.defineProperty(class_1.prototype, "aborted", {
                get: function () {
                    return this.parent.aborted;
                },
                enumerable: false,
                configurable: true
            });
            class_1.prototype.addEventListener = function (event, handler) {
                if (event !== "abort") {
                    return;
                }
                if (this.parent.source) {
                    this.parent.source.on("abort", handler);
                }
                else {
                    handler();
                }
            };
            class_1.prototype.removeEventListener = function (event, handler) {
                if (event !== "abort") {
                    return;
                }
                if (this.parent.source) {
                    this.parent.source.off("abort", handler);
                }
            };
            return class_1;
        }()))(this);
    }
    Cancellation.prototype.abort = function () {
        if (this.aborted) {
            return;
        }
        this.aborted = true;
        this.source.trigger("abort", undefined);
        this.source = undefined;
    };
    Cancellation.NEVER_SIGNAL = {
        aborted: false,
        addEventListener: function () {
            /* nothing */
        },
        removeEventListener: function () {
            /* nothing */
        },
    };
    return Cancellation;
}());
exports.Cancellation = Cancellation;
exports.CancellationToken = {
    throwIfAborted: function (ct) {
        if (ct.aborted) {
            throw new CancelledError();
        }
    },
    mapCancelledToNull: function (ct, promise) {
        var onResolve = function (value) {
            if (ct.aborted) {
                return null;
            }
            return value;
        };
        var onReject = function (err) {
            if (ct.aborted) {
                return null;
            }
            return Promise.reject(err);
        };
        return promise.then(onResolve, onReject);
    },
};
var CancelledError = /** @class */ (function (_super) {
    tslib_1.__extends(CancelledError, _super);
    function CancelledError(message) {
        if (message === void 0) { message = "Operation was cancelled"; }
        var _this = _super.call(this, message) || this;
        _this.name = CancelledError.name;
        Object.setPrototypeOf(_this, CancelledError.prototype);
        return _this;
    }
    return CancelledError;
}(Error));
exports.CancelledError = CancelledError;
function delay(timeout) {
    return new Promise(function (resolve) { return setTimeout(resolve, timeout); });
}
exports.delay = delay;
function animateInterval(duration, onProgress, cancellation) {
    return new Promise(function (resolve) {
        var animationFrameId;
        var start;
        var animate = function (time) {
            if (cancellation && cancellation.signal.aborted) {
                return;
            }
            start = start || time;
            var timePassed = time - start;
            if (timePassed > duration) {
                timePassed = duration;
            }
            onProgress(timePassed / duration);
            if (timePassed < duration) {
                animationFrameId = requestAnimationFrame(animate);
            }
            else {
                resolve();
            }
        };
        cancellation.signal.addEventListener("abort", function () {
            cancelAnimationFrame(animationFrameId);
            resolve();
        });
        animationFrameId = requestAnimationFrame(animate);
    });
}
exports.animateInterval = animateInterval;
function easeInOutBezier(t) {
    if (t < 0) {
        return 0;
    }
    if (t > 1) {
        return 1;
    }
    return t * t * (3.0 - 2.0 * t);
}
exports.easeInOutBezier = easeInOutBezier;
