"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var CLASS_NAME = 'graph-explorer-accordion';
var Accordion = /** @class */ (function (_super) {
    tslib_1.__extends(Accordion, _super);
    function Accordion(props) {
        var _this = _super.call(this, props) || this;
        _this.items = [];
        _this.isScrollable = false;
        _this.updateSizes = function () {
            var children = _this.props.children;
            var defaultProps = new Map();
            React.Children.forEach(children, function (child, index) {
                if (typeof child !== 'object') {
                    return;
                }
                var _a = child.props, defaultSize = _a.defaultSize, defaultCollapsed = _a.defaultCollapsed, collapsedSize = _a.collapsedSize, minSize = _a.minSize;
                // enables the scrollbar in the accordion if at least one item has min size
                if (minSize !== undefined) {
                    _this.isScrollable = true;
                }
                defaultProps.set(index, {
                    defaultSize: defaultSize,
                    defaultCollapsed: defaultCollapsed,
                    collapsedSize: collapsedSize,
                    minSize: minSize,
                });
            });
            _this.defaultProps = defaultProps;
            _this.setState(function (_a) {
                var collapsed = _a.collapsed;
                var totalSize = _this.clientSize(_this.element.parentElement);
                var leftSize = totalSize;
                var leftChildCount = React.Children.count(children);
                var newCollapsed = [];
                _this.defaultProps.forEach(function (_a, index) {
                    var defaultSize = _a.defaultSize, _b = _a.defaultCollapsed, defaultCollapsed = _b === void 0 ? false : _b;
                    // preserves items collapsed by user
                    var itemCollapsed = collapsed[index] === undefined
                        ? defaultCollapsed
                        : collapsed[index];
                    // if no expanded items, expands the last one
                    var isLastItem = index === _this.defaultProps.size - 1;
                    var noExpandedItems = newCollapsed.findIndex(function (c) { return !c; }) < 0;
                    if (isLastItem && noExpandedItems) {
                        itemCollapsed = false;
                    }
                    var size = itemCollapsed
                        ? _this.sizeWhenCollapsed(index)
                        : defaultSize;
                    if (size) {
                        leftSize = leftSize - size;
                        leftChildCount = leftChildCount - 1;
                    }
                    newCollapsed.push(itemCollapsed);
                });
                var newSizes = [];
                var newPercents = [];
                _this.defaultProps.forEach(function (_a, index) {
                    var defaultSize = _a.defaultSize, minSize = _a.minSize;
                    var size = leftSize / leftChildCount;
                    var collapsedSize = _this.sizeWhenCollapsed(index);
                    if (newCollapsed[index]) {
                        size = collapsedSize;
                    }
                    else if (defaultSize !== undefined) {
                        size = defaultSize;
                    }
                    else if (size < minSize) {
                        size = collapsedSize + minSize;
                    }
                    if (size < collapsedSize) {
                        size = collapsedSize;
                        newCollapsed[index] = true;
                    }
                    var percent = "".concat((100 * size) / totalSize, "%");
                    newSizes.push(size);
                    newPercents.push(percent);
                });
                return {
                    sizes: newSizes,
                    percents: newPercents,
                    collapsed: newCollapsed,
                };
            });
        };
        _this.onBeginDragHandle = function (itemIndex) {
            _this.originTotalSize = _this.clientSize(_this.element);
            _this.originSizes = _this.computeEffectiveItemSizes();
            _this.originCollapsed = tslib_1.__spreadArray([], _this.state.collapsed, true);
            _this.setState({ resizing: true }, function () {
                var _a = _this.props, direction = _a.direction, onStartResize = _a.onStartResize;
                if (onStartResize) {
                    onStartResize(direction);
                }
            });
        };
        _this.onEndDragHandle = function () {
            _this.setState({ resizing: false });
        };
        _this.sizeWhenCollapsed = function (index) {
            var item = _this.items[index];
            var collapsedSize = _this.defaultProps.get(index).collapsedSize;
            if (collapsedSize !== undefined) {
                return collapsedSize;
            }
            var headerSize = item.header ? _this.clientSize(item.header) : 0;
            return (headerSize +
                (_this.offsetSize(item.element) - _this.clientSize(item.element)));
        };
        _this.onDragHandle = function (itemIndex, dx, dy) {
            var sizes = tslib_1.__spreadArray([], _this.originSizes, true);
            var collapsed = tslib_1.__spreadArray([], _this.originCollapsed, true);
            new SizeDistributor(sizes, collapsed, _this.originTotalSize, _this.sizeWhenCollapsed).distribute(itemIndex + 1, _this.isVertical ? dy : dx);
            var percents = sizes.map(function (size) { return "".concat((100 * size) / _this.originTotalSize, "%"); });
            _this.setState({ sizes: sizes, percents: percents, collapsed: collapsed });
        };
        var childCount = React.Children.count(_this.props.children);
        _this.state = {
            collapsed: [],
            resizing: false,
            percents: React.Children.map(_this.props.children, function () { return "".concat(100 / childCount, "%"); }),
        };
        return _this;
    }
    Accordion.prototype.componentDidMount = function () {
        this.updateSizes();
    };
    Accordion.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a = this.props, direction = _a.direction, children = _a.children, onResize = _a.onResize, animationDuration = _a.animationDuration;
        if (React.Children.count(children) !==
            React.Children.count(prevProps.children)) {
            this.updateSizes();
        }
        var _b = this.state, sizes = _b.sizes, resizing = _b.resizing;
        if (sizes !== prevState.sizes && onResize) {
            if (resizing) {
                onResize(direction);
            }
            else {
                // triggers the callback after finishing CSS animation
                setTimeout(function () { return onResize(direction); }, animationDuration);
            }
        }
    };
    Object.defineProperty(Accordion.prototype, "isVertical", {
        get: function () {
            return this.props.direction === 'vertical';
        },
        enumerable: false,
        configurable: true
    });
    Accordion.prototype.clientSize = function (element) {
        var clientWidth = element.clientWidth, clientHeight = element.clientHeight;
        return this.isVertical ? clientHeight : clientWidth;
    };
    Accordion.prototype.offsetSize = function (element) {
        var offsetWidth = element.offsetWidth, offsetHeight = element.offsetHeight;
        return this.isVertical ? offsetHeight : offsetWidth;
    };
    Accordion.prototype.render = function () {
        var _this = this;
        var direction = this.props.direction;
        var resizing = this.state.resizing;
        var resizingClassName = resizing ? "".concat(CLASS_NAME, "--resizing") : '';
        var scrollableClassName = this.isScrollable
            ? "".concat(CLASS_NAME, "--scrollable")
            : '';
        return (React.createElement("div", { className: "".concat(CLASS_NAME, " ").concat(CLASS_NAME, "--").concat(direction, " ").concat(resizingClassName, " ").concat(scrollableClassName), ref: function (element) { return (_this.element = element); } }, this.renderItems()));
    };
    Accordion.prototype.renderItems = function () {
        var _this = this;
        var _a = this.state, sizes = _a.sizes, percents = _a.percents, collapsed = _a.collapsed;
        var _b = this.props, children = _b.children, direction = _b.direction;
        return React.Children.map(children, function (child, index) {
            if (typeof child !== 'object') {
                throw new Error('Accordion should have only AccordionItem elements as children');
            }
            var lastChild = index === React.Children.count(children) - 1;
            var size = collapsed[index] ? sizes[index] : percents[index];
            var additionalProps = {
                ref: function (element) { return (_this.items[index] = element); },
                collapsed: collapsed[index],
                size: size,
                direction: direction,
                onChangeCollapsed: function (itemCollapsed) {
                    return _this.onItemChangeCollapsed({ itemIndex: index, itemCollapsed: itemCollapsed });
                },
                onBeginDragHandle: lastChild
                    ? undefined
                    : function () { return _this.onBeginDragHandle(index); },
                onDragHandle: lastChild
                    ? undefined
                    : function (dx, dy) { return _this.onDragHandle(index, dx, dy); },
                onEndDragHandle: _this.onEndDragHandle,
            };
            return React.cloneElement(child, additionalProps);
        });
    };
    Accordion.prototype.computeEffectiveItemSizes = function () {
        var _this = this;
        var sizes = [];
        this.items.forEach(function (item, index) {
            if (!item) {
                return;
            }
            if (_this.state.collapsed[index]) {
                var collapsedSize = _this.sizeWhenCollapsed(index);
                sizes.push(collapsedSize);
            }
            else {
                sizes.push(_this.offsetSize(item.element));
            }
        });
        return sizes;
    };
    Accordion.prototype.onItemChangeCollapsed = function (_a) {
        var itemIndex = _a.itemIndex, itemCollapsed = _a.itemCollapsed;
        var totalSize = this.clientSize(this.element);
        var sizes = this.computeEffectiveItemSizes();
        if (sizes.length === 1) {
            return;
        }
        var collapsed = tslib_1.__spreadArray([], this.state.collapsed, true);
        var effectiveSize = sizes[itemIndex];
        var collapsedSize = this.sizeWhenCollapsed(itemIndex);
        var distributor = new SizeDistributor(sizes, collapsed, totalSize, this.sizeWhenCollapsed);
        if (itemCollapsed) {
            var splitShift = Math.max(effectiveSize - collapsedSize, 0);
            sizes[itemIndex] = collapsedSize;
            if (itemIndex === sizes.length - 1) {
                distributor.expand(splitShift, itemIndex - 1);
            }
            else {
                distributor.expand(splitShift, itemIndex + 1);
            }
        }
        else {
            var _b = this.defaultProps.get(itemIndex), defaultSize = _b.defaultSize, minSize = _b.minSize;
            var shift = (defaultSize || totalSize / sizes.length) - collapsedSize;
            var freeSize = 0;
            if (itemIndex === sizes.length - 1) {
                freeSize = distributor.collapseForward({
                    shift: shift,
                    from: itemIndex - 1,
                    to: itemIndex,
                });
            }
            else {
                freeSize = distributor.collapseForward({
                    shift: shift,
                    from: itemIndex + 1,
                    to: sizes.length,
                });
            }
            freeSize = Math.max(freeSize, distributor.leftoverSize());
            if (freeSize < shift) {
                freeSize += distributor.collapseForward({
                    shift: shift - freeSize,
                    from: 0,
                    to: itemIndex,
                });
            }
            if (freeSize < minSize) {
                freeSize = minSize;
            }
            sizes[itemIndex] = Math.round(collapsedSize + freeSize);
        }
        collapsed[itemIndex] = itemCollapsed;
        var percents = sizes.map(function (size) { return "".concat((100 * size) / totalSize, "%"); });
        this.setState({ sizes: sizes, percents: percents, collapsed: collapsed });
    };
    Accordion.defaultProps = {
        direction: 'vertical',
        animationDuration: 300,
    };
    return Accordion;
}(React.Component));
exports.Accordion = Accordion;
var SizeDistributor = /** @class */ (function () {
    function SizeDistributor(sizes, collapsed, totalSize, sizeWhenCollapsed) {
        this.sizes = sizes;
        this.collapsed = collapsed;
        this.totalSize = totalSize;
        this.sizeWhenCollapsed = sizeWhenCollapsed;
    }
    SizeDistributor.prototype.distribute = function (splitIndex, splitShift) {
        if (splitShift > 0) {
            var freeSize = this.collapseForward({
                shift: splitShift,
                from: splitIndex,
                to: this.sizes.length,
            });
            freeSize = Math.max(freeSize, this.leftoverSize());
            this.expand(freeSize, splitIndex - 1);
        }
        else {
            var freeSize = this.collapseBackward({
                shift: -splitShift,
                from: 0,
                to: splitIndex,
            });
            freeSize = Math.max(freeSize, this.leftoverSize());
            this.expand(freeSize, splitIndex);
        }
    };
    SizeDistributor.prototype.collapseForward = function (_a) {
        var shift = _a.shift, from = _a.from, to = _a.to;
        if (shift <= 0) {
            return 0;
        }
        var shiftLeft = shift;
        for (var i = from; i < to; i++) {
            shiftLeft = this.collapse(shiftLeft, i);
        }
        return shift - shiftLeft;
    };
    SizeDistributor.prototype.collapseBackward = function (_a) {
        var shift = _a.shift, from = _a.from, to = _a.to;
        if (shift <= 0) {
            return 0;
        }
        var shiftLeft = shift;
        for (var i = to - 1; i >= from; i--) {
            shiftLeft = this.collapse(shiftLeft, i);
        }
        return shift - shiftLeft;
    };
    SizeDistributor.prototype.collapse = function (shift, index) {
        if (this.collapsed[index]) {
            return shift;
        }
        var size = this.sizes[index];
        var collapsedSize = this.sizeWhenCollapsed(index);
        var newSize = Math.round(Math.max(size - shift, collapsedSize));
        this.sizes[index] = newSize;
        this.collapsed[index] = newSize <= collapsedSize;
        return shift - (size - newSize);
    };
    SizeDistributor.prototype.expand = function (shift, index) {
        if (shift <= 0) {
            return 0;
        }
        var oldSize = this.sizes[index];
        var newSize = Math.round(oldSize + shift);
        this.sizes[index] = newSize;
        this.collapsed[index] = newSize <= this.sizeWhenCollapsed(index);
        return newSize - oldSize;
    };
    SizeDistributor.prototype.leftoverSize = function () {
        var sizeSum = this.sizes.reduce(function (sum, size) { return sum + size; }, 0);
        return Math.max(this.totalSize - sizeSum, 0);
    };
    return SizeDistributor;
}());
