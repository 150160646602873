"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardTemplate = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var react_1 = require("react");
var wellknown = require("wellknown");
var react_leaflet_1 = require("react-leaflet");
var Leaflet = require("leaflet");
var blankNodes_1 = require("../../data/sparql/blankNodes");
var utils_1 = require("./utils");
var schema_1 = require("../../data/schema");
var authoredEntity_1 = require("../../editor/authoredEntity");
var authoringState_1 = require("../../editor/authoringState");
var spinner_1 = require("../../viewUtils/spinner");
var FOAF_NAME = 'http://xmlns.com/foaf/0.1/name';
var CLASS_NAME = 'graph-explorer-standard-template';
var StandardTemplate = /** @class */ (function (_super) {
    tslib_1.__extends(StandardTemplate, _super);
    function StandardTemplate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StandardTemplate.prototype.render = function () {
        var _this = this;
        return (React.createElement(authoredEntity_1.AuthoredEntity, { templateProps: this.props }, function (context) { return _this.renderTemplate(context); }));
    };
    StandardTemplate.prototype.renderTemplate = function (context) {
        var _a = this.props, color = _a.color, types = _a.types, isExpanded = _a.isExpanded, iri = _a.iri, propsAsList = _a.propsAsList;
        var label = this.getLabel();
        var editor = context.editor;
        var isNewElement = authoringState_1.AuthoringState.isNewElement(editor.authoringState, iri);
        var leftStripeColor = isNewElement ? 'white' : color;
        var pinnedProperties = this.findPinnedProperties(context);
        return (React.createElement("div", { className: CLASS_NAME },
            React.createElement("div", { className: "".concat(CLASS_NAME, "__main"), style: { backgroundColor: leftStripeColor, borderColor: color } },
                React.createElement("div", { className: "".concat(CLASS_NAME, "__body"), style: { borderLeftColor: color } },
                    React.createElement("div", { className: "".concat(CLASS_NAME, "__body-horizontal") },
                        this.renderThumbnail(),
                        React.createElement("div", { className: "".concat(CLASS_NAME, "__body-content") },
                            React.createElement("div", { title: types, className: "".concat(CLASS_NAME, "__type") },
                                React.createElement("div", { className: "".concat(CLASS_NAME, "__type-value") }, this.getTypesLabel())),
                            React.createElement("div", { className: "".concat(CLASS_NAME, "__label"), title: label }, label))),
                    pinnedProperties ? (React.createElement("div", { className: "".concat(CLASS_NAME, "__pinned-props"), style: { borderColor: color } }, this.renderProperties(pinnedProperties))) : null)),
            isExpanded ? (React.createElement("div", { className: "".concat(CLASS_NAME, "__dropdown"), style: { borderColor: color } },
                this.renderPhoto(),
                React.createElement("div", { className: "".concat(CLASS_NAME, "__dropdown-content") },
                    this.renderIri(context),
                    this.renderProperties(propsAsList),
                    editor.inAuthoringMode ? (React.createElement("hr", { className: "".concat(CLASS_NAME, "__hr") })) : null,
                    editor.inAuthoringMode ? this.renderActions(context) : null))) : null));
    };
    StandardTemplate.prototype.findPinnedProperties = function (context) {
        var _a = this.props, isExpanded = _a.isExpanded, propsAsList = _a.propsAsList, elementId = _a.elementId;
        if (isExpanded) {
            return undefined;
        }
        var templateState = context.view.model.getElement(elementId).elementState;
        if (!templateState) {
            return undefined;
        }
        var pinned = templateState[schema_1.TemplateProperties.PinnedProperties];
        if (!pinned) {
            return undefined;
        }
        var filtered = propsAsList.filter(function (prop) { return Boolean(pinned[prop.id]); });
        return filtered.length === 0 ? undefined : filtered;
    };
    StandardTemplate.prototype.renderProperties = function (propsAsList) {
        if (!propsAsList.length) {
            return React.createElement("div", null, "no properties");
        }
        return (React.createElement("div", { className: "".concat(CLASS_NAME, "__properties") }, propsAsList.map(function (_a) {
            var name = _a.name, id = _a.id, property = _a.property;
            var propertyValues = (0, utils_1.getPropertyValues)(property);
            if (id === 'http://www.opengis.net/ont/geosparql#asWKT') {
                var geojson = wellknown.parse(propertyValues[0]);
                if (geojson) {
                    var geojsonFeature = Leaflet.geoJson(geojson);
                    return (React.createElement(react_leaflet_1.Map, { key: id, bounds: geojsonFeature.getBounds(), boundsOptions: { maxZoom: 11 }, zoomControl: false },
                        React.createElement(react_leaflet_1.TileLayer, { attribution: "&copy <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors", url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" }),
                        React.createElement(react_leaflet_1.GeoJSON, { data: geojson }),
                        React.createElement(react_leaflet_1.ZoomControl, { position: "topright" })));
                }
            }
            return (React.createElement("div", { key: id, className: "".concat(CLASS_NAME, "__properties-row") },
                React.createElement("div", { className: "".concat(CLASS_NAME, "__properties-key"), title: "".concat(name, " (").concat(id, ")") }, name),
                React.createElement("div", { className: "".concat(CLASS_NAME, "__properties-values") }, propertyValues.map(function (text, index) { return (React.createElement("div", { className: "".concat(CLASS_NAME, "__properties-value"), key: index, title: text }, text)); }))));
        })));
    };
    StandardTemplate.prototype.renderPhoto = function () {
        var _a = this.props, color = _a.color, imgUrl = _a.imgUrl;
        if (!imgUrl) {
            return null;
        }
        return (React.createElement("div", { className: "".concat(CLASS_NAME, "__photo"), style: { borderColor: color } },
            React.createElement("img", { src: imgUrl, className: "".concat(CLASS_NAME, "__photo-image") })));
    };
    StandardTemplate.prototype.renderIri = function (context) {
        var iri = this.props.iri;
        var finalIri = context.editedIri === undefined ? iri : context.editedIri;
        return (React.createElement("div", null,
            React.createElement("div", { className: "".concat(CLASS_NAME, "__iri") },
                React.createElement("div", { className: "".concat(CLASS_NAME, "__iri-key") },
                    "IRI",
                    context.editedIri ? '\u00A0(edited)' : '',
                    ":"),
                React.createElement("div", { className: "".concat(CLASS_NAME, "__iri-value") }, (0, blankNodes_1.isEncodedBlank)(finalIri) ? (React.createElement("span", null, "(blank node)")) : (React.createElement("a", { href: finalIri, title: finalIri, "data-iri-click-intent": "openEntityIri" }, finalIri)))),
            React.createElement("hr", { className: "".concat(CLASS_NAME, "__hr") })));
    };
    StandardTemplate.prototype.renderThumbnail = function () {
        var _a = this.props, color = _a.color, imgUrl = _a.imgUrl, iconUrl = _a.iconUrl;
        if (imgUrl) {
            return (React.createElement("div", { className: "".concat(CLASS_NAME, "__thumbnail"), "aria-hidden": "true" },
                React.createElement("img", { src: imgUrl, className: "".concat(CLASS_NAME, "__thumbnail-image") })));
        }
        else if (iconUrl) {
            return (React.createElement("div", { className: "".concat(CLASS_NAME, "__thumbnail"), "aria-hidden": "true" },
                React.createElement("img", { src: iconUrl, className: "".concat(CLASS_NAME, "__thumbnail-icon") })));
        }
        var typeLabel = this.getTypesLabel();
        return (React.createElement("div", { className: "".concat(CLASS_NAME, "__thumbnail"), "aria-hidden": "true", style: { color: color } }, typeLabel.length > 0 ? typeLabel.charAt(0).toUpperCase() : '✳'));
    };
    StandardTemplate.prototype.getTypesLabel = function () {
        return this.props.types;
    };
    StandardTemplate.prototype.getLabel = function () {
        var _a = this.props, label = _a.label, props = _a.props;
        return (0, utils_1.getProperty)(props, FOAF_NAME) || label;
    };
    StandardTemplate.prototype.renderActions = function (context) {
        var canEdit = context.canEdit, canDelete = context.canDelete, onEdit = context.onEdit, onDelete = context.onDelete;
        var SPINNER_WIDTH = 15;
        var SPINNER_HEIGHT = 12;
        return (React.createElement("div", { className: "".concat(CLASS_NAME, "__actions") },
            React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: canDelete
                    ? 'Delete entity'
                    : 'Deletion is unavailable for the selected element', disabled: !canDelete, onClick: onDelete },
                React.createElement("span", { className: "fa fa-trash" }),
                "\u00A0",
                canEdit === undefined ? (React.createElement(spinner_1.HtmlSpinner, { width: SPINNER_WIDTH, height: SPINNER_HEIGHT })) : ('Delete')),
            React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: canEdit
                    ? 'Edit entity'
                    : 'Editing is unavailable for the selected element', disabled: !canEdit, onClick: onEdit },
                React.createElement("span", { className: "fa fa-edit" }),
                "\u00A0",
                canEdit === undefined ? (React.createElement(spinner_1.HtmlSpinner, { width: SPINNER_WIDTH, height: SPINNER_HEIGHT })) : ('Edit'))));
    };
    return StandardTemplate;
}(react_1.Component));
exports.StandardTemplate = StandardTemplate;
