"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.highlightSubstring = exports.startDragElements = exports.ListElementView = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var d3_color_1 = require("d3-color");
var CLASS_NAME = 'graph-explorer-list-element-view';
var ListElementView = /** @class */ (function (_super) {
    tslib_1.__extends(ListElementView, _super);
    function ListElementView() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = function (event) {
            var _a = _this.props, disabled = _a.disabled, model = _a.model, onClick = _a.onClick;
            if (!disabled && onClick) {
                event.persist();
                onClick(event, model);
            }
        };
        return _this;
    }
    ListElementView.prototype.render = function () {
        var _a = this.props, className = _a.className, view = _a.view, model = _a.model, highlightText = _a.highlightText, disabled = _a.disabled, selected = _a.selected, onDragStart = _a.onDragStart;
        var _b = view.getTypeStyle(model.types).color, h = _b.h, c = _b.c, l = _b.l;
        var frontColor = selected && !disabled ? (0, d3_color_1.hcl)(h, c, l * 1.2) : (0, d3_color_1.hcl)('white');
        var classNames = "".concat(CLASS_NAME);
        classNames += disabled ? " ".concat(CLASS_NAME, "--disabled") : '';
        classNames += className ? " ".concat(className) : '';
        var localizedText = view.formatLabel(model.label.values, model.id);
        var classesString = model.types.length > 0
            ? "\nClasses: ".concat(view.getElementTypeString(model))
            : '';
        return (React.createElement("li", { className: classNames, draggable: !disabled && Boolean(onDragStart), title: "".concat(localizedText, " ").concat(view.formatIri(model.id)).concat(classesString), style: { background: (0, d3_color_1.hcl)(h, c, l).toString() }, onClick: this.onClick, onDragStart: onDragStart },
            React.createElement("div", { className: "".concat(CLASS_NAME, "__label"), style: { background: frontColor.toString() } }, highlightSubstring(localizedText, highlightText))));
    };
    return ListElementView;
}(React.Component));
exports.ListElementView = ListElementView;
function startDragElements(e, iris) {
    try {
        e.dataTransfer.setData('application/x-graph-explorer-elements', JSON.stringify(iris));
    }
    catch (ex) {
        // IE fix
        e.dataTransfer.setData('text', JSON.stringify(iris));
    }
    return false;
}
exports.startDragElements = startDragElements;
var DEFAULT_HIGHLIGHT_PROPS = {
    className: "graph-explorer-text-highlight",
};
function highlightSubstring(text, substring, highlightProps) {
    if (highlightProps === void 0) { highlightProps = DEFAULT_HIGHLIGHT_PROPS; }
    if (!substring) {
        return React.createElement("span", null, text);
    }
    var start = text.toLowerCase().indexOf(substring.toLowerCase());
    if (start < 0) {
        return React.createElement("span", null, text);
    }
    var end = start + substring.length;
    var before = text.substring(0, start);
    var highlighted = text.substring(start, end);
    var after = text.substring(end);
    return (React.createElement("span", null,
        before,
        React.createElement("span", tslib_1.__assign({}, highlightProps), highlighted),
        after));
}
exports.highlightSubstring = highlightSubstring;
