"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navigator = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var d3_color_1 = require("d3-color");
var async_1 = require("../viewUtils/async");
var events_1 = require("../viewUtils/events");
var paper_1 = require("../diagram/paper");
var CLASS_NAME = 'graph-explorer-navigator';
var MIN_SCALE = 0.25;
var Navigator = /** @class */ (function (_super) {
    tslib_1.__extends(Navigator, _super);
    function Navigator(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.delayedRedraw = new async_1.Debouncer();
        _this.listener = new events_1.EventObserver();
        _this.scheduleRedraw = function () {
            if (_this.state.expanded) {
                _this.delayedRedraw.call(_this.draw);
            }
        };
        _this.draw = function () {
            var _a = _this.props, pt = _a.paperTransform, width = _a.width, height = _a.height;
            _this.calculateTransform();
            var ctx = _this.canvas.getContext('2d');
            ctx.fillStyle = '#EEEEEE';
            ctx.clearRect(0, 0, width, height);
            ctx.fillRect(0, 0, width, height);
            var paneStart = (0, paper_1.paneTopLeft)(pt);
            var paneSize = (0, paper_1.totalPaneSize)(pt);
            var paneEnd = {
                x: paneStart.x + paneSize.x,
                y: paneStart.y + paneSize.y,
            };
            var start = canvasFromPaneCoords(paneStart, pt, _this.transform);
            var end = canvasFromPaneCoords(paneEnd, pt, _this.transform);
            ctx.fillStyle = 'white';
            ctx.fillRect(start.x, start.y, end.x - start.x, end.y - start.y);
            ctx.save();
            _this.drawElements(ctx);
            _this.drawViewport(ctx);
            ctx.restore();
        };
        _this.onDragViewport = function (e) {
            e.preventDefault();
            if (_this.isDraggingViewport) {
                var canvas = _this.canvasFromPageCoords(e.pageX, e.pageY);
                var paper = paperFromCanvasCoords(canvas, _this.props.paperTransform, _this.transform);
                _this.props.paperArea.centerTo(paper);
            }
        };
        _this.onMouseUp = function () {
            _this.stopDraggingViewport();
        };
        _this.onWheel = function (e) {
            e.preventDefault();
            var delta = Math.max(-1, Math.min(1, e.deltaY || e.deltaX));
            _this.props.paperArea.zoomBy(-delta * 0.1);
        };
        _this.onToggleClick = function () {
            _this.setState(function (state) { return ({ expanded: !state.expanded }); }, _this.scheduleRedraw);
        };
        _this.state = { expanded: _this.props.expanded };
        return _this;
    }
    Navigator.prototype.componentDidMount = function () {
        var _a = this.props, view = _a.view, paperArea = _a.paperArea;
        this.listener.listen(view.events, 'changeHighlight', this.scheduleRedraw);
        this.listener.listen(view.model.events, 'changeCells', this.scheduleRedraw);
        this.listener.listen(view.model.events, 'elementEvent', this.scheduleRedraw);
        this.listener.listen(paperArea.events, 'pointerMove', this.scheduleRedraw);
        this.listener.listen(paperArea.events, 'scroll', this.scheduleRedraw);
    };
    Navigator.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return nextState !== this.state;
    };
    Navigator.prototype.componentWillUnmount = function () {
        this.delayedRedraw.dispose();
        this.listener.stopListening();
        this.stopDraggingViewport();
    };
    Navigator.prototype.drawElements = function (ctx) {
        var _this = this;
        var _a = this.props, view = _a.view, pt = _a.paperTransform;
        view.model.elements.forEach(function (element) {
            var position = element.position, size = element.size;
            ctx.fillStyle = _this.chooseElementColor(element);
            var _a = canvasFromPaperCoords(position, pt, _this.transform), x1 = _a.x, y1 = _a.y;
            var _b = canvasFromPaperCoords({
                x: position.x + size.width,
                y: position.y + size.height,
            }, pt, _this.transform), x2 = _b.x, y2 = _b.y;
            ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
        });
    };
    Navigator.prototype.chooseElementColor = function (element) {
        var view = this.props.view;
        var isBlurred = view.highlighter && !view.highlighter(element);
        if (isBlurred) {
            return 'lightgray';
        }
        var _a = view.getTypeStyle(element.data.types).color, h = _a.h, c = _a.c, l = _a.l;
        return (0, d3_color_1.hcl)(h, c, l).toString();
    };
    Navigator.prototype.drawViewport = function (ctx) {
        var _a = this.props, paperArea = _a.paperArea, pt = _a.paperTransform, width = _a.width, height = _a.height;
        ctx.strokeStyle = '#337ab7';
        ctx.lineWidth = 2;
        var _b = paperArea.getAreaMetrics(), clientWidth = _b.clientWidth, clientHeight = _b.clientHeight;
        var viewportStart = paperArea.clientToScrollablePaneCoords(0, 0);
        var viewportEnd = paperArea.clientToScrollablePaneCoords(clientWidth, clientHeight);
        var _c = canvasFromPaneCoords(viewportStart, pt, this.transform), x1 = _c.x, y1 = _c.y;
        var _d = canvasFromPaneCoords(viewportEnd, pt, this.transform), x2 = _d.x, y2 = _d.y;
        // draw visible viewport rectangle
        ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
        // draw "out of area" viewport borders
        ctx.beginPath();
        if (x1 < 0) {
            ctx.moveTo(0, y1);
            ctx.lineTo(0, y2);
        }
        if (y1 < 0) {
            ctx.moveTo(x1, 0);
            ctx.lineTo(x2, 0);
        }
        if (x2 > width) {
            ctx.moveTo(width, y1);
            ctx.lineTo(width, y2);
        }
        if (y2 > height) {
            ctx.moveTo(x1, height);
            ctx.lineTo(x2, height);
        }
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#a0d2ff';
        ctx.setLineDash([5, 5]);
        ctx.stroke();
    };
    Navigator.prototype.calculateTransform = function () {
        var _a = this.props, paperArea = _a.paperArea, pt = _a.paperTransform, width = _a.width, height = _a.height, scalePadding = _a.scalePadding;
        var box = paperArea.getContentFittingBox();
        var displayPadding = {
            x: Math.max(box.width, width / MIN_SCALE) * scalePadding,
            y: Math.max(box.height, height / MIN_SCALE) * scalePadding,
        };
        var displayStart = (0, paper_1.paneFromPaperCoords)({
            x: box.x - displayPadding.x,
            y: box.y - displayPadding.y,
        }, pt);
        var displayEnd = (0, paper_1.paneFromPaperCoords)({
            x: box.x + box.width + displayPadding.x,
            y: box.y + box.height + displayPadding.y,
        }, pt);
        var displaySize = {
            x: displayEnd.x - displayStart.x,
            y: displayEnd.y - displayStart.y,
        };
        var scale = Math.min(width / displaySize.x, height / displaySize.y);
        var canvasOffset = {
            x: (width - displaySize.x * scale) / 2,
            y: (height - displaySize.y * scale) / 2,
        };
        this.transform = { scale: scale, canvasOffset: canvasOffset, paneOffset: displayStart };
    };
    Navigator.prototype.canvasFromPageCoords = function (pageX, pageY) {
        var _a = this.canvas.getBoundingClientRect(), top = _a.top, left = _a.left;
        return {
            x: pageX - left - window.pageXOffset,
            y: pageY - top - window.pageYOffset,
        };
    };
    Navigator.prototype.render = function () {
        var _this = this;
        var _a = this.props, width = _a.width, height = _a.height;
        var expanded = this.state.expanded;
        return (React.createElement("div", { className: "".concat(CLASS_NAME, " ").concat(CLASS_NAME, "--").concat(expanded ? 'expanded' : 'collapsed'), style: expanded ? { width: width, height: height } : undefined },
            React.createElement("canvas", { ref: function (canvas) { return (_this.canvas = canvas); }, width: width, height: height, onMouseDown: function (e) {
                    _this.startDragginViewport();
                    _this.onDragViewport(e);
                }, onWheel: this.onWheel }),
            React.createElement("button", { className: "".concat(CLASS_NAME, "__toggle"), title: expanded ? 'Collapse navigator' : 'Expand navigator', onClick: this.onToggleClick },
                React.createElement("div", { className: "".concat(CLASS_NAME, "__toggle-icon") }))));
    };
    Navigator.prototype.startDragginViewport = function () {
        if (!this.isDraggingViewport) {
            this.isDraggingViewport = true;
            document.addEventListener('mousemove', this.onDragViewport);
            document.addEventListener('mouseup', this.onMouseUp);
        }
    };
    Navigator.prototype.stopDraggingViewport = function () {
        if (this.isDraggingViewport) {
            this.isDraggingViewport = false;
            document.removeEventListener('mousemove', this.onDragViewport);
            document.removeEventListener('mouseup', this.onMouseUp);
        }
    };
    Navigator.defaultProps = {
        width: 300,
        height: 160,
        scalePadding: 0.2,
        expanded: true,
    };
    return Navigator;
}(React.Component));
exports.Navigator = Navigator;
function canvasFromPaneCoords(pane, pt, nt) {
    return {
        x: nt.canvasOffset.x + (pane.x - nt.paneOffset.x) * nt.scale,
        y: nt.canvasOffset.y + (pane.y - nt.paneOffset.y) * nt.scale,
    };
}
function canvasFromPaperCoords(paper, pt, nt) {
    var pane = (0, paper_1.paneFromPaperCoords)(paper, pt);
    return canvasFromPaneCoords(pane, pt, nt);
}
function paperFromCanvasCoords(canvas, pt, nt) {
    var pane = {
        x: nt.paneOffset.x + (canvas.x - nt.canvasOffset.x) / nt.scale,
        y: nt.paneOffset.y + (canvas.y - nt.canvasOffset.y) / nt.scale,
    };
    return (0, paper_1.paperFromPaneCoords)(pane, pt);
}
