"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLinkConnectedToElement = exports.AuthoringState = exports.AuthoringKind = void 0;
var tslib_1 = require("tslib");
var model_1 = require("../data/model");
var collections_1 = require("../viewUtils/collections");
var AuthoringKind;
(function (AuthoringKind) {
    AuthoringKind["ChangeElement"] = "changeElement";
    AuthoringKind["ChangeLink"] = "changeLink";
})(AuthoringKind = exports.AuthoringKind || (exports.AuthoringKind = {}));
exports.AuthoringState = {
    empty: {
        elements: new Map(),
        links: new collections_1.HashMap(model_1.hashLink, model_1.sameLink),
    },
    isEmpty: function (state) {
        return state.elements.size === 0 && state.links.size === 0;
    },
    clone: function (index) {
        return {
            elements: (0, collections_1.cloneMap)(index.elements),
            links: index.links.clone(),
        };
    },
    has: function (state, event) {
        return event.type === AuthoringKind.ChangeElement
            ? state.elements.get(event.after.id) === event
            : state.links.get(event.after) === event;
    },
    discard: function (state, discarded) {
        if (!this.has(state, discarded)) {
            return state;
        }
        var newState = this.clone(state);
        if (discarded.type === AuthoringKind.ChangeElement) {
            newState.elements.delete(discarded.after.id);
            if (!discarded.before) {
                state.links.forEach(function (e) {
                    if (isLinkConnectedToElement(e.after, discarded.after.id)) {
                        newState.links.delete(e.after);
                    }
                });
            }
        }
        else {
            newState.links.delete(discarded.after);
        }
        return newState;
    },
    addElement: function (state, item) {
        var event = {
            type: AuthoringKind.ChangeElement,
            after: item,
            deleted: false,
        };
        var newState = this.clone(state);
        newState.elements.set(event.after.id, event);
        return newState;
    },
    addLink: function (state, item) {
        var event = {
            type: AuthoringKind.ChangeLink,
            after: item,
            deleted: false,
        };
        var newState = this.clone(state);
        newState.links.set(event.after, event);
        return newState;
    },
    changeElement: function (state, before, after) {
        var newState = this.clone(state);
        // delete previous state for an entity
        newState.elements.delete(before.id);
        var previous = state.elements.get(before.id);
        if (previous && !previous.before) {
            // adding or changing new entity
            newState.elements.set(after.id, {
                type: AuthoringKind.ChangeElement,
                after: after,
                deleted: false,
            });
            if (before.id !== after.id) {
                state.links.forEach(function (e) {
                    if (!e.before && isLinkConnectedToElement(e.after, before.id)) {
                        var updatedLink = updateLinkToReferByNewIri(e.after, before.id, after.id);
                        newState.links.delete(e.after);
                        newState.links.set(updatedLink, {
                            type: AuthoringKind.ChangeLink,
                            after: updatedLink,
                            deleted: false,
                        });
                    }
                });
            }
        }
        else {
            // changing existing entity
            var iriChanged = after.id !== before.id;
            var previousBefore = previous ? previous.before : undefined;
            newState.elements.set(before.id, {
                type: AuthoringKind.ChangeElement,
                // always initialize 'before', otherwise entity will be considered new
                before: previousBefore || before,
                after: iriChanged ? tslib_1.__assign(tslib_1.__assign({}, after), { id: before.id }) : after,
                newIri: iriChanged ? after.id : undefined,
                deleted: false,
            });
        }
        return newState;
    },
    changeLink: function (state, before, after) {
        if (!(0, model_1.sameLink)(before, after)) {
            throw new Error('Cannot move link to another element or change its type');
        }
        var newState = this.clone(state);
        var previous = state.links.get(before);
        newState.links.set(before, {
            type: AuthoringKind.ChangeLink,
            before: previous ? previous.before : undefined,
            after: after,
            deleted: false,
        });
        return newState;
    },
    deleteElement: function (state, model) {
        var newState = this.clone(state);
        newState.elements.delete(model.id);
        state.links.forEach(function (e) {
            if (isLinkConnectedToElement(e.after, model.id)) {
                newState.links.delete(e.after);
            }
        });
        if (!this.isNewElement(state, model.id)) {
            newState.elements.set(model.id, {
                type: AuthoringKind.ChangeElement,
                before: model,
                after: model,
                deleted: true,
            });
        }
        return newState;
    },
    deleteLink: function (state, target) {
        var newState = this.clone(state);
        newState.links.delete(target);
        if (!this.isNewLink(state, target)) {
            newState.links.set(target, {
                type: AuthoringKind.ChangeLink,
                before: target,
                after: target,
                deleted: true,
            });
        }
        return newState;
    },
    deleteNewLinksConnectedToElements: function (state, elementIris) {
        var newState = this.clone(state);
        state.links.forEach(function (e) {
            if (!e.before) {
                var target = e.after;
                if (elementIris.has(target.sourceId) ||
                    elementIris.has(target.targetId)) {
                    newState.links.delete(target);
                }
            }
        });
        return newState;
    },
    isNewElement: function (state, target) {
        var event = state.elements.get(target);
        return event && event.type === AuthoringKind.ChangeElement && !event.before;
    },
    isDeletedElement: function (state, target) {
        var event = state.elements.get(target);
        return event && event.deleted;
    },
    isElementWithModifiedIri: function (state, target) {
        var event = state.elements.get(target);
        return (event &&
            event.type === AuthoringKind.ChangeElement &&
            event.before &&
            Boolean(event.newIri));
    },
    isNewLink: function (state, linkModel) {
        var event = state.links.get(linkModel);
        return event && !event.before;
    },
    isDeletedLink: function (state, linkModel) {
        var event = state.links.get(linkModel);
        return ((event && event.deleted) ||
            this.isDeletedElement(state, linkModel.sourceId) ||
            this.isDeletedElement(state, linkModel.targetId));
    },
    isUncertainLink: function (state, linkModel) {
        return (!this.isDeletedLink(state, linkModel) &&
            (this.isElementWithModifiedIri(state, linkModel.sourceId) ||
                this.isElementWithModifiedIri(state, linkModel.targetId)));
    },
};
function isLinkConnectedToElement(link, elementIri) {
    return link.sourceId === elementIri || link.targetId === elementIri;
}
exports.isLinkConnectedToElement = isLinkConnectedToElement;
function updateLinkToReferByNewIri(link, oldIri, newIri) {
    return tslib_1.__assign(tslib_1.__assign({}, link), { sourceId: link.sourceId === oldIri ? newIri : link.sourceId, targetId: link.targetId === oldIri ? newIri : link.targetId });
}
