"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restoreLinksBetweenElements = exports.requestElementData = exports.AsyncModel = void 0;
var tslib_1 = require("tslib");
var schema_1 = require("../data/schema");
var elements_1 = require("../diagram/elements");
var history_1 = require("../diagram/history");
var model_1 = require("../diagram/model");
var dataFetcher_1 = require("./dataFetcher");
var serializedDiagram_1 = require("./serializedDiagram");
var AsyncModel = /** @class */ (function (_super) {
    tslib_1.__extends(AsyncModel, _super);
    function AsyncModel(history, groupByProperties) {
        var _this = _super.call(this, history) || this;
        _this.groupByProperties = groupByProperties;
        _this.linkSettings = {};
        _this.onLinkTypeVisibilityChanged = function (e) {
            if (e.source.visible) {
                if (!e.preventLoading) {
                    _this.requestLinksOfType([e.source.id]);
                }
            }
            else {
                for (var _i = 0, _a = _this.linksOfType(e.source.id); _i < _a.length; _i++) {
                    var link = _a[_i];
                    _this.graph.removeLink(link.id);
                }
            }
        };
        return _this;
    }
    Object.defineProperty(AsyncModel.prototype, "asyncSource", {
        get: function () {
            return this.source;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AsyncModel.prototype, "dataProvider", {
        get: function () {
            return this._dataProvider;
        },
        enumerable: false,
        configurable: true
    });
    AsyncModel.prototype.subscribeGraph = function () {
        var _this = this;
        _super.prototype.subscribeGraph.call(this);
        this.graphListener.listen(this.graph.events, 'linkTypeEvent', function (e) {
            if (e.key === 'changeVisibility') {
                _this.onLinkTypeVisibilityChanged(e.data[e.key], e.key);
            }
        });
    };
    AsyncModel.prototype.setDataProvider = function (dataProvider) {
        this._dataProvider = dataProvider;
        this.fetcher = new dataFetcher_1.DataFetcher(this.graph, dataProvider);
    };
    AsyncModel.prototype.createNewDiagram = function (dataProvider) {
        var _this = this;
        this.resetGraph();
        this.setDataProvider(dataProvider);
        this.asyncSource.trigger('loadingStart', { source: this });
        return this.dataProvider
            .linkTypes()
            .then(function (linkTypes) {
            var allLinkTypes = _this.initLinkTypes(linkTypes);
            return _this.loadAndRenderLayout({
                allLinkTypes: allLinkTypes,
                markLinksAsLayoutOnly: false,
            });
        })
            .catch(function (error) {
            // tslint:disable-next-line:no-console
            console.error(error);
            _this.asyncSource.trigger('loadingError', { source: _this, error: error });
            return Promise.reject(error);
        });
    };
    AsyncModel.prototype.importLayout = function (params) {
        var _this = this;
        this.resetGraph();
        this.setDataProvider(params.dataProvider);
        this.asyncSource.trigger('loadingStart', { source: this });
        return this.dataProvider
            .linkTypes()
            .then(function (linkTypes) {
            var allLinkTypes = _this.initLinkTypes(linkTypes);
            var diagram = params.diagram ? params.diagram : (0, serializedDiagram_1.emptyDiagram)();
            _this.setLinkSettings(diagram.linkTypeOptions);
            var loadingModels = _this.loadAndRenderLayout({
                layoutData: diagram.layoutData,
                preloadedElements: params.preloadedElements || {},
                markLinksAsLayoutOnly: params.validateLinks || false,
                allLinkTypes: allLinkTypes,
                hideUnusedLinkTypes: params.hideUnusedLinkTypes,
            });
            var requestingLinks = params.validateLinks
                ? _this.requestLinksOfType()
                : Promise.resolve();
            return Promise.all([loadingModels, requestingLinks]);
        })
            .then(function () {
            _this.asyncSource.trigger('loadingSuccess', { source: _this });
        })
            .catch(function (error) {
            // tslint:disable-next-line:no-console
            console.error(error);
            _this.asyncSource.trigger('loadingError', { source: _this, error: error });
            return Promise.reject(error);
        });
    };
    AsyncModel.prototype.exportLayout = function () {
        var layoutData = (0, serializedDiagram_1.makeLayoutData)(this.graph.getElements(), this.graph.getLinks());
        var linkTypeOptions = this.graph
            .getLinkTypes()
            // do not serialize default link type options
            .filter(function (linkType) {
            return (!linkType.visible || !linkType.showLabel) &&
                linkType.id !== schema_1.PLACEHOLDER_LINK_TYPE;
        })
            .map(function (_a) {
            var id = _a.id, visible = _a.visible, showLabel = _a.showLabel;
            return ({
                '@type': 'LinkTypeOptions',
                property: id,
                visible: visible,
                showLabel: showLabel,
            });
        });
        return (0, serializedDiagram_1.makeSerializedDiagram)({ layoutData: layoutData, linkTypeOptions: linkTypeOptions });
    };
    AsyncModel.prototype.initLinkTypes = function (linkTypes) {
        var types = [];
        for (var _i = 0, linkTypes_1 = linkTypes; _i < linkTypes_1.length; _i++) {
            var _a = linkTypes_1[_i], id = _a.id, label = _a.label;
            var linkType = new elements_1.FatLinkType({ id: id, label: label.values });
            this.graph.addLinkType(linkType);
            types.push(linkType);
        }
        return types;
    };
    AsyncModel.prototype.setLinkSettings = function (settings) {
        if (!settings) {
            return;
        }
        for (var _i = 0, settings_1 = settings; _i < settings_1.length; _i++) {
            var setting = settings_1[_i];
            var _a = setting.visible, visible = _a === void 0 ? true : _a, _b = setting.showLabel, showLabel = _b === void 0 ? true : _b;
            var linkTypeId = setting.property;
            this.linkSettings[linkTypeId] = {
                '@type': 'LinkTypeOptions',
                property: linkTypeId,
                visible: visible,
                showLabel: showLabel,
            };
            //const linkType = this.getLinkType(linkTypeId);
            var linkType = this.createLinkType(linkTypeId);
            if (linkType) {
                linkType.setVisibility({ visible: visible, showLabel: showLabel });
            }
        }
    };
    AsyncModel.prototype.loadAndRenderLayout = function (params) {
        var _a = params.layoutData, layoutData = _a === void 0 ? (0, serializedDiagram_1.emptyLayoutData)() : _a, _b = params.preloadedElements, preloadedElements = _b === void 0 ? {} : _b, markLinksAsLayoutOnly = params.markLinksAsLayoutOnly, hideUnusedLinkTypes = params.hideUnusedLinkTypes;
        var elementIrisToRequestData = [];
        var usedLinkTypes = {};
        for (var _i = 0, _c = layoutData.elements; _i < _c.length; _i++) {
            var layoutElement = _c[_i];
            var id = layoutElement["@id"], iri = layoutElement.iri, position = layoutElement.position, size = layoutElement.size, isExpanded = layoutElement.isExpanded, group = layoutElement.group, elementState = layoutElement.elementState;
            var template = preloadedElements[iri];
            var data = template || (0, model_1.placeholderDataFromIri)(iri);
            var element = new elements_1.Element({
                id: id,
                data: data,
                position: position,
                size: size,
                expanded: isExpanded,
                group: group,
                elementState: elementState,
            });
            this.graph.addElement(element);
            if (!template) {
                elementIrisToRequestData.push(element.iri);
            }
        }
        for (var _d = 0, _e = layoutData.links; _d < _e.length; _d++) {
            var layoutLink = _e[_d];
            var id = layoutLink["@id"], property = layoutLink.property, source = layoutLink.source, target = layoutLink.target, vertices = layoutLink.vertices, linkState = layoutLink.linkState;
            var linkType = this.createLinkType(property);
            usedLinkTypes[linkType.id] = linkType;
            var link = this.addLink(new elements_1.Link({
                id: id,
                typeId: linkType.id,
                sourceId: source['@id'],
                targetId: target['@id'],
                vertices: vertices,
                linkState: linkState,
            }));
            if (link) {
                link.setLayoutOnly(markLinksAsLayoutOnly);
            }
        }
        this.subscribeGraph();
        var requestingModels = this.requestElementData(elementIrisToRequestData);
        if (hideUnusedLinkTypes && params.allLinkTypes) {
            this.hideUnusedLinkTypes(params.allLinkTypes, usedLinkTypes);
        }
        return requestingModels;
    };
    AsyncModel.prototype.hideUnusedLinkTypes = function (allTypes, usedTypes) {
        for (var _i = 0, allTypes_1 = allTypes; _i < allTypes_1.length; _i++) {
            var linkType = allTypes_1[_i];
            if (!usedTypes[linkType.id]) {
                linkType.setVisibility({
                    visible: false,
                    showLabel: linkType.showLabel,
                });
            }
        }
    };
    AsyncModel.prototype.requestElementData = function (elementIris) {
        return this.fetcher.fetchElementData(elementIris);
    };
    AsyncModel.prototype.requestLinksOfType = function (linkTypeIds) {
        var _this = this;
        var linkTypes = linkTypeIds ||
            this.graph
                .getLinkTypes()
                .filter(function (type) { return type.visible; })
                .map(function (type) { return type.id; });
        return this.dataProvider
            .linksInfo({
            elementIds: this.graph.getElements().map(function (element) { return element.iri; }),
            linkTypeIds: linkTypes,
        })
            .then(function (links) { return _this.onLinkInfoLoaded(links); });
    };
    AsyncModel.prototype.createClass = function (classId) {
        if (this.graph.getClass(classId)) {
            return _super.prototype.getClass.call(this, classId);
        }
        var classModel = _super.prototype.createClass.call(this, classId);
        this.fetcher.fetchClass(classModel);
        return classModel;
    };
    AsyncModel.prototype.createLinkType = function (linkTypeId) {
        if (this.graph.getLinkType(linkTypeId)) {
            return _super.prototype.createLinkType.call(this, linkTypeId);
        }
        var linkType = _super.prototype.createLinkType.call(this, linkTypeId);
        var setting = this.linkSettings[linkType.id];
        if (setting) {
            var visible = setting.visible, showLabel = setting.showLabel;
            linkType.setVisibility({ visible: visible, showLabel: showLabel, preventLoading: true });
        }
        this.fetcher.fetchLinkType(linkType);
        return linkType;
    };
    AsyncModel.prototype.createProperty = function (propertyIri) {
        if (this.graph.getProperty(propertyIri)) {
            return _super.prototype.createProperty.call(this, propertyIri);
        }
        var property = _super.prototype.createProperty.call(this, propertyIri);
        this.fetcher.fetchPropertyType(property);
        return property;
    };
    AsyncModel.prototype.onLinkInfoLoaded = function (links) {
        var allowToCreate;
        var cancel = function () {
            allowToCreate = false;
        };
        for (var _i = 0, links_1 = links; _i < links_1.length; _i++) {
            var linkModel = links_1[_i];
            this.createLinkType(linkModel.linkTypeId);
            allowToCreate = true;
            this.asyncSource.trigger('createLoadedLink', {
                source: this,
                model: linkModel,
                cancel: cancel,
            });
            if (allowToCreate) {
                this.createLinks(linkModel);
            }
        }
    };
    AsyncModel.prototype.createLinks = function (data) {
        var sources = this.graph
            .getElements()
            .filter(function (el) { return el.iri === data.sourceId; });
        var targets = this.graph
            .getElements()
            .filter(function (el) { return el.iri === data.targetId; });
        var typeId = data.linkTypeId;
        for (var _i = 0, sources_1 = sources; _i < sources_1.length; _i++) {
            var source = sources_1[_i];
            for (var _a = 0, targets_1 = targets; _a < targets_1.length; _a++) {
                var target = targets_1[_a];
                this.addLink(new elements_1.Link({ typeId: typeId, sourceId: source.id, targetId: target.id, data: data }));
            }
        }
    };
    AsyncModel.prototype.loadEmbeddedElements = function (elementIri) {
        var _this = this;
        var elements = this.groupByProperties.map(function (groupBy) {
            return _this.dataProvider.linkElements({
                elementId: elementIri,
                linkId: groupBy.linkType,
                offset: 0,
                direction: groupBy.linkDirection,
            });
        });
        return Promise.all(elements).then(function (res) {
            return res.reduce(function (memo, current) { return Object.assign(memo, current); }, {});
        });
    };
    return AsyncModel;
}(model_1.DiagramModel));
exports.AsyncModel = AsyncModel;
function requestElementData(model, elementIris) {
    return history_1.Command.effect('Fetch element data', function () {
        model.requestElementData(elementIris);
    });
}
exports.requestElementData = requestElementData;
function restoreLinksBetweenElements(model) {
    return history_1.Command.effect('Restore links between elements', function () {
        model.requestLinksOfType();
    });
}
exports.restoreLinksBetweenElements = restoreLinksBetweenElements;
