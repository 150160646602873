"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateElementType = exports.ElementTypeSelector = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var schema_1 = require("../data/schema");
var instancesSearch_1 = require("../widgets/instancesSearch");
var listElementView_1 = require("../widgets/listElementView");
var async_1 = require("../viewUtils/async");
var spinner_1 = require("../viewUtils/spinner");
var CLASS_NAME = 'graph-explorer-edit-form';
var ElementTypeSelector = /** @class */ (function (_super) {
    tslib_1.__extends(ElementTypeSelector, _super);
    function ElementTypeSelector(props) {
        var _this = _super.call(this, props) || this;
        _this.cancellation = new async_1.Cancellation();
        _this.filterCancellation = new async_1.Cancellation();
        _this.loadingItemCancellation = new async_1.Cancellation();
        _this.onElementTypeChange = function (e) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var signal, _a, onChange, metadataApi, classId, elementModel;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setState({ isLoading: true });
                        this.loadingItemCancellation.abort();
                        this.loadingItemCancellation = new async_1.Cancellation();
                        signal = this.loadingItemCancellation.signal;
                        _a = this.props, onChange = _a.onChange, metadataApi = _a.metadataApi;
                        classId = e.target.value;
                        return [4 /*yield*/, async_1.CancellationToken.mapCancelledToNull(signal, metadataApi.generateNewElement([classId], signal))];
                    case 1:
                        elementModel = _b.sent();
                        if (elementModel === null) {
                            return [2 /*return*/];
                        }
                        this.setState({ isLoading: false });
                        onChange({
                            value: elementModel,
                            isNew: true,
                            loading: false,
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        _this.renderPossibleElementType = function (elementType) {
            var view = _this.props.view;
            var type = view.model.createClass(elementType);
            var label = view.formatLabel(type.label, type.id);
            return (React.createElement("option", { key: elementType, value: elementType }, label));
        };
        _this.state = { searchString: '', existingElements: [] };
        return _this;
    }
    ElementTypeSelector.prototype.componentDidMount = function () {
        this.fetchPossibleElementTypes();
    };
    ElementTypeSelector.prototype.componentDidUpdate = function (prevProps, prevState) {
        var searchString = this.state.searchString;
        if (searchString !== prevState.searchString) {
            this.searchExistingElements();
        }
    };
    ElementTypeSelector.prototype.componentWillUnmount = function () {
        this.cancellation.abort();
        this.filterCancellation.abort();
        this.loadingItemCancellation.abort();
    };
    ElementTypeSelector.prototype.fetchPossibleElementTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, view, metadataApi, source, elementTypes;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, view = _a.view, metadataApi = _a.metadataApi, source = _a.source;
                        if (!metadataApi) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, async_1.CancellationToken.mapCancelledToNull(this.cancellation.signal, metadataApi.typesOfElementsDraggedFrom(source, this.cancellation.signal))];
                    case 1:
                        elementTypes = _b.sent();
                        if (elementTypes === null) {
                            return [2 /*return*/];
                        }
                        elementTypes.sort(makeElementTypeComparatorByLabel(view));
                        this.setState({ elementTypes: elementTypes });
                        return [2 /*return*/];
                }
            });
        });
    };
    ElementTypeSelector.prototype.searchExistingElements = function () {
        var _this = this;
        var _a = this.props, editor = _a.editor, view = _a.view;
        var searchString = this.state.searchString;
        this.setState({ existingElements: [] });
        if (searchString.length > 0) {
            this.setState({ isLoading: true });
            this.filterCancellation.abort();
            this.filterCancellation = new async_1.Cancellation();
            var signal_1 = this.filterCancellation.signal;
            var request = (0, instancesSearch_1.createRequest)({ text: searchString }, view.getLanguage());
            editor.model.dataProvider.filter(request).then(function (elements) {
                if (signal_1.aborted) {
                    return;
                }
                var existingElements = Object.keys(elements).map(function (key) { return elements[key]; });
                _this.setState({ existingElements: existingElements, isLoading: false });
            });
        }
    };
    ElementTypeSelector.prototype.renderElementTypeSelector = function () {
        var elementValue = this.props.elementValue;
        var _a = this.state, elementTypes = _a.elementTypes, isLoading = _a.isLoading;
        var value = elementValue.value.types.length
            ? elementValue.value.types[0]
            : '';
        if (isLoading) {
            return React.createElement(spinner_1.HtmlSpinner, { width: 20, height: 20 });
        }
        return (React.createElement("div", { className: "".concat(CLASS_NAME, "__control-row") },
            React.createElement("label", null, "Entity Type"),
            elementTypes ? (React.createElement("select", { className: "graph-explorer-form-control", value: value, onChange: this.onElementTypeChange },
                React.createElement("option", { value: schema_1.PLACEHOLDER_ELEMENT_TYPE, disabled: true }, "Select entity type"),
                elementTypes.map(this.renderPossibleElementType))) : (React.createElement("div", null,
                React.createElement(spinner_1.HtmlSpinner, { width: 20, height: 20 }))),
            elementValue.error ? (React.createElement("span", { className: "".concat(CLASS_NAME, "__control-error") }, elementValue.error)) : ('')));
    };
    ElementTypeSelector.prototype.renderExistingElementsList = function () {
        var _this = this;
        var _a = this.props, view = _a.view, editor = _a.editor, elementValue = _a.elementValue;
        var _b = this.state, elementTypes = _b.elementTypes, isLoading = _b.isLoading, existingElements = _b.existingElements;
        if (isLoading) {
            return React.createElement(spinner_1.HtmlSpinner, { width: 20, height: 20 });
        }
        if (existingElements.length > 0) {
            return existingElements.map(function (element) {
                var isAlreadyOnDiagram = !editor.temporaryState.elements.has(element.id) &&
                    Boolean(editor.model.elements.find(function (_a) {
                        var iri = _a.iri, group = _a.group;
                        return iri === element.id && group === undefined;
                    }));
                var hasAppropriateType = Boolean(elementTypes.find(function (type) { return element.types.indexOf(type) >= 0; }));
                return (React.createElement(listElementView_1.ListElementView, { key: element.id, view: view, model: element, disabled: isAlreadyOnDiagram || !hasAppropriateType, selected: element.id === elementValue.value.id, onClick: function (e, model) { return _this.onSelectExistingItem(model); } }));
            });
        }
        return React.createElement("span", null, "No results");
    };
    ElementTypeSelector.prototype.onSelectExistingItem = function (model) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, editor, onChange, signal, result, loadedModel;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, editor = _a.editor, onChange = _a.onChange;
                        this.loadingItemCancellation.abort();
                        this.loadingItemCancellation = new async_1.Cancellation();
                        signal = this.loadingItemCancellation.signal;
                        onChange({ value: model, isNew: false, loading: true });
                        return [4 /*yield*/, editor.model.dataProvider.elementInfo({
                                elementIds: [model.id],
                            })];
                    case 1:
                        result = _b.sent();
                        if (signal.aborted) {
                            return [2 /*return*/];
                        }
                        loadedModel = result[model.id];
                        onChange({ value: loadedModel, isNew: false, loading: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    ElementTypeSelector.prototype.render = function () {
        var _this = this;
        var searchString = this.state.searchString;
        return (React.createElement("div", { className: "".concat(CLASS_NAME, "__form-row ").concat(CLASS_NAME, "__element-selector") },
            React.createElement("div", { className: "".concat(CLASS_NAME, "__search") },
                React.createElement("i", { className: "fa fa-search ".concat(CLASS_NAME, "__search-icon") }),
                React.createElement("input", { value: searchString, onChange: function (e) {
                        return _this.setState({
                            searchString: e.target.value,
                        });
                    }, className: "graph-explorer-form-control ".concat(CLASS_NAME, "__search-input"), placeholder: "Search for...", autoFocus: true })),
            searchString.length > 0 ? (React.createElement("div", { className: "".concat(CLASS_NAME, "__existing-elements-list") }, this.renderExistingElementsList())) : (React.createElement("div", null,
                React.createElement("div", { className: "".concat(CLASS_NAME, "__separator") },
                    React.createElement("i", { className: "".concat(CLASS_NAME, "__separator-text") }, "or create new entity")),
                this.renderElementTypeSelector()))));
    };
    return ElementTypeSelector;
}(React.Component));
exports.ElementTypeSelector = ElementTypeSelector;
function makeElementTypeComparatorByLabel(view) {
    return function (a, b) {
        var typeA = view.model.createClass(a);
        var typeB = view.model.createClass(b);
        var labelA = view.formatLabel(typeA.label, typeA.id);
        var labelB = view.formatLabel(typeB.label, typeB.id);
        return labelA.localeCompare(labelB);
    };
}
function validateElementType(element) {
    var isElementTypeSelected = element.types.indexOf(schema_1.PLACEHOLDER_ELEMENT_TYPE) < 0;
    var error = !isElementTypeSelected ? 'Required.' : undefined;
    return Promise.resolve({ error: error, allowChange: true });
}
exports.validateElementType = validateElementType;
