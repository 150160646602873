"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RDFLoader = exports.DEFAULT_PROXY = void 0;
exports.DEFAULT_PROXY = '/lod-proxy/';
var RDFLoader = /** @class */ (function () {
    function RDFLoader(parameters) {
        this.fetchingFileCatche = {};
        this.parser = parameters.parser;
        this.proxy = parameters.proxy || exports.DEFAULT_PROXY;
    }
    RDFLoader.prototype.parseData = function (data, contentType) {
        var result = this.parser.parse(data, contentType);
        return result;
    };
    RDFLoader.prototype.downloadElement = function (elementId) {
        var _this = this;
        var sharpIndex = elementId.indexOf('#');
        var fileUrl = sharpIndex !== -1 ? elementId.substr(0, sharpIndex) : elementId;
        var typePointer = 0;
        var mimeTypes = Object.keys(this.parser.parserMap);
        var recursivePart = function () {
            var acceptType = mimeTypes[typePointer++];
            if (acceptType &&
                (elementId.startsWith('http') || elementId.startsWith('file'))) {
                return fetchFile({
                    url: elementId,
                    proxy: _this.proxy,
                    headers: {
                        Accept: acceptType,
                    },
                }).then(function (body) {
                    return _this.parseData(body, acceptType).catch(function (error) {
                        // tslint:disable-next-line:no-console
                        console.warn(error);
                        if (typePointer < mimeTypes.length) {
                            return recursivePart();
                        }
                        else {
                            throw new Error("Unable to parse response. Response: ".concat(body));
                        }
                    });
                });
            }
            else {
                throw new Error("Unable to fetch data using this id (".concat(elementId, ")"));
            }
        };
        if (!this.fetchingFileCatche[fileUrl]) {
            this.fetchingFileCatche[fileUrl] = recursivePart();
        }
        return this.fetchingFileCatche[fileUrl];
    };
    return RDFLoader;
}());
exports.RDFLoader = RDFLoader;
function fetchFile(params) {
    return fetch(params.proxy + params.url, {
        method: 'GET',
        credentials: 'same-origin',
        mode: 'cors',
        cache: 'default',
        headers: params.headers || {
            Accept: 'application/rdf+xml',
        },
    }).then(function (response) {
        if (response.ok) {
            return response.text();
        }
        else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    });
}
