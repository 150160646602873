"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceLayout = exports.WorkspaceLayoutType = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var accordion_1 = require("../accordion");
var accordionItem_1 = require("../accordionItem");
var DEFAULT_HORIZONTAL_COLLAPSED_SIZE = 28;
var WorkspaceLayoutType;
(function (WorkspaceLayoutType) {
    WorkspaceLayoutType["Row"] = "row";
    WorkspaceLayoutType["Column"] = "column";
    WorkspaceLayoutType["Component"] = "component";
})(WorkspaceLayoutType = exports.WorkspaceLayoutType || (exports.WorkspaceLayoutType = {}));
var WorkspaceLayout = /** @class */ (function (_super) {
    tslib_1.__extends(WorkspaceLayout, _super);
    function WorkspaceLayout() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WorkspaceLayout.prototype.renderAccordion = function (_a) {
        var _this = this;
        var children = _a.children, direction = _a.direction, animationDuration = _a.animationDuration;
        var _b = this.props, _onStartResize = _b._onStartResize, _onResize = _b._onResize;
        var items = children.map(function (child, index) {
            var dockSide;
            if (direction === 'horizontal' && !child.undocked) {
                if (index === 0) {
                    dockSide = accordionItem_1.DockSide.Left;
                }
                else if (index === children.length - 1) {
                    dockSide = accordionItem_1.DockSide.Right;
                }
            }
            var collapsedSize = child.collapsedSize;
            if (collapsedSize === undefined && direction === 'horizontal') {
                collapsedSize = DEFAULT_HORIZONTAL_COLLAPSED_SIZE;
            }
            return (React.createElement(accordionItem_1.AccordionItem, { key: child.type === WorkspaceLayoutType.Component ? child.id : index, heading: child.type === WorkspaceLayoutType.Component
                    ? child.heading
                    : undefined, dockSide: dockSide, defaultSize: child.defaultSize, defaultCollapsed: child.defaultCollapsed, collapsedSize: collapsedSize, minSize: child.minSize }, _this.renderLayout(child)));
        });
        return (React.createElement(accordion_1.Accordion, { direction: direction, onStartResize: _onStartResize, onResize: _onResize, animationDuration: animationDuration }, items));
    };
    WorkspaceLayout.prototype.renderLayout = function (layout) {
        if (layout.type === WorkspaceLayoutType.Row) {
            return this.renderAccordion({
                children: layout.children,
                direction: 'horizontal',
                animationDuration: layout.animationDuration,
            });
        }
        if (layout.type === WorkspaceLayoutType.Column) {
            return this.renderAccordion({
                children: layout.children,
                direction: 'vertical',
                animationDuration: layout.animationDuration,
            });
        }
        if (layout.type === WorkspaceLayoutType.Component) {
            return React.Children.only(layout.content);
        }
        return null;
    };
    WorkspaceLayout.prototype.render = function () {
        var layout = this.props.layout;
        return this.renderLayout(layout);
    };
    return WorkspaceLayout;
}(React.Component));
exports.WorkspaceLayout = WorkspaceLayout;
