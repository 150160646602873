"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saveLayoutToLocalStorage = exports.loadDiagram = exports.tryLoadFromNamedResources = exports.tryLoadFromCloudStorage = exports.tryLoadLayoutFromLocalStorage = exports.onPageLoad = void 0;
var index_1 = require("../src/graph-explorer/index");
function onPageLoad(callback) {
    document.addEventListener('DOMContentLoaded', function () {
        var container = document.createElement('div');
        container.id = 'root';
        document.body.appendChild(container);
        callback(container);
    });
}
exports.onPageLoad = onPageLoad;
function tryLoadLayoutFromLocalStorage() {
    if (window.location.hash.length > 1) {
        try {
            var key = window.location.hash.substring(1);
            var unparsedLayout = localStorage.getItem(key);
            var entry = unparsedLayout && JSON.parse(unparsedLayout);
            if (!entry && key.charAt(0) !== '!' && key.charAt(1) !== '=') {
                alert("The diagram \u2018".concat(key, "\u2019 was not found in your browser's local storage."));
            }
            // backward compatibility test. If we encounder old diagram,
            // wrap it into Diagram interface, jsonld - pass through
            if (entry['@context']) {
                return entry;
            }
            else {
                return (0, index_1.convertToSerializedDiagram)({
                    layoutData: entry,
                    linkTypeOptions: [],
                });
            }
        }
        catch (e) {
            /* ignore */
        }
    }
    return undefined;
}
exports.tryLoadLayoutFromLocalStorage = tryLoadLayoutFromLocalStorage;
function tryLoadFromCloudStorage() {
    if (window.location.hash.length > 2 && window.location.hash.slice(0, 2) === '#!') {
        var remote_1 = window.location.hash.slice(2);
        return fetch('https://diagramstore.aksw.org/' + remote_1)
            .then(function (res) { return res.json(); })
            .catch(function (e) {
            alert("The diagram \u2018".concat(remote_1.substring(0, 8) + (remote_1.length > 8 ? '…' : ''), "\u2019 was not found in the cloud storage."));
        });
    }
    return Promise.resolve(undefined);
}
exports.tryLoadFromCloudStorage = tryLoadFromCloudStorage;
function tryLoadFromNamedResources(workspace) {
    if (window.location.hash.length > 3 && window.location.hash.slice(0, 3) === '#r=') {
        var remote = window.location.hash.slice(3);
        if (remote) {
            var model_1 = workspace.getModel();
            var iris_1 = remote.split(';');
            model_1.dataProvider.elementInfo({ elementIds: iris_1 })
                .then(function (em) {
                var ids = new Map();
                iris_1.forEach(function (iri) {
                    var node = model_1.createElement(em[iri]);
                    ids.set(iri, node.id);
                });
                workspace.forceLayout();
                return ids;
            }).then(function (ids) {
                /* now that we have the resources, add the links */
                model_1.dataProvider
                    .linksInfo({ elementIds: iris_1, linkTypeIds: [] })
                    .then(function (lm) {
                    lm.forEach(function (link) {
                        var newLink = new index_1.Link({
                            typeId: link.linkTypeId,
                            sourceId: ids.get(link.sourceId),
                            targetId: ids.get(link.targetId)
                        });
                        model_1.addLink(newLink);
                    });
                    workspace.forceLayout();
                });
            });
        }
    }
}
exports.tryLoadFromNamedResources = tryLoadFromNamedResources;
function loadDiagram(workspace, dataProvider) {
    var localDiagram = tryLoadLayoutFromLocalStorage();
    var next = localDiagram ? Promise.resolve(localDiagram) : tryLoadFromCloudStorage();
    next.then(function (diagram) {
        workspace.getModel().importLayout({
            diagram: diagram,
            validateLinks: true,
            dataProvider: dataProvider
        });
        if (!diagram) {
            tryLoadFromNamedResources(workspace);
        }
    });
}
exports.loadDiagram = loadDiagram;
function saveLayoutToLocalStorage(diagram) {
    var randomKey = Math.floor((1 + Math.random()) * 0x10000000000)
        .toString(16)
        .substring(1);
    localStorage.setItem(randomKey, JSON.stringify(diagram));
    return randomKey;
}
exports.saveLayoutToLocalStorage = saveLayoutToLocalStorage;
