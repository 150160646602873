"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthoredEntity = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var paperArea_1 = require("../diagram/paperArea");
var async_1 = require("../viewUtils/async");
var workspaceContext_1 = require("../workspace/workspaceContext");
var authoringState_1 = require("./authoringState");
/**
 * Component to simplify tracking changes in validation messages (property and link type labels).
 */
var AuthoredEntity = /** @class */ (function (_super) {
    tslib_1.__extends(AuthoredEntity, _super);
    function AuthoredEntity(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.queryCancellation = new async_1.Cancellation();
        _this.onChangeAuthoringState = function (e) {
            var editor = e.source, previous = e.previous;
            var iri = _this.props.templateProps.data.id;
            var current = editor.authoringState;
            if (current.elements.get(iri) !== previous.elements.get(iri)) {
                _this.queryAllowedActions();
            }
        };
        _this.onEdit = function () {
            var editor = _this.context.workspace.editor;
            var elementId = _this.props.templateProps.elementId;
            var element = editor.model.getElement(elementId);
            editor.showEditEntityForm(element);
        };
        _this.onDelete = function () {
            var editor = _this.context.workspace.editor;
            var data = _this.props.templateProps.data;
            editor.deleteEntity(data.id);
        };
        _this.state = {};
        return _this;
    }
    AuthoredEntity.prototype.componentDidMount = function () {
        var editor = this.context.workspace.editor;
        editor.events.on('changeAuthoringState', this.onChangeAuthoringState);
        this.queryAllowedActions();
    };
    AuthoredEntity.prototype.componentDidUpdate = function (prevProps) {
        var shouldUpdateAllowedActions = !(this.props.templateProps.data === prevProps.templateProps.data &&
            this.props.templateProps.isExpanded === prevProps.templateProps.isExpanded);
        if (shouldUpdateAllowedActions) {
            this.queryAllowedActions();
        }
    };
    AuthoredEntity.prototype.componentWillUnmount = function () {
        var editor = this.context.workspace.editor;
        editor.events.off('changeAuthoringState', this.onChangeAuthoringState);
        this.queryCancellation.abort();
    };
    AuthoredEntity.prototype.queryAllowedActions = function () {
        var _a = this.props.templateProps, isExpanded = _a.isExpanded, data = _a.data;
        // only fetch whether it's allowed to edit when expanded
        if (!isExpanded) {
            return;
        }
        this.queryCancellation.abort();
        this.queryCancellation = new async_1.Cancellation();
        var editor = this.context.workspace.editor;
        if (!editor.metadataApi ||
            authoringState_1.AuthoringState.isDeletedElement(editor.authoringState, data.id)) {
            this.setState({ canEdit: false, canDelete: false });
        }
        else {
            this.queryCanEdit(data);
            this.queryCanDelete(data);
        }
    };
    AuthoredEntity.prototype.queryCanEdit = function (data) {
        var _this = this;
        var editor = this.context.workspace.editor;
        var signal = this.queryCancellation.signal;
        this.setState({ canEdit: undefined });
        async_1.CancellationToken.mapCancelledToNull(signal, editor.metadataApi.canEditElement(data, signal)).then(function (canEdit) {
            if (canEdit === null) {
                return;
            }
            _this.setState({ canEdit: canEdit });
        });
    };
    AuthoredEntity.prototype.queryCanDelete = function (data) {
        var _this = this;
        var editor = this.context.workspace.editor;
        var signal = this.queryCancellation.signal;
        this.setState({ canDelete: undefined });
        async_1.CancellationToken.mapCancelledToNull(signal, editor.metadataApi.canDeleteElement(data, signal)).then(function (canDelete) {
            if (canDelete === null) {
                return;
            }
            _this.setState({ canDelete: canDelete });
        });
    };
    AuthoredEntity.prototype.render = function () {
        var renderTemplate = this.props.children;
        var view = this.context.paperArea.view;
        var editor = this.context.workspace.editor;
        var _a = this.state, canEdit = _a.canEdit, canDelete = _a.canDelete;
        var iri = this.props.templateProps.iri;
        var elementEvent = editor.authoringState.elements.get(iri);
        var editedIri = elementEvent && elementEvent.type === authoringState_1.AuthoringKind.ChangeElement
            ? elementEvent.newIri
            : undefined;
        return renderTemplate({
            editor: editor,
            view: view,
            canEdit: canEdit,
            canDelete: canDelete,
            editedIri: editedIri,
            onEdit: this.onEdit,
            onDelete: this.onDelete,
        });
    };
    AuthoredEntity.contextTypes = tslib_1.__assign(tslib_1.__assign({}, paperArea_1.PaperAreaContextTypes), workspaceContext_1.WorkspaceContextTypes);
    return AuthoredEntity;
}(React.Component));
exports.AuthoredEntity = AuthoredEntity;
