"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupTemplate = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var embeddedLayer_1 = require("../../diagram/embeddedLayer");
var CLASS = 'graph-explorer-group-template';
var GroupTemplate = /** @class */ (function (_super) {
    tslib_1.__extends(GroupTemplate, _super);
    function GroupTemplate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GroupTemplate.prototype.render = function () {
        var _a = this.props, label = _a.label, iconUrl = _a.iconUrl, types = _a.types, color = _a.color, isExpanded = _a.isExpanded;
        return (React.createElement("div", { className: CLASS },
            React.createElement("div", { className: "".concat(CLASS, "__wrap"), style: {
                    backgroundColor: color,
                    borderColor: color,
                } },
                React.createElement("div", { className: "".concat(CLASS, "__type-line"), title: label },
                    React.createElement("div", { className: "".concat(CLASS, "__type-line-icon") },
                        React.createElement("img", { src: iconUrl })),
                    React.createElement("div", { title: types, className: "".concat(CLASS, "__type-line-text-container") },
                        React.createElement("div", { className: "".concat(CLASS, "__type-line-text") }, types))),
                React.createElement("div", { className: "".concat(CLASS, "__body"), style: { borderColor: color } },
                    React.createElement("span", { className: "".concat(CLASS, "__label"), title: label }, label),
                    isExpanded ? (React.createElement("div", { className: "".concat(CLASS, "__embedded-layer") },
                        React.createElement(embeddedLayer_1.EmbeddedLayer, null))) : null))));
    };
    return GroupTemplate;
}(React.Component));
exports.GroupTemplate = GroupTemplate;
