"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkTypesToolbox = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var commands_1 = require("../diagram/commands");
var elements_1 = require("../diagram/elements");
var async_1 = require("../viewUtils/async");
var events_1 = require("../viewUtils/events");
var listElementView_1 = require("./listElementView");
var progressBar_1 = require("./progressBar");
var LinkInToolBox = /** @class */ (function (_super) {
    tslib_1.__extends(LinkInToolBox, _super);
    function LinkInToolBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onPressFilter = function () {
            if (_this.props.onPressFilter) {
                _this.props.onPressFilter(_this.props.link);
            }
        };
        _this.changeState = function (state) {
            var history = _this.props.view.model.history;
            changeLinkTypeState(history, state, [_this.props.link]);
        };
        _this.isChecked = function (stateName) {
            var curState;
            if (!_this.props.link.visible) {
                curState = 'invisible';
            }
            else if (!_this.props.link.showLabel) {
                curState = 'withoutLabels';
            }
            else {
                curState = 'allVisible';
            }
            return stateName === curState;
        };
        _this.getText = function () {
            var _a = _this.props, linkType = _a.link, view = _a.view, filterKey = _a.filterKey;
            var fullText = view.formatLabel(linkType.label, linkType.id);
            return (0, listElementView_1.highlightSubstring)(fullText, filterKey);
        };
        return _this;
    }
    LinkInToolBox.prototype.render = function () {
        var _this = this;
        var newIcon = this.props.link.isNew ? (React.createElement("span", { className: "linkInToolBox__new-tag" }, "new")) : ('');
        var countIcon = this.props.count > 0 ? (React.createElement("span", { className: "graph-explorer-badge" }, this.props.count)) : ('');
        var badgeContainer = newIcon || countIcon ? (React.createElement("div", null,
            newIcon,
            countIcon)) : ('');
        return (React.createElement("li", { "data-linktypeid": this.props.link.id, className: "graph-explorer-list-group-item linkInToolBox clearfix" },
            React.createElement("span", { className: "graph-explorer-btn-group graph-explorer-btn-group-xs", "data-toggle": "buttons" },
                React.createElement("label", { className: 'graph-explorer-btn graph-explorer-btn-default' +
                        (this.isChecked('invisible') ? ' active' : ''), id: "invisible", title: "Hide links and labels", onClick: function () { return _this.changeState('invisible'); } },
                    React.createElement("span", { className: "fa fa-times", "aria-hidden": "true" })),
                React.createElement("label", { className: 'graph-explorer-btn graph-explorer-btn-default' +
                        (this.isChecked('withoutLabels') ? ' active' : ''), id: "withoutLabels", title: "Show links without labels", onClick: function () { return _this.changeState('withoutLabels'); } },
                    React.createElement("span", { className: "fa fa-arrows-h", "aria-hidden": "true" })),
                React.createElement("label", { className: 'graph-explorer-btn graph-explorer-btn-default' +
                        (this.isChecked('allVisible') ? ' active' : ''), id: "allVisible", title: "Show links with labels", onClick: function () { return _this.changeState('allVisible'); } },
                    React.createElement("span", { className: "fa fa-text-width", "aria-hidden": "true" }))),
            React.createElement("div", { className: "link-title" }, this.getText()),
            badgeContainer,
            React.createElement("div", { className: "linkInToolBox__filter-button", onClick: this.onPressFilter })));
    };
    return LinkInToolBox;
}(React.Component));
var LinkTypesToolboxView = /** @class */ (function (_super) {
    tslib_1.__extends(LinkTypesToolboxView, _super);
    function LinkTypesToolboxView(props) {
        var _this = _super.call(this, props) || this;
        _this.compareLinks = function (a, b) {
            var view = _this.props.view;
            var aText = view.formatLabel(a.label, a.id);
            var bText = view.formatLabel(b.label, b.id);
            return aText.localeCompare(bText);
        };
        _this.onChangeInput = function (e) {
            _this.setState({ filterKey: e.currentTarget.value });
        };
        _this.onDropFilter = function () {
            _this.setState({ filterKey: '' });
        };
        _this.getLinks = function () {
            var _a = _this.props, view = _a.view, _b = _a.links, links = _b === void 0 ? [] : _b;
            return links
                .filter(function (linkType) {
                var text = view
                    .formatLabel(linkType.label, linkType.id)
                    .toLowerCase();
                return (!_this.state.filterKey ||
                    text.indexOf(_this.state.filterKey.toLowerCase()) >= 0);
            })
                .sort(_this.compareLinks);
        };
        _this.getViews = function (links) {
            var countMap = _this.props.countMap || {};
            var views = [];
            for (var _i = 0, links_1 = links; _i < links_1.length; _i++) {
                var link = links_1[_i];
                views.push(React.createElement(LinkInToolBox, { key: link.id, view: _this.props.view, link: link, onPressFilter: _this.props.filterCallback, count: countMap[link.id] || 0, filterKey: _this.state.filterKey }));
            }
            return views;
        };
        _this.state = { filterKey: '' };
        return _this;
    }
    LinkTypesToolboxView.prototype.render = function () {
        var className = 'link-types-toolbox';
        var _a = this.props, view = _a.view, dataState = _a.dataState, selectedElement = _a.selectedElement;
        var history = view.model.history;
        var links = this.getLinks();
        var views = this.getViews(links);
        var connectedTo = null;
        if (selectedElement) {
            var selectedElementLabel = view.formatLabel(selectedElement.data.label.values, selectedElement.iri);
            connectedTo = (React.createElement("h4", { className: "links-heading", style: { display: 'block' } },
                "Connected to",
                '\u00A0',
                React.createElement("span", null, selectedElementLabel)));
        }
        var dropButton = null;
        if (this.state.filterKey) {
            dropButton = (React.createElement("button", { type: "button", className: "".concat(className, "__clearSearch"), onClick: this.onDropFilter },
                React.createElement("span", { className: "fa fa-times", "aria-hidden": "true" })));
        }
        return (React.createElement("div", { className: className },
            React.createElement("div", { className: "".concat(className, "__heading") },
                React.createElement("div", { className: "".concat(className, "__searching-box") },
                    React.createElement("input", { className: "search-input graph-explorer-form-control", type: "text", value: this.state.filterKey, onChange: this.onChangeInput, placeholder: "Search for..." }),
                    dropButton),
                React.createElement("div", { className: "".concat(className, "__switch-all") },
                    React.createElement("div", { className: "graph-explorer-btn-group graph-explorer-btn-group-xs" },
                        React.createElement("label", { className: "graph-explorer-btn graph-explorer-btn-default", title: "Hide links and labels", onClick: function () { return changeLinkTypeState(history, 'invisible', links); } },
                            React.createElement("span", { className: "fa fa-times", "aria-hidden": "true" })),
                        React.createElement("label", { className: "graph-explorer-btn graph-explorer-btn-default", title: "Show links without labels", onClick: function () {
                                return changeLinkTypeState(history, 'withoutLabels', links);
                            } },
                            React.createElement("span", { className: "fa fa-arrows-h", "aria-hidden": "true" })),
                        React.createElement("label", { className: "graph-explorer-btn graph-explorer-btn-default", title: "Show links with labels", onClick: function () {
                                return changeLinkTypeState(history, 'allVisible', links);
                            } },
                            React.createElement("span", { className: "fa fa-text-width", "aria-hidden": "true" }))),
                    React.createElement("span", null, "\u00A0Switch all"))),
            React.createElement(progressBar_1.ProgressBar, { state: dataState }),
            React.createElement("div", { className: "".concat(className, "__rest") },
                connectedTo,
                React.createElement("div", { className: "graph-explorer-scrollable" },
                    React.createElement("ul", { className: "graph-explorer-list-group connected-links" }, views)))));
    };
    return LinkTypesToolboxView;
}(React.Component));
var LinkTypesToolbox = /** @class */ (function (_super) {
    tslib_1.__extends(LinkTypesToolbox, _super);
    function LinkTypesToolbox(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.listener = new events_1.EventObserver();
        _this.linkListener = new events_1.EventObserver();
        _this.debounceSelection = new async_1.Debouncer(50 /* ms */);
        _this.updateOnCurrentSelection = function () {
            var editor = _this.props.editor;
            var single = editor.selection.length === 1 ? editor.selection[0] : null;
            if (single !== _this.state.selectedElement && single instanceof elements_1.Element) {
                _this.requestLinksOf(single);
            }
        };
        _this.onLinkChanged = function () {
            _this.forceUpdate();
        };
        _this.onAddToFilter = function (linkType) {
            var selectedElement = _this.state.selectedElement;
            selectedElement.addToFilter(linkType);
        };
        var _a = _this.props, view = _a.view, editor = _a.editor;
        _this.listener.listen(view.events, 'changeLanguage', function () {
            return _this.updateOnCurrentSelection();
        });
        _this.listener.listen(editor.model.events, 'loadingSuccess', function () {
            return _this.updateOnCurrentSelection();
        });
        _this.listener.listen(editor.events, 'changeSelection', function () {
            _this.debounceSelection.call(_this.updateOnCurrentSelection);
        });
        _this.state = { dataState: progressBar_1.ProgressState.none };
        return _this;
    }
    LinkTypesToolbox.prototype.componentDidMount = function () {
        this.updateOnCurrentSelection();
    };
    LinkTypesToolbox.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.linkListener.stopListening();
        this.debounceSelection.dispose();
    };
    LinkTypesToolbox.prototype.requestLinksOf = function (selectedElement) {
        var _this = this;
        if (selectedElement) {
            var request_1 = { elementId: selectedElement.iri };
            this.currentRequest = request_1;
            this.setState({ dataState: progressBar_1.ProgressState.loading, selectedElement: selectedElement });
            this.props.editor.model.dataProvider
                .linkTypesOf(request_1)
                .then(function (linkTypes) {
                if (_this.currentRequest !== request_1) {
                    return;
                }
                var _a = _this.computeStateFromRequestResult(linkTypes), linksOfElement = _a.linksOfElement, countMap = _a.countMap;
                _this.subscribeOnLinksEvents(linksOfElement);
                _this.setState({
                    dataState: progressBar_1.ProgressState.completed,
                    linksOfElement: linksOfElement,
                    countMap: countMap,
                });
            })
                .catch(function (error) {
                if (_this.currentRequest !== request_1) {
                    return;
                }
                // tslint:disable-next-line:no-console
                console.error(error);
                _this.setState({
                    dataState: progressBar_1.ProgressState.error,
                    linksOfElement: undefined,
                    countMap: {},
                });
            });
        }
        else {
            this.currentRequest = null;
            this.setState({
                dataState: progressBar_1.ProgressState.completed,
                selectedElement: selectedElement,
                linksOfElement: undefined,
                countMap: {},
            });
        }
    };
    LinkTypesToolbox.prototype.computeStateFromRequestResult = function (linkTypes) {
        var linksOfElement = [];
        var countMap = {};
        var model = this.props.editor.model;
        for (var _i = 0, linkTypes_1 = linkTypes; _i < linkTypes_1.length; _i++) {
            var linkType = linkTypes_1[_i];
            var type = model.createLinkType(linkType.id);
            linksOfElement.push(type);
            countMap[linkType.id] = linkType.inCount + linkType.outCount;
        }
        return { linksOfElement: linksOfElement, countMap: countMap };
    };
    LinkTypesToolbox.prototype.subscribeOnLinksEvents = function (linksOfElement) {
        this.linkListener.stopListening();
        var listener = this.linkListener;
        for (var _i = 0, linksOfElement_1 = linksOfElement; _i < linksOfElement_1.length; _i++) {
            var link = linksOfElement_1[_i];
            listener.listen(link.events, 'changeLabel', this.onLinkChanged);
            listener.listen(link.events, 'changeVisibility', this.onLinkChanged);
        }
    };
    LinkTypesToolbox.prototype.render = function () {
        var view = this.props.view;
        var _a = this.state, selectedElement = _a.selectedElement, dataState = _a.dataState, linksOfElement = _a.linksOfElement, countMap = _a.countMap;
        return (React.createElement(LinkTypesToolboxView, { view: view, dataState: dataState, links: linksOfElement, countMap: countMap, filterCallback: this.onAddToFilter, selectedElement: selectedElement }));
    };
    return LinkTypesToolbox;
}(React.Component));
exports.LinkTypesToolbox = LinkTypesToolbox;
function changeLinkTypeState(history, state, links) {
    var batch = history.startBatch();
    var _a = state === 'invisible'
        ? { visible: false, showLabel: false }
        : state === 'withoutLabels'
            ? { visible: true, showLabel: false }
            : state === 'allVisible'
                ? { visible: true, showLabel: true }
                : undefined, visible = _a.visible, showLabel = _a.showLabel;
    for (var _i = 0, links_2 = links; _i < links_2.length; _i++) {
        var linkType = links_2[_i];
        history.execute((0, commands_1.changeLinkTypeVisibility)({ linkType: linkType, visible: visible, showLabel: showLabel }));
    }
    batch.store();
}
