"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultToolbar = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var CLASS_NAME = 'graph-explorer-toolbar';
var DefaultToolbar = /** @class */ (function (_super) {
    tslib_1.__extends(DefaultToolbar, _super);
    function DefaultToolbar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onChangeLanguage = function (event) {
            var value = event.currentTarget.value;
            _this.props.onChangeLanguage(value);
        };
        _this.onExportSVG = function () {
            _this.props.onExportSVG();
        };
        _this.onExportPNG = function () {
            _this.props.onExportPNG();
        };
        return _this;
    }
    DefaultToolbar.prototype.renderSaveDiagramButton = function () {
        if (!this.props.onSaveDiagram) {
            return null;
        }
        return (React.createElement("button", { type: "button", className: "saveDiagramButton graph-explorer-btn graph-explorer-btn-default", disabled: this.props.canSaveDiagram === false, onClick: this.props.onSaveDiagram },
            React.createElement("span", { className: "fa fa-cloud-upload", "aria-hidden": "true" }),
            " Save diagram"));
    };
    DefaultToolbar.prototype.renderPersistAuthoredChangesButton = function () {
        if (!this.props.onPersistChanges) {
            return null;
        }
        return (React.createElement("button", { type: "button", className: "saveDiagramButton graph-explorer-btn graph-explorer-btn-success", disabled: this.props.canPersistChanges === false, onClick: this.props.onPersistChanges },
            React.createElement("span", { className: "fa fa-floppy-o", "aria-hidden": "true" }),
            " Save data"));
    };
    DefaultToolbar.prototype.renderLanguages = function () {
        var _a = this.props, selectedLanguage = _a.selectedLanguage, languages = _a.languages;
        if (languages.length <= 1) {
            return null;
        }
        return (React.createElement("span", { className: "graph-explorer-btn-group ".concat(CLASS_NAME, "__language-selector") },
            React.createElement("label", { className: "graph-explorer-label" },
                React.createElement("span", null, "Data Language - ")),
            React.createElement("select", { value: selectedLanguage, onChange: this.onChangeLanguage }, languages.map(function (_a) {
                var code = _a.code, label = _a.label;
                return (React.createElement("option", { key: code, value: code }, label));
            }))));
    };
    DefaultToolbar.prototype.render = function () {
        return (React.createElement("div", { className: CLASS_NAME },
            React.createElement("div", { className: "graph-explorer-btn-group graph-explorer-btn-group-sm" },
                React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Reload Classes Tree", onClick: this.props.onReloadClassTree },
                    React.createElement("span", { className: "fa fa-refresh", "aria-hidden": "true" }),
                    " Classes"),
                React.createElement("span", { className: "graph-explorer-toolbar__layout-group" }, "\u00A0"),
                this.renderSaveDiagramButton(),
                React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Download diagram source", onClick: this.props.onDownloadSource },
                    React.createElement("span", { className: "fa fa-download", "aria-hidden": "true" })),
                React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Open diagram source", onClick: this.props.onUploadSource },
                    React.createElement("span", { className: "fa fa-folder-open-o", "aria-hidden": "true" })),
                this.renderPersistAuthoredChangesButton(),
                this.props.onClearAll ? (React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Clear All", onClick: this.props.onClearAll },
                    React.createElement("span", { className: "fa fa-trash", "aria-hidden": "true" }),
                    "\u00A0Clear All")) : null,
                React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Force layout", onClick: this.props.onForceLayout },
                    React.createElement("span", { className: "fa fa-sitemap", "aria-hidden": "true" }),
                    " Layout"),
                React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Zoom In", onClick: this.props.onZoomIn },
                    React.createElement("span", { className: "fa fa-search-plus", "aria-hidden": "true" })),
                React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Zoom Out", onClick: this.props.onZoomOut },
                    React.createElement("span", { className: "fa fa-search-minus", "aria-hidden": "true" })),
                React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Fit to Screen", onClick: this.props.onZoomToFit },
                    React.createElement("span", { className: "fa fa-arrows-alt", "aria-hidden": "true" })),
                React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Print diagram", onClick: window.print },
                    React.createElement("span", { className: "fa fa-print", "aria-hidden": "true" })),
                React.createElement("span", { className: "graph-explorer-toolbar__layout-group" }, "\u00A0"),
                this.renderLanguages())));
    };
    return DefaultToolbar;
}(React.Component));
exports.DefaultToolbar = DefaultToolbar;
