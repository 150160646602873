"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isBlankBinding = exports.isRdfLiteral = exports.isRdfIri = exports.isRdfBlank = void 0;
function isRdfBlank(e) {
    return e && e.type === 'bnode';
}
exports.isRdfBlank = isRdfBlank;
function isRdfIri(e) {
    return e && e.type === 'uri';
}
exports.isRdfIri = isRdfIri;
function isRdfLiteral(e) {
    return e && e.type === 'literal';
}
exports.isRdfLiteral = isRdfLiteral;
function isBlankBinding(binding) {
    var blank = binding;
    return (blank.blankTrgProp !== undefined ||
        blank.blankTrg !== undefined ||
        blank.blankSrcProp !== undefined ||
        blank.blankSrc !== undefined);
}
exports.isBlankBinding = isBlankBinding;
