"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NonRememberingHistory = exports.Command = void 0;
var events_1 = require("../viewUtils/events");
function createAction(title, action) {
    return { title: title, invoke: action };
}
function effectAction(title, body) {
    var perform = {
        title: title,
        invoke: function () {
            body();
            return skip;
        },
    };
    var skip = {
        title: 'Skipped effect: ' + title,
        invoke: function () { return perform; },
    };
    return perform;
}
exports.Command = {
    create: createAction,
    effect: effectAction,
};
var NonRememberingHistory = /** @class */ (function () {
    function NonRememberingHistory() {
        this.source = new events_1.EventSource();
        this.events = this.source;
        this.undoStack = [];
        this.redoStack = [];
        this.storeBatch = function () {
            /* nothing */
        };
        this.discardBatch = function () {
            /* nothing */
        };
    }
    NonRememberingHistory.prototype.reset = function () {
        this.source.trigger('historyChanged', { hasChanges: false });
    };
    NonRememberingHistory.prototype.undo = function () {
        throw new Error('Undo is unsupported');
    };
    NonRememberingHistory.prototype.redo = function () {
        throw new Error('Redo is unsupported');
    };
    NonRememberingHistory.prototype.execute = function (command) {
        command.invoke();
        this.source.trigger('historyChanged', { hasChanges: true });
    };
    NonRememberingHistory.prototype.registerToUndo = function (command) {
        this.source.trigger('historyChanged', { hasChanges: true });
    };
    NonRememberingHistory.prototype.startBatch = function (title) {
        return {
            history: this,
            store: this.storeBatch,
            discard: this.discardBatch,
        };
    };
    return NonRememberingHistory;
}());
exports.NonRememberingHistory = NonRememberingHistory;
