"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HtmlSpinner = exports.Spinner = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var CLASS_NAME = 'graph-explorer-spinner';
var Spinner = /** @class */ (function (_super) {
    tslib_1.__extends(Spinner, _super);
    function Spinner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Spinner.prototype.render = function () {
        var _a = this.props, _b = _a.position, position = _b === void 0 ? { x: 0, y: 0 } : _b, _c = _a.size, size = _c === void 0 ? 50 : _c, statusText = _a.statusText, errorOccured = _a.errorOccured;
        var textLeftMargin = 5;
        var pathGeometry = 'm3.47,-19.7 a20,20 0 1,1 -6.95,0 m0,0 l-6,5 m6,-5 l-8,-0' +
            (errorOccured ? 'M-8,-8L8,8M-8,8L8,-8' : '');
        return (React.createElement("g", { className: CLASS_NAME, "data-error": errorOccured, transform: "translate(".concat(position.x, ",").concat(position.y, ")") },
            React.createElement("g", { className: "".concat(CLASS_NAME, "__arrow") },
                React.createElement("path", { d: pathGeometry, transform: "scale(0.02)scale(".concat(size, ")"), fill: "none", stroke: errorOccured ? 'red' : 'black', strokeWidth: "3", strokeLinecap: "round" })),
            React.createElement("text", { style: { dominantBaseline: 'middle' }, x: size / 2 + textLeftMargin }, statusText)));
    };
    return Spinner;
}(React.Component));
exports.Spinner = Spinner;
var HtmlSpinner = /** @class */ (function (_super) {
    tslib_1.__extends(HtmlSpinner, _super);
    function HtmlSpinner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HtmlSpinner.prototype.render = function () {
        var _a = this.props, width = _a.width, height = _a.height;
        var size = Math.min(width, height);
        return (React.createElement("svg", { width: width, height: height },
            React.createElement(Spinner, { size: size, position: { x: width / 2, y: height / 2 } })));
    };
    return HtmlSpinner;
}(React.Component));
exports.HtmlSpinner = HtmlSpinner;
