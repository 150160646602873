"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SparqlGraphBuilder = void 0;
var graphBuilder_1 = require("./graphBuilder");
var DEFAULT_PREFIX = "PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>\n PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#>\n PREFIX owl:  <http://www.w3.org/2002/07/owl#>" + '\n\n';
var SparqlGraphBuilder = /** @class */ (function () {
    function SparqlGraphBuilder(dataProvider) {
        this.dataProvider = dataProvider;
        this.graphBuilder = new graphBuilder_1.GraphBuilder(dataProvider);
    }
    SparqlGraphBuilder.prototype.getGraphFromConstruct = function (constructQuery) {
        var _this = this;
        var query = DEFAULT_PREFIX + constructQuery;
        return this.dataProvider
            .executeSparqlConstruct(query)
            .then(function (graph) { return _this.graphBuilder.getGraphFromRDFGraph(graph); });
    };
    return SparqlGraphBuilder;
}());
exports.SparqlGraphBuilder = SparqlGraphBuilder;
