"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkspaceContextTypes = exports.WorkspaceEventKey = void 0;
var react_1 = require("../viewUtils/react");
var WorkspaceEventKey;
(function (WorkspaceEventKey) {
    WorkspaceEventKey["searchUpdateCriteria"] = "search:updateCriteria";
    WorkspaceEventKey["searchQueryItem"] = "search:queryItems";
    WorkspaceEventKey["connectionsLoadLinks"] = "connections:loadLinks";
    WorkspaceEventKey["connectionsExpandLink"] = "connections:expandLink";
    WorkspaceEventKey["connectionsLoadElements"] = "connections:loadElements";
    WorkspaceEventKey["editorChangeSelection"] = "editor:changeSelection";
    WorkspaceEventKey["editorToggleDialog"] = "editor:toggleDialog";
    WorkspaceEventKey["editorAddElements"] = "editor:addElements";
})(WorkspaceEventKey = exports.WorkspaceEventKey || (exports.WorkspaceEventKey = {}));
exports.WorkspaceContextTypes = {
    workspace: react_1.PropTypes.anything,
};
