"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.n3toRdfNode = exports.parseTurtleText = void 0;
var N3 = require("n3");
function parseTurtleText(turtleText) {
    return new Promise(function (resolve, reject) {
        var triples = [];
        new N3.Parser().parse(turtleText, function (error, triple, hash) {
            if (error) {
                reject(error);
            }
            else if (triple) {
                triples.push({
                    subject: n3toRdfNode(triple.subject),
                    predicate: n3toRdfNode(triple.predicate),
                    object: n3toRdfNode(triple.object),
                });
            }
            else {
                resolve(triples);
            }
        });
    });
}
exports.parseTurtleText = parseTurtleText;
function n3toRdfNode(entity) {
    if (N3.Util.isLiteral(entity)) {
        var literal = entity;
        return {
            type: 'literal',
            value: literal.value,
            datatype: literal.datatype.value,
            'xml:lang': literal.language
        };
    }
    else {
        return { type: 'uri', value: entity.value };
    }
}
exports.n3toRdfNode = n3toRdfNode;
