"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paperFromPaneCoords = exports.paneFromPaperCoords = exports.paneTopLeft = exports.totalPaneSize = exports.TransformedSvgCanvas = exports.Paper = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var react_1 = require("react");
var elements_1 = require("./elements");
var elementLayer_1 = require("./elementLayer");
var linkLayer_1 = require("./linkLayer");
var CLASS_NAME = 'graph-explorer-paper';
var Paper = /** @class */ (function (_super) {
    tslib_1.__extends(Paper, _super);
    function Paper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onMouseDown = function (e) {
            var _a = _this.props, view = _a.view, onPointerDown = _a.onPointerDown;
            var cell = e.target instanceof Element
                ? findCell(e.target, e.currentTarget, view.model)
                : undefined;
            if (onPointerDown) {
                onPointerDown(e, cell);
            }
        };
        return _this;
    }
    Paper.prototype.render = function () {
        var _a = this.props, view = _a.view, group = _a.group, paperTransform = _a.paperTransform, linkLayerWidgets = _a.linkLayerWidgets, elementLayerWidgets = _a.elementLayerWidgets;
        var width = paperTransform.width, height = paperTransform.height, originX = paperTransform.originX, originY = paperTransform.originY, scale = paperTransform.scale, paddingX = paperTransform.paddingX, paddingY = paperTransform.paddingY;
        var scaledWidth = width * scale;
        var scaledHeight = height * scale;
        // using padding instead of margin in combination with setting width and height
        // on .paper element to avoid "over-constrained" margins, see an explanation here:
        // https://stackoverflow.com/questions/11695354
        var style = {
            width: scaledWidth + paddingX,
            height: scaledHeight + paddingY,
            marginLeft: paddingX,
            marginTop: paddingY,
            paddingRight: paddingX,
            paddingBottom: paddingY,
        };
        var htmlTransformStyle = {
            position: 'absolute',
            left: 0,
            top: 0,
            transform: "scale(".concat(scale, ",").concat(scale, ")translate(").concat(originX, "px,").concat(originY, "px)"),
        };
        return (React.createElement("div", { className: CLASS_NAME, style: style, onMouseDown: this.onMouseDown },
            React.createElement(TransformedSvgCanvas, { className: "".concat(CLASS_NAME, "__canvas"), style: { overflow: 'visible' }, paperTransform: paperTransform },
                React.createElement(linkLayer_1.LinkMarkers, { view: view }),
                React.createElement(linkLayer_1.LinkLayer, { view: view, links: view.model.links, group: group })),
            linkLayerWidgets,
            React.createElement(elementLayer_1.ElementLayer, { view: view, group: group, style: htmlTransformStyle }),
            elementLayerWidgets));
    };
    return Paper;
}(react_1.Component));
exports.Paper = Paper;
function findCell(bottom, top, model) {
    var target = bottom;
    var vertexIndex;
    while (true) {
        if (target instanceof Element) {
            if (target.hasAttribute('data-element-id')) {
                return model.getElement(target.getAttribute('data-element-id'));
            }
            else if (target.hasAttribute('data-link-id')) {
                var link = model.getLinkById(target.getAttribute('data-link-id'));
                return typeof vertexIndex === 'number'
                    ? new elements_1.LinkVertex(link, vertexIndex)
                    : link;
            }
            else if (target.hasAttribute('data-vertex')) {
                vertexIndex = Number(target.getAttribute('data-vertex'));
            }
        }
        if (!target || target === top) {
            break;
        }
        target = target.parentNode;
    }
    return undefined;
}
var TransformedSvgCanvas = /** @class */ (function (_super) {
    tslib_1.__extends(TransformedSvgCanvas, _super);
    function TransformedSvgCanvas() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TransformedSvgCanvas.prototype.render = function () {
        var _a = this.props, paperTransform = _a.paperTransform, style = _a.style, children = _a.children, otherProps = tslib_1.__rest(_a, ["paperTransform", "style", "children"]);
        var width = paperTransform.width, height = paperTransform.height, originX = paperTransform.originX, originY = paperTransform.originY, scale = paperTransform.scale, paddingX = paperTransform.paddingX, paddingY = paperTransform.paddingY;
        var scaledWidth = width * scale;
        var scaledHeight = height * scale;
        var svgStyle = TransformedSvgCanvas.SVG_STYLE;
        if (style) {
            svgStyle = tslib_1.__assign(tslib_1.__assign({}, svgStyle), style);
        }
        return (React.createElement("svg", tslib_1.__assign({ width: scaledWidth, height: scaledHeight, style: svgStyle }, otherProps),
            React.createElement("g", { transform: "scale(".concat(scale, ",").concat(scale, ")translate(").concat(originX, ",").concat(originY, ")") }, children)));
    };
    TransformedSvgCanvas.SVG_STYLE = {
        position: 'absolute',
        top: 0,
        left: 0,
    };
    return TransformedSvgCanvas;
}(react_1.Component));
exports.TransformedSvgCanvas = TransformedSvgCanvas;
/**
 * @returns scrollable pane size in non-scaled pane coords.
 */
function totalPaneSize(pt) {
    return {
        x: pt.width * pt.scale + pt.paddingX * 2,
        y: pt.height * pt.scale + pt.paddingY * 2,
    };
}
exports.totalPaneSize = totalPaneSize;
/**
 * @returns scrollable pane top-left corner position in non-scaled pane coords.
 */
function paneTopLeft(pt) {
    return { x: -pt.paddingX, y: -pt.paddingY };
}
exports.paneTopLeft = paneTopLeft;
function paneFromPaperCoords(paper, pt) {
    return {
        x: (paper.x + pt.originX) * pt.scale,
        y: (paper.y + pt.originY) * pt.scale,
    };
}
exports.paneFromPaperCoords = paneFromPaperCoords;
function paperFromPaneCoords(pane, pt) {
    return {
        x: pane.x / pt.scale - pt.originX,
        y: pane.y / pt.scale - pt.originY,
    };
}
exports.paperFromPaneCoords = paperFromPaneCoords;
