"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RDFCompositeParser = void 0;
function workaroundForRDFXmlParser(body) {
    // For some strange reason we've encountered xml parser errors
    // when parsing rdf/xml file with Collection tag.
    // As I remember, file came from x3c Ontology
    // and this workaround helps to get file through xml parsing.
    return body.replace(/parseType=["']Collection["']/gi, 'parseType="Collection1"');
}
var POSTFIX_TO_MIME = {
    xml: 'application/rdf+xml',
    rdf: 'application/rdf+xml',
    owl: 'application/rdf+xml',
    nttl: 'application/x-turtle',
    jsonld: 'application/ld+json',
    rj: 'application/ld+json',
    ttl: 'text/turtle',
    nt: 'text/turtle',
    nq: 'text/turtle',
};
function getMimeTypeByFileName(fileName) {
    var postfix = (fileName.match(/\.([\S]*)$/i) || [])[1];
    return postfix ? POSTFIX_TO_MIME[postfix] : undefined;
}
var RDFCompositeParser = /** @class */ (function () {
    function RDFCompositeParser(parserMap) {
        this.parserMap = parserMap;
    }
    RDFCompositeParser.prototype.parse = function (body, mimeType, fileName) {
        if (mimeType) {
            if (mimeType === 'application/rdf+xml') {
                body = workaroundForRDFXmlParser(body);
            }
            if (!this.parserMap[mimeType]) {
                throw Error('There is no parser for this MIME type');
            }
            return this.parserMap[mimeType].parse(body);
        }
        else {
            return this.tryToGuessMimeType(body, fileName);
        }
    };
    RDFCompositeParser.prototype.tryToGuessMimeType = function (body, fileName) {
        var _this = this;
        var mimeTypeIndex = 0;
        var mimeTypes = Object.keys(this.parserMap);
        if (fileName) {
            var mime_1 = getMimeTypeByFileName(fileName);
            if (mime_1) {
                mimeTypes = [mime_1].concat(mimeTypes.filter(function (type) { return type !== mime_1; }));
            }
        }
        var errors = [];
        var recursion = function () {
            if (mimeTypeIndex < mimeTypes.length) {
                var mimeType_1 = mimeTypes[mimeTypeIndex++];
                try {
                    var bodyToParse = mimeType_1 === 'application/rdf+xml'
                        ? workaroundForRDFXmlParser(body)
                        : body;
                    return _this.parserMap[mimeType_1]
                        .parse(bodyToParse)
                        .catch(function (error) {
                        errors.push({ mimeType: mimeType_1, error: error });
                        return recursion();
                    });
                }
                catch (error) {
                    return recursion();
                }
            }
            else {
                throw new Error('Unknow mime type. Parse errors:\n' +
                    errors
                        .map(function (e) { return "".concat(e.mimeType, ": ").concat(e.error.message, " ").concat(e.error.stack, ";\n"); })
                        .join('\n'));
            }
        };
        return recursion();
    };
    return RDFCompositeParser;
}());
exports.RDFCompositeParser = RDFCompositeParser;
