"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getElementTypes = exports.filter = exports.linkTypesOf = exports.linksInfo = exports.elementInfo = exports.createLabelForBlankBinding = exports.decodeId = exports.encodeId = exports.processBlankBindings = exports.updateFilterResults = exports.QueryExecutor = exports.isEncodedBlank = exports.BLANK_NODE_QUERY = exports.BLANK_NODE_QUERY_PARAMETERS = exports.ENCODED_PREFIX = exports.MAX_RECURSION_DEEP = void 0;
var tslib_1 = require("tslib");
var utils_1 = require("../utils");
var sparqlModels_1 = require("./sparqlModels");
exports.MAX_RECURSION_DEEP = 3;
exports.ENCODED_PREFIX = 'sparql-blank:';
exports.BLANK_NODE_QUERY_PARAMETERS = '?blankTrgProp ?blankTrg ?blankSrc ?blankSrcProp ?listHead';
exports.BLANK_NODE_QUERY = "\n    OPTIONAL {\n        FILTER (ISBLANK(?inst)).\n        {\n            ?inst ?blankTrgProp ?blankTrg.\n            ?blankSrc ?blankSrcProp ?inst.\n            FILTER NOT EXISTS { ?inst rdf:first _:smth1 }.\n            BIND(\"blankNode\" as ?blankType)\n        } UNION {\n            ?inst rdf:rest*/rdf:first ?blankTrg.\n            ?blankSrc ?blankSrcProp ?inst.\n            _:smth2 rdf:first ?blankTrg.\n            BIND(?blankSrcProp as ?blankTrgProp)\n            BIND(\"listHead\" as ?blankType)\n            FILTER NOT EXISTS { _:smth3 rdf:rest ?inst }.\n        } UNION {\n            ?listHead rdf:rest* ?inst.\n            FILTER NOT EXISTS { _:smth4 rdf:rest ?listHead }.\n\n            ?listHead rdf:rest*/rdf:first ?blankTrg.\n            ?blankSrc ?blankSrcProp ?listHead.\n            _:smth5 rdf:first ?blankTrg.\n            BIND(?blankSrcProp as ?blankTrgProp)\n            BIND(\"listHead\" as ?blankType)\n        }\n    }\n";
function isEncodedBlank(id) {
    return id.startsWith(exports.ENCODED_PREFIX);
}
exports.isEncodedBlank = isEncodedBlank;
var QueryExecutor = /** @class */ (function () {
    function QueryExecutor(queryFunction) {
        this.queryFunction = queryFunction;
        this.queryDictionary = {};
    }
    QueryExecutor.prototype.executeQuery = function (query) {
        var _this = this;
        var execution = this.queryDictionary[query];
        if (execution) {
            return execution;
        }
        else {
            this.queryDictionary[query] = this.queryFunction(query).then(function (response) {
                delete _this.queryDictionary[query];
                return response;
            });
            return this.queryDictionary[query];
        }
    };
    return QueryExecutor;
}());
exports.QueryExecutor = QueryExecutor;
function updateFilterResults(result, queryFunction, settings) {
    var completeBindings = [];
    var blankBindings = [];
    for (var _i = 0, _a = result.results.bindings; _i < _a.length; _i++) {
        var binding = _a[_i];
        if ((0, sparqlModels_1.isBlankBinding)(binding)) {
            blankBindings.push(binding);
        }
        else {
            completeBindings.push(binding);
        }
    }
    return processBlankBindings(blankBindings, function (callBackQuery) {
        return queryFunction(callBackQuery);
    }, settings).then(function (processedBindings) {
        result.results.bindings = completeBindings.concat(processedBindings);
        return result;
    });
}
exports.updateFilterResults = updateFilterResults;
function processBlankBindings(blankBindings, queryFunction, settings) {
    var bindingGroupsById = {};
    for (var _i = 0, blankBindings_1 = blankBindings; _i < blankBindings_1.length; _i++) {
        var binding = blankBindings_1[_i];
        if (binding.newInst) {
            binding.inst = binding.newInst;
        }
        if (!bindingGroupsById[binding.inst.value]) {
            bindingGroupsById[binding.inst.value] = [];
        }
        bindingGroupsById[binding.inst.value].push(binding);
    }
    var relatedBlankBindnings = [];
    for (var _a = 0, blankBindings_2 = blankBindings; _a < blankBindings_2.length; _a++) {
        var b = blankBindings_2[_a];
        if ((0, sparqlModels_1.isRdfBlank)(b.blankTrg)) {
            relatedBlankBindnings.push([b]);
        }
    }
    var queryExecutor = new QueryExecutor(queryFunction);
    return loadRelatedBlankNodes(relatedBlankBindnings, queryExecutor, settings).then(function (loadedGroupsById) {
        var idsMap = getEncodedIdDictionary(loadedGroupsById);
        var groups = Object.keys(bindingGroupsById).map(function (key) { return bindingGroupsById[key]; });
        for (var _i = 0, groups_1 = groups; _i < groups_1.length; _i++) {
            var group = groups_1[_i];
            for (var _a = 0, group_1 = group; _a < group_1.length; _a++) {
                var blankBinding = group_1[_a];
                if (!blankBinding.label) {
                    blankBinding.label = createLabelForBlankBinding(blankBinding);
                }
                var encodedId4LoadedElement = idsMap[blankBinding.blankTrg.value];
                if (encodedId4LoadedElement) {
                    blankBinding.blankTrg.value = encodedId4LoadedElement;
                }
            }
            var encodedId = encodeId(group);
            updateGroupIds(group, encodedId);
        }
        return blankBindings;
    });
}
exports.processBlankBindings = processBlankBindings;
function getEncodedIdDictionary(blankBindingGroups) {
    var idDictionary = {};
    var keys = Object.keys(blankBindingGroups);
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        idDictionary[key] = encodeId(blankBindingGroups[key]);
        updateGroupIds(blankBindingGroups[key], idDictionary[key]);
    }
    return idDictionary;
}
function updateGroupIds(group, newId) {
    for (var _i = 0, group_2 = group; _i < group_2.length; _i++) {
        var loadedBlankBinding = group_2[_i];
        loadedBlankBinding.inst.value = newId;
    }
}
function encodeId(blankBindings) {
    var bindingSet = {};
    for (var _i = 0, blankBindings_3 = blankBindings; _i < blankBindings_3.length; _i++) {
        var binding = blankBindings_3[_i];
        // leave out instance unique ID
        var inst = binding.inst, exceptInst = tslib_1.__rest(binding, ["inst"]);
        var encodedBinding = JSON.stringify(exceptInst);
        bindingSet[encodedBinding] = exceptInst;
    }
    var normalizedBindings = Object.keys(bindingSet)
        .sort()
        .map(function (key) { return bindingSet[key]; });
    return exports.ENCODED_PREFIX + encodeURI(JSON.stringify(normalizedBindings));
}
exports.encodeId = encodeId;
function decodeId(id) {
    if (!isEncodedBlank(id)) {
        return undefined;
    }
    try {
        var clearId = id.substring(exports.ENCODED_PREFIX.length, id.length);
        var parsedBindings = JSON.parse(decodeURI(clearId));
        var bindings = parsedBindings.map(function (binding) {
            // restore instance unique ID
            binding.inst = { type: 'uri', value: id };
            return binding;
        });
        return bindings;
    }
    catch (error) {
        /* silent */
        return undefined;
    }
}
exports.decodeId = decodeId;
function createLabelForBlankBinding(bn) {
    if (bn.blankType.value === 'listHead') {
        return {
            type: 'literal',
            value: 'RDFList',
            'xml:lang': '',
        };
    }
    else {
        return {
            type: 'literal',
            value: bn.class
                ? (0, utils_1.getUriLocalName)(bn.class.value) || bn.class.value
                : 'anonymous',
            'xml:lang': '',
        };
    }
}
exports.createLabelForBlankBinding = createLabelForBlankBinding;
function loadRelatedBlankNodes(blankChains, queryExecutor, settings, recursionDeep) {
    recursionDeep = recursionDeep || 1;
    if (recursionDeep > exports.MAX_RECURSION_DEEP) {
        return Promise.resolve({});
    }
    var queryPairs = blankChains.map(function (chain) { return ({
        query: getQueryForChain(chain, settings),
        chain: chain,
    }); });
    var promises = queryPairs.map(function (pair) {
        return queryExecutor.executeQuery(pair.query).then(function (response) { return ({
            response: response,
            chain: pair.chain,
        }); });
    });
    return Promise.all(promises).then(function (results) {
        var recursionPromises = [];
        var loadedBlankBindings = {};
        var _loop_1 = function (result) {
            var bindings = result.response.results.bindings;
            if (bindings.length > 0) {
                var relatedBlankBindings = [];
                for (var _a = 0, bindings_1 = bindings; _a < bindings_1.length; _a++) {
                    var binding = bindings_1[_a];
                    if ((0, sparqlModels_1.isRdfBlank)(binding.blankTrg)) {
                        relatedBlankBindings.push(result.chain.concat([binding]));
                    }
                }
                recursionPromises.push(loadRelatedBlankNodes(relatedBlankBindings, queryExecutor, settings, recursionDeep + 1).then(function (loadedGroupsById) {
                    var idsMap = getEncodedIdDictionary(loadedGroupsById);
                    var mergedResults = {};
                    for (var _i = 0, bindings_2 = bindings; _i < bindings_2.length; _i++) {
                        var binding = bindings_2[_i];
                        binding.label = createLabelForBlankBinding(binding);
                        var encodedId = idsMap[binding.blankTrg.value];
                        if (encodedId) {
                            binding.blankTrg.value = encodedId;
                        }
                        if (!mergedResults[binding.inst.value]) {
                            mergedResults[binding.inst.value] = [];
                        }
                        mergedResults[binding.inst.value].push(binding);
                    }
                    Object.keys(mergedResults).forEach(function (key) {
                        var group = mergedResults[key];
                        var originalId = group[0].inst.value;
                        loadedBlankBindings[originalId] = group;
                    });
                    return true;
                }));
            }
        };
        for (var _i = 0, results_1 = results; _i < results_1.length; _i++) {
            var result = results_1[_i];
            _loop_1(result);
        }
        return Promise.all(recursionPromises).then(function () {
            return loadedBlankBindings;
        });
    });
}
function getQueryForChain(blankNodes, sparqlDataProviderSettings) {
    function getQueryBlock(blankNode, index, maxIndex) {
        // if blankNode has type 'listHead' then his target and targetProperty is artificial,
        // and we can't include this id in chain
        var trustableTrgProp = index === 0 || blankNode.blankType.value !== 'listHead';
        var sourceId = index > 0 ? '?inst' + (index - 1) : '<' + blankNode.blankSrc.value + '>';
        var sourcePropId = trustableTrgProp
            ? index > 0
                ? '?blankTrgProp' + (index - 1)
                : '<' + blankNode.blankSrcProp.value + '>'
            : '?anyType' + index;
        var instPostfix = index === maxIndex ? '' : index.toString();
        var targetPropId = trustableTrgProp
            ? '<' + blankNode.blankTrgProp.value + '>'
            : '?anyType0' + index;
        var firstRelation = index === 0 && blankNode.blankType.value === 'listHead'
            ? "\n            ?blankSrc".concat(index, " rdf:rest*/rdf:first ?inst").concat(instPostfix, ".\n            ")
            : "?blankSrc".concat(index, " ").concat(targetPropId, " ?inst").concat(instPostfix, ".");
        return "\n        # ======================\n        ".concat(sourceId, " ").concat(sourcePropId, " ?blankSrc").concat(index, ".\n        ").concat(firstRelation, "\n        BIND (<").concat(blankNode.blankTrgProp.value, "> as ?blankSrcProp").concat(index, ").\n        FILTER (ISBLANK(?inst").concat(instPostfix, ")).\n        {\n            ?inst").concat(instPostfix, " ?blankTrgProp").concat(instPostfix, " ?blankTrg").concat(instPostfix, ".\n            BIND(\"blankNode\" as ?blankType").concat(instPostfix, ").\n            FILTER NOT EXISTS { ?inst").concat(instPostfix, " rdf:first _:smth1").concat(index, " }.\n        } UNION {\n            ?inst").concat(instPostfix, " rdf:rest*/rdf:first ?blankTrg").concat(instPostfix, ".\n            ?blankSrc").concat(index, " ?blankSrcProp").concat(index, " ?inst").concat(instPostfix, ".\n            _:smth2").concat(index, " rdf:first ?blankTrg").concat(instPostfix, ".\n            BIND(?blankSrcProp").concat(index, " as ?blankTrgProp").concat(instPostfix, ")\n            BIND(\"listHead\" as ?blankType").concat(instPostfix, ")\n            FILTER NOT EXISTS { _:smth3").concat(index, " rdf:rest ?inst").concat(instPostfix, " }.\n        }\n        OPTIONAL {\n            ?inst").concat(instPostfix, " rdf:type ?class").concat(instPostfix, ".\n        }\n    ");
    }
    var body = blankNodes
        .map(function (bn, index) { return getQueryBlock(bn, index, blankNodes.length - 1); })
        .join('\n');
    var query = "".concat(sparqlDataProviderSettings.defaultPrefix, "\n    SELECT ?inst ?class ?label ?blankTrgProp ?blankTrg ?blankType\n        WHERE {\n           ").concat(body, "\n        }\n    ");
    return query;
}
function elementInfo(elementIds) {
    var ids = elementIds.filter(function (id) { return isEncodedBlank(id); });
    return {
        head: undefined,
        results: { bindings: getElementBindings(ids) },
    };
}
exports.elementInfo = elementInfo;
function linksInfo(elementIds) {
    return {
        head: undefined,
        results: { bindings: getLinkBinding(elementIds) },
    };
}
exports.linksInfo = linksInfo;
function linkTypesOf(params) {
    return {
        head: undefined,
        results: { bindings: getLinkCountBinding(params.elementId) },
    };
}
exports.linkTypesOf = linkTypesOf;
function filter(params) {
    var filterResponse = {
        head: undefined,
        results: { bindings: [] },
    };
    if (params.limit === 0) {
        params.limit = 100;
    }
    if (params.elementTypeId) {
        filterResponse.results.bindings = [];
    }
    else if (params.refElementId && params.refElementLinkId) {
        filterResponse.results.bindings = getAllRelatedByLinkTypeElements(params.refElementId, params.refElementLinkId, params.linkDirection);
    }
    else if (params.refElementId) {
        filterResponse.results.bindings = getAllRelatedElements(params.refElementId);
    }
    if (params.text && filterResponse.results.bindings.length !== 0) {
        filterResponse.results.bindings = filterResponse.results.bindings.filter(function (be) { return be.inst.value.toLowerCase().indexOf(params.text) !== -1; });
    }
    return filterResponse;
}
exports.filter = filter;
function getElementTypes(elementIds) {
    var bindings = [];
    for (var _i = 0, elementIds_1 = elementIds; _i < elementIds_1.length; _i++) {
        var id = elementIds_1[_i];
        var blankBindings = decodeId(id);
        if (blankBindings) {
            for (var _a = 0, blankBindings_4 = blankBindings; _a < blankBindings_4.length; _a++) {
                var be = blankBindings_4[_a];
                if ((0, sparqlModels_1.isRdfIri)(be.inst) && be.class) {
                    bindings.push({ inst: be.inst, class: be.class });
                }
            }
        }
    }
    return { head: undefined, results: { bindings: bindings } };
}
exports.getElementTypes = getElementTypes;
function getAllRelatedByLinkTypeElements(refElementId, refElementLinkId, linkDirection) {
    var blankElements = (decodeId(refElementId) || []).concat(decodeId(refElementLinkId) || []);
    var bindings = [];
    if (blankElements.length > 0) {
        for (var _i = 0, blankElements_1 = blankElements; _i < blankElements_1.length; _i++) {
            var be = blankElements_1[_i];
            if (linkDirection === 'in') {
                if (be.inst.value === refElementId &&
                    ((0, sparqlModels_1.isRdfIri)(be.blankSrc) || (0, sparqlModels_1.isRdfBlank)(be.blankSrc)) &&
                    refElementLinkId === be.blankSrcProp.value) {
                    if ((0, sparqlModels_1.isRdfIri)(be.blankSrc)) {
                        bindings.push({
                            inst: be.blankSrc,
                        });
                    }
                    else {
                        bindings = bindings.concat(decodeId(be.blankSrc.value) || [{ inst: be.blankSrc }]);
                    }
                }
                else if (be.blankTrg.value === refElementId &&
                    refElementLinkId === be.blankTrgProp.value) {
                    bindings.push(be);
                }
            }
            else {
                if (be.inst.value === refElementId &&
                    ((0, sparqlModels_1.isRdfIri)(be.blankTrg) || (0, sparqlModels_1.isRdfBlank)(be.blankTrg)) &&
                    refElementLinkId === be.blankTrgProp.value) {
                    if ((0, sparqlModels_1.isRdfIri)(be.blankTrg)) {
                        bindings.push({
                            inst: be.blankTrg,
                        });
                    }
                    else {
                        bindings = bindings.concat(decodeId(be.blankTrg.value) || [{ inst: be.blankTrg }]);
                    }
                }
                else if (be.blankSrc.value === refElementId &&
                    refElementLinkId === be.blankSrcProp.value) {
                    bindings.push(be);
                }
            }
        }
    }
    return bindings;
}
function getAllRelatedElements(id) {
    var blankElements = decodeId(id);
    var bindings = [];
    if (blankElements) {
        for (var _i = 0, blankElements_2 = blankElements; _i < blankElements_2.length; _i++) {
            var be = blankElements_2[_i];
            if (be.inst.value === id ||
                id === be.blankSrc.value ||
                id === be.blankTrg.value) {
                bindings.push(be);
                if ((0, sparqlModels_1.isRdfIri)(be.blankSrc)) {
                    bindings.push({ inst: be.blankSrc });
                }
                else if ((0, sparqlModels_1.isRdfBlank)(be.blankSrc)) {
                    bindings = bindings.concat(decodeId(be.blankSrc.value) || [{ inst: be.blankSrc }]);
                }
                if ((0, sparqlModels_1.isRdfIri)(be.blankTrg)) {
                    bindings.push({ inst: be.blankTrg });
                }
                else if ((0, sparqlModels_1.isRdfBlank)(be.blankTrg)) {
                    bindings = bindings.concat(decodeId(be.blankTrg.value) || [{ inst: be.blankTrg }]);
                }
            }
        }
    }
    return bindings;
}
function getElementBindings(ids) {
    var blankElements = [];
    for (var _i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
        var id = ids_1[_i];
        var blankBindings = decodeId(id);
        if (blankBindings) {
            blankElements = blankElements.concat(decodeId(id));
        }
    }
    return blankElements.filter(function (be) {
        return ids.indexOf(be.inst.value) !== -1;
    });
}
function getLinkBinding(ids) {
    var blankElements = [];
    for (var _i = 0, ids_2 = ids; _i < ids_2.length; _i++) {
        var id = ids_2[_i];
        var blankBindings = decodeId(id);
        if (blankBindings) {
            blankElements = blankElements.concat(decodeId(id));
        }
    }
    var bindings = [];
    for (var _a = 0, blankElements_3 = blankElements; _a < blankElements_3.length; _a++) {
        var be = blankElements_3[_a];
        if (ids.indexOf(be.inst.value) !== -1) {
            if (((0, sparqlModels_1.isRdfIri)(be.blankSrc) || (0, sparqlModels_1.isRdfBlank)(be.blankSrc)) &&
                (0, sparqlModels_1.isRdfIri)(be.blankSrcProp) &&
                ids.indexOf(be.blankSrc.value) !== -1) {
                bindings.push({
                    source: be.blankSrc,
                    type: be.blankSrcProp,
                    target: be.inst,
                });
            }
            if (((0, sparqlModels_1.isRdfIri)(be.blankTrg) || (0, sparqlModels_1.isRdfBlank)(be.blankTrg)) &&
                (0, sparqlModels_1.isRdfIri)(be.blankTrgProp) &&
                ids.indexOf(be.blankTrg.value) !== -1) {
                bindings.push({
                    source: be.inst,
                    type: be.blankTrgProp,
                    target: be.blankTrg,
                });
            }
        }
    }
    return bindings;
}
function getLinkCountBinding(id) {
    var blankElements = decodeId(id);
    var bindings = [];
    var dictionary = {};
    for (var _i = 0, blankElements_4 = blankElements; _i < blankElements_4.length; _i++) {
        var be = blankElements_4[_i];
        if (id === be.inst.value) {
            if (((0, sparqlModels_1.isRdfIri)(be.blankTrg) || (0, sparqlModels_1.isRdfBlank)(be.blankTrg)) &&
                (0, sparqlModels_1.isRdfIri)(be.blankTrgProp)) {
                if (((0, sparqlModels_1.isRdfIri)(be.blankSrc) || (0, sparqlModels_1.isRdfBlank)(be.blankSrc)) &&
                    (0, sparqlModels_1.isRdfIri)(be.blankSrcProp)) {
                    if (!dictionary[be.blankSrcProp.value]) {
                        dictionary[be.blankSrcProp.value] = {
                            link: be.blankSrcProp,
                            inCount: {
                                type: 'literal',
                                value: '1',
                                'xml:lang': '',
                            },
                            outCount: {
                                type: 'literal',
                                value: '0',
                                'xml:lang': '',
                            },
                        };
                    }
                }
                if (!dictionary[be.blankTrgProp.value]) {
                    dictionary[be.blankTrgProp.value] = {
                        link: be.blankTrgProp,
                        inCount: {
                            type: 'literal',
                            value: '0',
                            'xml:lang': '',
                        },
                        outCount: {
                            type: 'literal',
                            value: '1',
                            'xml:lang': '',
                        },
                    };
                }
                else {
                    dictionary[be.blankTrgProp.value].outCount.value = (+dictionary[be.blankTrgProp.value].outCount.value + 1).toString();
                }
            }
        }
    }
    return Object.keys(dictionary).map(function (k) { return dictionary[k]; });
}
