"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultElementTemplateBundle = void 0;
var tslib_1 = require("tslib");
tslib_1.__exportStar(require("./default"), exports);
tslib_1.__exportStar(require("./group"), exports);
tslib_1.__exportStar(require("./standard"), exports);
var DefaultElementTemplateBundle = function (types) {
    return undefined;
};
exports.DefaultElementTemplateBundle = DefaultElementTemplateBundle;
