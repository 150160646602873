"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataURLToBlob = exports.fitRectKeepingAspectRatio = exports.loadImage = exports.toDataURL = exports.toSVG = void 0;
var tslib_1 = require("tslib");
var lodash_1 = require("lodash");
var geometry_1 = require("../diagram/geometry");
var canvg = require('canvg-fixed');
var SVG_NAMESPACE = 'http://www.w3.org/2000/svg';
/**
 * Padding (in px) for <foreignObject> elements of exported SVG to
 * mitigate issues with elements body overflow caused by missing styles
 * in exported image.
 */
var FOREIGN_OBJECT_SIZE_PADDING = 2;
var BORDER_PADDING = 100;
function toSVG(options) {
    return exportSVG(options).then(function (svg) {
        return new XMLSerializer().serializeToString(svg);
    });
}
exports.toSVG = toSVG;
function exportSVG(options) {
    var bbox = options.contentBox, watermarkSvg = options.watermarkSvg;
    var _a = clonePaperSvg(options, FOREIGN_OBJECT_SIZE_PADDING), svgClone = _a.svgClone, imageBounds = _a.imageBounds;
    var paddedWidth = bbox.width + 2 * BORDER_PADDING;
    var paddedHeight = bbox.height + 2 * BORDER_PADDING;
    if (options.preserveDimensions) {
        svgClone.setAttribute('width', paddedWidth.toString());
        svgClone.setAttribute('height', paddedHeight.toString());
    }
    else {
        svgClone.setAttribute('width', '100%');
        svgClone.setAttribute('height', '100%');
    }
    var viewBox = {
        x: bbox.x - BORDER_PADDING,
        y: bbox.y - BORDER_PADDING,
        width: paddedWidth,
        height: paddedHeight,
    };
    svgClone.setAttribute('viewBox', "".concat(viewBox.x, " ").concat(viewBox.y, " ").concat(viewBox.width, " ").concat(viewBox.height));
    addWatermark(svgClone, viewBox, watermarkSvg);
    var images = [];
    var nodes = svgClone.querySelectorAll('img');
    foreachNode(nodes, function (node) { return images.push(node); });
    var convertingImages = Promise.all(images.map(function (img) {
        var exportKey = img.getAttribute('export-key');
        img.removeAttribute('export-key');
        if (exportKey) {
            var _a = imageBounds[exportKey], width = _a.width, height = _a.height;
            img.setAttribute('width', width.toString());
            img.setAttribute('height', height.toString());
            if (!options.convertImagesToDataUris) {
                return Promise.resolve();
            }
            return exportAsDataUri(img)
                .then(function (dataUri) {
                // check for empty svg data URI which happens when mockJointXHR catches an exception
                if (dataUri && dataUri !== 'data:image/svg+xml,') {
                    img.src = dataUri;
                }
            })
                .catch(function (err) {
                // tslint:disable-next-line:no-console
                console.warn('Failed to export image: ' + img.src, err);
            });
        }
        else {
            return Promise.resolve();
        }
    }));
    return convertingImages.then(function () {
        // workaround to include only graph-explorer-related stylesheets
        var exportedCssText = extractCSSFromDocument(svgClone);
        var defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
        defs.innerHTML = "<style>".concat(exportedCssText, "</style>");
        svgClone.insertBefore(defs, svgClone.firstChild);
        if (options.elementsToRemoveSelector) {
            foreachNode(svgClone.querySelectorAll(options.elementsToRemoveSelector), function (node) { return node.remove(); });
        }
        return svgClone;
    });
}
function addWatermark(svg, viewBox, watermarkSvg) {
    var WATERMARK_CLASS = 'graph-explorer-exported-watermark';
    var WATERMARK_MAX_WIDTH = 120;
    var WATERMARK_PADDING = 20;
    var image = document.createElementNS(SVG_NAMESPACE, 'image');
    image.setAttributeNS('http://www.w3.org/1999/xlink', 'href', watermarkSvg);
    image.setAttribute('class', WATERMARK_CLASS);
    var width = Math.min(viewBox.width * 0.2, WATERMARK_MAX_WIDTH);
    var x = viewBox.x + viewBox.width - width - WATERMARK_PADDING;
    var y = viewBox.y + WATERMARK_PADDING;
    image.setAttribute('x', x.toString());
    image.setAttribute('y', y.toString());
    image.setAttribute('width', width.toString());
    image.setAttribute('opacity', '0.3');
    svg.insertBefore(image, svg.firstChild);
}
function clearAttributes(svg) {
    var availableIds = {};
    var prohibitedIds = {};
    var defss = svg.querySelectorAll('defs');
    foreachNode(defss, function (defs) {
        foreachNode(defs.childNodes, function (def) {
            var id = def.getAttribute('id');
            if (id) {
                availableIds[id] = true;
            }
        });
    });
    var paths = svg.querySelectorAll('*');
    foreachNode(paths, function (path) {
        var markerStart = extractId(path.getAttribute('marker-start'));
        if (markerStart && !availableIds[markerStart]) {
            path.removeAttribute('marker-start');
        }
        var markerEnd = extractId(path.getAttribute('marker-end'));
        if (markerEnd && !availableIds[markerEnd]) {
            path.removeAttribute('marker-end');
        }
        var filterId = extractId(path.getAttribute('filter'));
        if (filterId && !availableIds[filterId]) {
            path.removeAttribute('filter');
        }
    });
    function extractId(attributeValue) {
        if (attributeValue) {
            return (attributeValue.match(/#(.*?)\)/) || [])[1];
        }
        return undefined;
    }
}
function extractCSSFromDocument(targetSubtree) {
    var exportedRules = new Set();
    for (var i = 0; i < document.styleSheets.length; i++) {
        var rules = void 0;
        try {
            var cssSheet = document.styleSheets[i];
            rules = cssSheet.cssRules || cssSheet.rules;
            if (!rules) {
                continue;
            }
        }
        catch (e) {
            continue;
        }
        for (var j = 0; j < rules.length; j++) {
            var rule = rules[j];
            if (rule instanceof CSSStyleRule) {
                if (targetSubtree.querySelector(rule.selectorText)) {
                    exportedRules.add(rule);
                }
            }
        }
    }
    var exportedCssTexts = [];
    exportedRules.forEach(function (rule) { return exportedCssTexts.push(rule.cssText); });
    return exportedCssTexts.join('\n');
}
function clonePaperSvg(options, elementSizePadding) {
    var model = options.model, paper = options.paper, getOverlayedElement = options.getOverlayedElement;
    var svgClone = paper.cloneNode(true);
    svgClone.removeAttribute('class');
    svgClone.removeAttribute('style');
    function findViewport() {
        var child = svgClone.firstChild;
        while (child) {
            if (child instanceof SVGGElement) {
                return child;
            }
            child = child.nextSibling;
        }
        return undefined;
    }
    var viewport = findViewport();
    viewport.removeAttribute('transform');
    var imageBounds = {};
    var _loop_1 = function (element) {
        var modelId = element.id;
        var overlayedView = getOverlayedElement(modelId);
        if (!overlayedView) {
            return "continue";
        }
        var elementRoot = document.createElementNS(SVG_NAMESPACE, 'g');
        var overlayedViewContent = overlayedView.firstChild.cloneNode(true);
        elementRoot.setAttribute('class', 'graph-explorer-exported-element');
        var newRoot = document.createElementNS(SVG_NAMESPACE, 'foreignObject');
        newRoot.appendChild(overlayedViewContent);
        var _b = (0, geometry_1.boundsOf)(element), x = _b.x, y = _b.y, width = _b.width, height = _b.height;
        newRoot.setAttribute('transform', "translate(".concat(x, ",").concat(y, ")"));
        newRoot.setAttribute('width', (width + elementSizePadding).toString());
        newRoot.setAttribute('height', (height + elementSizePadding).toString());
        elementRoot.appendChild(newRoot);
        viewport.appendChild(elementRoot);
        var clonedNodes = overlayedViewContent.querySelectorAll('img');
        foreachNode(overlayedView.querySelectorAll('img'), function (img, index) {
            var exportKey = (0, lodash_1.uniqueId)('export-key-');
            clonedNodes[index].setAttribute('export-key', exportKey);
            imageBounds[exportKey] = {
                width: img.clientWidth,
                height: img.clientHeight,
            };
        });
    };
    for (var _i = 0, _a = model.elements; _i < _a.length; _i++) {
        var element = _a[_i];
        _loop_1(element);
    }
    return { svgClone: svgClone, imageBounds: imageBounds };
}
function exportAsDataUri(original) {
    var url = original.src;
    if (!url || url.startsWith('data:')) {
        return Promise.resolve(url);
    }
    return loadCrossOriginImage(original.src).then(function (image) {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);
        // match extensions like htttp://example.com/images/foo.JPG&w=200
        var extensionMatch = url.match(/\.([a-zA-Z0-9]+)[^.a-zA-Z0-9]?[^.]*$/);
        var extension = extensionMatch ? extensionMatch[1].toLowerCase() : 'png';
        try {
            var mimeType = 'image/' + (extension === 'jpg' ? 'jpeg' : extension);
            var dataUri = canvas.toDataURL(mimeType);
            return Promise.resolve(dataUri);
        }
        catch (e) {
            if (extension !== 'svg') {
                return Promise.reject('Failed to convert image to data URI');
            }
            return fetch(url)
                .then(function (response) { return response.text(); })
                .then(function (svg) {
                return svg.length > 0 ? 'data:image/svg+xml,' + encodeURIComponent(svg) : '';
            });
        }
    });
}
function loadCrossOriginImage(src) {
    var image = new Image();
    image.crossOrigin = 'anonymous';
    var promise = new Promise(function (resolve, reject) {
        image.onload = function () { return resolve(image); };
        image.onerror = function (ev) { return reject(ev); };
    });
    image.src = src;
    return promise;
}
function foreachNode(nodeList, callback) {
    for (var i = 0; i < nodeList.length; i++) {
        callback(nodeList[i], i);
    }
}
var MAX_CANVAS_LENGTH = 4096;
function toDataURL(options) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        function createCanvas(canvasWidth, canvasHeight, backgroundColor) {
            var cnv = document.createElement('canvas');
            cnv.width = canvasWidth;
            cnv.height = canvasHeight;
            var cnt = cnv.getContext('2d');
            if (backgroundColor) {
                cnt.fillStyle = backgroundColor;
                cnt.fillRect(0, 0, canvasWidth, canvasHeight);
            }
            return { canvas: cnv, context: cnt };
        }
        var paper, _a, mimeType, svgOptions, svg, svgBox, containerSize, _b, innerSize, outerSize, offset, svgString, _c, canvas, context, image;
        return tslib_1.__generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    paper = options.paper, _a = options.mimeType, mimeType = _a === void 0 ? 'image/png' : _a;
                    svgOptions = tslib_1.__assign(tslib_1.__assign({}, options), { convertImagesToDataUris: true, mockImages: false, preserveDimensions: true });
                    return [4 /*yield*/, exportSVG(svgOptions)];
                case 1:
                    svg = _d.sent();
                    svgBox = {
                        width: Number(svg.getAttribute('width')),
                        height: Number(svg.getAttribute('height')),
                    };
                    containerSize = typeof options.width === 'number' || typeof options.height === 'number'
                        ? { width: options.width, height: options.height }
                        : fallbackContainerSize(svgBox);
                    _b = computeAutofit(svgBox, containerSize), innerSize = _b.innerSize, outerSize = _b.outerSize, offset = _b.offset;
                    svg.setAttribute('width', innerSize.width.toString());
                    svg.setAttribute('height', innerSize.height.toString());
                    svgString = new XMLSerializer().serializeToString(svg);
                    _c = createCanvas(outerSize.width, outerSize.height, options.backgroundColor), canvas = _c.canvas, context = _c.context;
                    return [4 /*yield*/, loadImage('data:image/svg+xml,' + encodeURIComponent(svgString))];
                case 2:
                    image = _d.sent();
                    context.drawImage(image, offset.x, offset.y, innerSize.width, innerSize.height);
                    return [2 /*return*/, canvas.toDataURL(mimeType, options.quality)];
            }
        });
    });
}
exports.toDataURL = toDataURL;
function loadImage(source) {
    return new Promise(function (resolve, reject) {
        var image = new Image();
        image.onload = function () {
            resolve(image);
        };
        image.onerror = function (ev) {
            reject(ev);
        };
        image.src = source;
    });
}
exports.loadImage = loadImage;
function computeAutofit(itemSize, containerSize) {
    var fit = fitRectKeepingAspectRatio(itemSize.width, itemSize.height, containerSize.width, containerSize.height);
    var innerSize = {
        width: Math.floor(fit.width),
        height: Math.floor(fit.height),
    };
    var outerSize = {
        width: typeof containerSize.width === 'number'
            ? containerSize.width
            : innerSize.width,
        height: typeof containerSize.height === 'number'
            ? containerSize.height
            : innerSize.height,
    };
    var offset = {
        x: Math.round((outerSize.width - innerSize.width) / 2),
        y: Math.round((outerSize.height - innerSize.height) / 2),
    };
    return { innerSize: innerSize, outerSize: outerSize, offset: offset };
}
function fallbackContainerSize(itemSize) {
    var maxResolutionScale = Math.min(MAX_CANVAS_LENGTH / itemSize.width, MAX_CANVAS_LENGTH / itemSize.height);
    var resolutionScale = Math.min(2.0, maxResolutionScale);
    var width = Math.floor(itemSize.width * resolutionScale);
    var height = Math.floor(itemSize.height * resolutionScale);
    return { width: width, height: height };
}
function fitRectKeepingAspectRatio(sourceWidth, sourceHeight, targetWidth, targetHeight) {
    if (!(typeof targetWidth === 'number' || typeof targetHeight === 'number')) {
        return { width: sourceWidth, height: sourceHeight };
    }
    var sourceAspectRatio = sourceWidth / sourceHeight;
    targetWidth =
        typeof targetWidth === 'number'
            ? targetWidth
            : targetHeight * sourceAspectRatio;
    targetHeight =
        typeof targetHeight === 'number'
            ? targetHeight
            : targetWidth / sourceAspectRatio;
    if (targetHeight * sourceAspectRatio <= targetWidth) {
        return { width: targetHeight * sourceAspectRatio, height: targetHeight };
    }
    else {
        return { width: targetWidth, height: targetWidth / sourceAspectRatio };
    }
}
exports.fitRectKeepingAspectRatio = fitRectKeepingAspectRatio;
/**
 * Creates and returns a blob from a data URL (either base64 encoded or not).
 *
 * @param {string} dataURL The data URL to convert.
 * @return {Blob} A blob representing the array buffer data.
 */
function dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        var parts = dataURL.split(',');
        var contentType = parts[0].split(':')[1];
        var raw = decodeURIComponent(parts[1]);
        return new Blob([raw], { type: contentType });
    }
    else {
        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
        var uInt8Array = new Uint8Array(rawLength);
        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    }
}
exports.dataURLToBlob = dataURLToBlob;
