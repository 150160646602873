"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRequest = exports.InstancesSearch = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var events_1 = require("../viewUtils/events");
var progressBar_1 = require("./progressBar");
var searchResults_1 = require("./searchResults");
var workspaceContext_1 = require("../workspace/workspaceContext");
var DirectionInImage = require('../../../images/direction-in.png');
var DirectionOutImage = require('../../../images/direction-out.png');
var CLASS_NAME = 'graph-explorer-instances-search';
var InstancesSearch = /** @class */ (function (_super) {
    tslib_1.__extends(InstancesSearch, _super);
    function InstancesSearch(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.listener = new events_1.EventObserver();
        _this.onSelectionChanged = function (newSelection) {
            _this.setState({ selection: newSelection });
        };
        _this.state = {
            resultId: 0,
            selection: new Set(),
        };
        return _this;
    }
    InstancesSearch.prototype.render = function () {
        var _this = this;
        var ENTER_KEY_CODE = 13;
        var className = "".concat(CLASS_NAME, " ").concat(this.props.className || '');
        var progressState = this.state.quering
            ? progressBar_1.ProgressState.loading
            : this.state.error
                ? progressBar_1.ProgressState.error
                : this.state.items
                    ? progressBar_1.ProgressState.completed
                    : progressBar_1.ProgressState.none;
        var searchTerm = this.state.inputText === undefined
            ? this.props.criteria.text
            : this.state.inputText;
        return (React.createElement("div", { className: className },
            React.createElement(progressBar_1.ProgressBar, { state: progressState }),
            React.createElement("div", { className: "".concat(CLASS_NAME, "__criteria") },
                this.renderCriteria(),
                React.createElement("div", { className: "".concat(CLASS_NAME, "__text-criteria graph-explorer-input-group") },
                    React.createElement("input", { type: "text", className: "graph-explorer-form-control", placeholder: "Search for...", value: searchTerm || '', onChange: function (e) {
                            return _this.setState({ inputText: e.currentTarget.value });
                        }, onKeyUp: function (e) {
                            if (e.keyCode === ENTER_KEY_CODE) {
                                _this.submitCriteriaUpdate();
                            }
                        } }),
                    React.createElement("span", { className: "graph-explorer-input-group-btn" },
                        React.createElement("button", { className: "graph-explorer-btn graph-explorer-btn-default", type: "button", title: "Search", onClick: function () { return _this.submitCriteriaUpdate(); } },
                            React.createElement("span", { className: "fa fa-search", "aria-hidden": "true" }))))),
            React.createElement("div", { className: "".concat(CLASS_NAME, "__rest graph-explorer-scrollable"), key: this.state.resultId },
                React.createElement(searchResults_1.SearchResults, { view: this.props.view, items: this.state.items, highlightText: this.props.criteria.text, selection: this.state.selection, onSelectionChanged: this.onSelectionChanged }),
                React.createElement("div", { className: "".concat(CLASS_NAME, "__rest-end") },
                    React.createElement("button", { type: "button", className: "".concat(CLASS_NAME, "__load-more graph-explorer-btn graph-explorer-btn-primary"), disabled: this.state.quering, style: {
                            display: this.state.moreItemsAvailable ? undefined : 'none',
                        }, onClick: function () { return _this.queryItems(true); } },
                        React.createElement("span", { className: "fa fa-chevron-down", "aria-hidden": "true" }),
                        "\u00A0Show more")))));
    };
    InstancesSearch.prototype.renderCriteria = function () {
        var _this = this;
        var _a = this.props, _b = _a.criteria, criteria = _b === void 0 ? {} : _b, view = _a.view;
        var criterions = [];
        if (criteria.elementType) {
            var classInfo = criteria.elementType;
            var classLabel = view.formatLabel(classInfo.label, classInfo.id);
            criterions.push(React.createElement("div", { key: "hasType", className: "".concat(CLASS_NAME, "__criterion") },
                this.renderRemoveCriterionButtons(function () {
                    return _this.props.onCriteriaChanged(tslib_1.__assign(tslib_1.__assign({}, _this.props.criteria), { elementType: undefined }));
                }),
                "Has type",
                ' ',
                React.createElement("span", { className: "".concat(CLASS_NAME, "__criterion-class"), title: classInfo.id }, classLabel)));
        }
        else if (criteria.refElement) {
            var element = criteria.refElement;
            var elementLabel = view.formatLabel(element.data.label.values, element.iri);
            var linkType = criteria.refElementLink;
            var linkTypeLabel = linkType
                ? view.formatLabel(linkType.label, linkType.id)
                : undefined;
            criterions.push(React.createElement("div", { key: "hasLinkedElement", className: "".concat(CLASS_NAME, "__criterion") },
                this.renderRemoveCriterionButtons(function () {
                    return _this.props.onCriteriaChanged(tslib_1.__assign(tslib_1.__assign({}, _this.props.criteria), { refElement: undefined, refElementLink: undefined }));
                }),
                "Connected to",
                ' ',
                React.createElement("span", { className: "".concat(CLASS_NAME, "__criterion-element"), title: element ? element.iri : undefined }, elementLabel),
                linkType && (React.createElement("span", null,
                    ' through ',
                    React.createElement("span", { className: "".concat(CLASS_NAME, "__criterion-link-type"), title: linkType ? linkType.id : undefined }, linkTypeLabel),
                    criteria.linkDirection === 'in' && (React.createElement("span", null,
                        ' as ',
                        React.createElement("img", { className: "".concat(CLASS_NAME, "__link-direction"), src: DirectionInImage }),
                        "\u00A0source")),
                    criteria.linkDirection === 'out' && (React.createElement("span", null,
                        ' as ',
                        React.createElement("img", { className: "".concat(CLASS_NAME, "__link-direction"), src: DirectionOutImage }),
                        "\u00A0target"))))));
        }
        return React.createElement("div", { className: "".concat(CLASS_NAME, "__criterions") }, criterions);
    };
    InstancesSearch.prototype.renderRemoveCriterionButtons = function (onClick) {
        return (React.createElement("div", { className: "".concat(CLASS_NAME, "__criterion-remove ontoidia-btn-group graph-explorer-btn-group-xs") },
            React.createElement("button", { type: "button", className: "graph-explorer-btn graph-explorer-btn-default", title: "Remove criteria", onClick: onClick },
                React.createElement("span", { className: "fa fa-times", "aria-hidden": "true" }))));
    };
    InstancesSearch.prototype.submitCriteriaUpdate = function () {
        var text = this.state.inputText === undefined
            ? this.props.criteria.text
            : this.state.inputText;
        text = text === '' ? undefined : text;
        this.props.onCriteriaChanged(tslib_1.__assign(tslib_1.__assign({}, this.props.criteria), { text: text }));
    };
    InstancesSearch.prototype.componentDidMount = function () {
        var _this = this;
        this.listener.listen(this.props.view.events, 'changeLanguage', function () {
            return _this.forceUpdate();
        });
        this.queryItems(false);
    };
    InstancesSearch.prototype.componentDidUpdate = function (nextProps) {
        var _this = this;
        var languageChanged = this.currentRequest
            ? this.currentRequest.languageCode !== nextProps.view.getLanguage()
            : false;
        if (this.props.criteria !== nextProps.criteria || languageChanged) {
            this.setState({ inputText: undefined }, function () { return _this.queryItems(false); });
        }
    };
    InstancesSearch.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.currentRequest = undefined;
    };
    InstancesSearch.prototype.queryItems = function (loadMoreItems) {
        var _this = this;
        var request;
        if (loadMoreItems) {
            if (!this.currentRequest) {
                throw new Error('Cannot request more items without initial request.');
            }
            var _a = this.currentRequest, offset = _a.offset, limit = _a.limit;
            request = tslib_1.__assign(tslib_1.__assign({}, this.currentRequest), { offset: offset + limit });
        }
        else {
            request = createRequest(this.props.criteria, this.props.view.getLanguage());
        }
        if (!(request.text ||
            request.elementTypeId ||
            request.refElementId ||
            request.refElementLinkId)) {
            this.setState({
                quering: false,
                error: undefined,
                items: undefined,
                selection: new Set(),
                moreItemsAvailable: false,
            });
            return;
        }
        this.currentRequest = request;
        this.setState({
            quering: true,
            error: undefined,
            moreItemsAvailable: false,
        });
        this.props.model.dataProvider
            .filter(request)
            .then(function (elements) {
            if (_this.currentRequest !== request) {
                return;
            }
            _this.processFilterData(elements);
            _this.context.workspace.triggerWorkspaceEvent(workspaceContext_1.WorkspaceEventKey.searchQueryItem);
        })
            .catch(function (error) {
            if (_this.currentRequest !== request) {
                return;
            }
            // tslint:disable-next-line:no-console
            console.error(error);
            _this.setState({ quering: false, error: error });
        });
    };
    InstancesSearch.prototype.processFilterData = function (elements) {
        var requestedAdditionalItems = this.currentRequest.offset > 0;
        var existingIris = {};
        if (requestedAdditionalItems) {
            this.state.items.forEach(function (item) { return (existingIris[item.id] = true); });
        }
        var items = requestedAdditionalItems ? tslib_1.__spreadArray([], this.state.items, true) : [];
        for (var iri in elements) {
            if (!elements.hasOwnProperty(iri)) {
                continue;
            }
            if (existingIris[iri]) {
                continue;
            }
            items.push(elements[iri]);
        }
        var moreItemsAvailable = Object.keys(elements).length >= this.currentRequest.limit;
        if (requestedAdditionalItems) {
            this.setState({
                quering: false,
                items: items,
                error: undefined,
                moreItemsAvailable: moreItemsAvailable,
            });
        }
        else {
            this.setState({
                quering: false,
                resultId: this.state.resultId + 1,
                items: items,
                selection: new Set(),
                error: undefined,
                moreItemsAvailable: moreItemsAvailable,
            });
        }
    };
    InstancesSearch.contextTypes = workspaceContext_1.WorkspaceContextTypes;
    return InstancesSearch;
}(React.Component));
exports.InstancesSearch = InstancesSearch;
function createRequest(criteria, language) {
    var text = criteria.text, elementType = criteria.elementType, refElement = criteria.refElement, refElementLink = criteria.refElementLink, linkDirection = criteria.linkDirection;
    return {
        text: text,
        elementTypeId: elementType ? elementType.id : undefined,
        refElementId: refElement ? refElement.iri : undefined,
        refElementLinkId: refElementLink ? refElementLink.id : undefined,
        linkDirection: linkDirection,
        offset: 0,
        limit: 100,
        languageCode: language || 'en',
    };
}
exports.createRequest = createRequest;
