"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.executeSparqlConstruct = exports.executeSparqlQuery = exports.SparqlDataProvider = exports.SparqlQueryMethod = void 0;
var tslib_1 = require("tslib");
var collections_1 = require("../../viewUtils/collections");
var responseHandler_1 = require("./responseHandler");
var sparqlDataProviderSettings_1 = require("./sparqlDataProviderSettings");
var BlankNodes = require("./blankNodes");
var turtle_1 = require("./turtle");
var SparqlQueryMethod;
(function (SparqlQueryMethod) {
    SparqlQueryMethod[SparqlQueryMethod["GET"] = 1] = "GET";
    SparqlQueryMethod[SparqlQueryMethod["POST"] = 2] = "POST";
})(SparqlQueryMethod = exports.SparqlQueryMethod || (exports.SparqlQueryMethod = {}));
var SparqlDataProvider = /** @class */ (function () {
    function SparqlDataProvider(options, settings) {
        if (settings === void 0) { settings = sparqlDataProviderSettings_1.OWLStatsSettings; }
        this.linkByPredicate = new Map();
        this.linkById = new Map();
        this.propertyByPredicate = new Map();
        var _a = options.queryFunction, queryFunction = _a === void 0 ? queryInternal : _a;
        this.options = tslib_1.__assign(tslib_1.__assign({}, options), { queryFunction: queryFunction });
        this.settings = settings;
        for (var _i = 0, _b = settings.linkConfigurations; _i < _b.length; _i++) {
            var link = _b[_i];
            this.linkById.set(link.id, link);
            var predicate = (0, responseHandler_1.isDirectLink)(link) ? link.path : link.id;
            (0, collections_1.getOrCreateArrayInMap)(this.linkByPredicate, predicate).push(link);
        }
        this.openWorldLinks =
            settings.linkConfigurations.length === 0 ||
                Boolean(settings.openWorldLinks);
        for (var _c = 0, _d = settings.propertyConfigurations; _c < _d.length; _c++) {
            var property = _d[_c];
            var predicate = (0, responseHandler_1.isDirectProperty)(property)
                ? property.path
                : property.id;
            (0, collections_1.getOrCreateArrayInMap)(this.propertyByPredicate, predicate).push(property);
        }
        this.openWorldProperties =
            settings.propertyConfigurations.length === 0 ||
                Boolean(settings.openWorldProperties);
    }
    SparqlDataProvider.prototype.classTree = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, defaultPrefix, schemaLabelProperty, classTreeQuery, endpointUrl, cachedTree, query, result, classTree;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.settings, defaultPrefix = _a.defaultPrefix, schemaLabelProperty = _a.schemaLabelProperty, classTreeQuery = _a.classTreeQuery;
                        if (!classTreeQuery) {
                            return [2 /*return*/, []];
                        }
                        endpointUrl = this.options.endpointUrl;
                        cachedTree = localStorage.getItem("classTree#".concat(endpointUrl));
                        if (cachedTree) {
                            return [2 /*return*/, JSON.parse(cachedTree)];
                        }
                        query = defaultPrefix +
                            resolveTemplate(classTreeQuery, {
                                schemaLabelProperty: schemaLabelProperty,
                            });
                        return [4 /*yield*/, this.executeSparqlQuery(query)];
                    case 1:
                        result = _b.sent();
                        classTree = (0, responseHandler_1.getClassTree)(result);
                        if (!this.options.prepareLabels) return [3 /*break*/, 3];
                        return [4 /*yield*/, attachLabels((0, responseHandler_1.flattenClassTree)(classTree), this.options.prepareLabels)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        localStorage.setItem("classTree#".concat(endpointUrl), JSON.stringify(classTree));
                        return [2 /*return*/, classTree];
                }
            });
        });
    };
    SparqlDataProvider.prototype.propertyInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, defaultPrefix, schemaLabelProperty, propertyInfoQuery, properties, ids, query, result, _i, _b, id;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.settings, defaultPrefix = _a.defaultPrefix, schemaLabelProperty = _a.schemaLabelProperty, propertyInfoQuery = _a.propertyInfoQuery;
                        if (!propertyInfoQuery) return [3 /*break*/, 2];
                        ids = params.propertyIds
                            .map(escapeIri)
                            .map(function (id) { return " ( ".concat(id, " )"); })
                            .join(' ');
                        query = defaultPrefix +
                            resolveTemplate(propertyInfoQuery, {
                                ids: ids,
                                schemaLabelProperty: schemaLabelProperty,
                            });
                        return [4 /*yield*/, this.executeSparqlQuery(query)];
                    case 1:
                        result = _c.sent();
                        properties = (0, responseHandler_1.getPropertyInfo)(result);
                        return [3 /*break*/, 3];
                    case 2:
                        properties = {};
                        for (_i = 0, _b = params.propertyIds; _i < _b.length; _i++) {
                            id = _b[_i];
                            properties[id] = { id: id, label: { values: [] } };
                        }
                        _c.label = 3;
                    case 3:
                        if (!this.options.prepareLabels) return [3 /*break*/, 5];
                        return [4 /*yield*/, attachLabels((0, collections_1.objectValues)(properties), this.options.prepareLabels)];
                    case 4:
                        _c.sent();
                        _c.label = 5;
                    case 5: return [2 /*return*/, properties];
                }
            });
        });
    };
    SparqlDataProvider.prototype.classInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, defaultPrefix, schemaLabelProperty, classInfoQuery, classes, ids, query, result;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.settings, defaultPrefix = _a.defaultPrefix, schemaLabelProperty = _a.schemaLabelProperty, classInfoQuery = _a.classInfoQuery;
                        if (!classInfoQuery) return [3 /*break*/, 2];
                        ids = params.classIds
                            .map(escapeIri)
                            .map(function (id) { return " ( ".concat(id, " )"); })
                            .join(' ');
                        query = defaultPrefix +
                            resolveTemplate(classInfoQuery, {
                                ids: ids,
                                schemaLabelProperty: schemaLabelProperty,
                            });
                        return [4 /*yield*/, this.executeSparqlQuery(query)];
                    case 1:
                        result = _b.sent();
                        classes = (0, responseHandler_1.getClassInfo)(result);
                        return [3 /*break*/, 3];
                    case 2:
                        classes = params.classIds.map(function (id) { return ({ id: id, label: { values: [] }, children: [] }); });
                        _b.label = 3;
                    case 3:
                        if (!this.options.prepareLabels) return [3 /*break*/, 5];
                        return [4 /*yield*/, attachLabels(classes, this.options.prepareLabels)];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5: return [2 /*return*/, classes];
                }
            });
        });
    };
    SparqlDataProvider.prototype.linkTypesInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, defaultPrefix, schemaLabelProperty, linkTypesInfoQuery, linkTypes, ids, query, result;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.settings, defaultPrefix = _a.defaultPrefix, schemaLabelProperty = _a.schemaLabelProperty, linkTypesInfoQuery = _a.linkTypesInfoQuery;
                        if (!linkTypesInfoQuery) return [3 /*break*/, 2];
                        ids = params.linkTypeIds
                            .map(escapeIri)
                            .map(function (id) { return " ( ".concat(id, " )"); })
                            .join(' ');
                        query = defaultPrefix +
                            resolveTemplate(linkTypesInfoQuery, {
                                ids: ids,
                                schemaLabelProperty: schemaLabelProperty,
                            });
                        return [4 /*yield*/, this.executeSparqlQuery(query)];
                    case 1:
                        result = _b.sent();
                        linkTypes = (0, responseHandler_1.getLinkTypes)(result);
                        return [3 /*break*/, 3];
                    case 2:
                        linkTypes = params.linkTypeIds.map(function (id) { return ({ id: id, label: { values: [] } }); });
                        _b.label = 3;
                    case 3:
                        if (!this.options.prepareLabels) return [3 /*break*/, 5];
                        return [4 /*yield*/, attachLabels(linkTypes, this.options.prepareLabels)];
                    case 4:
                        _b.sent();
                        _b.label = 5;
                    case 5: return [2 /*return*/, linkTypes];
                }
            });
        });
    };
    SparqlDataProvider.prototype.linkTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, defaultPrefix, schemaLabelProperty, linkTypesQuery, linkTypesPattern, query, result, linkTypes;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.settings, defaultPrefix = _a.defaultPrefix, schemaLabelProperty = _a.schemaLabelProperty, linkTypesQuery = _a.linkTypesQuery, linkTypesPattern = _a.linkTypesPattern;
                        if (!linkTypesQuery) {
                            return [2 /*return*/, []];
                        }
                        query = defaultPrefix +
                            resolveTemplate(linkTypesQuery, {
                                linkTypesPattern: linkTypesPattern,
                                schemaLabelProperty: schemaLabelProperty,
                            });
                        return [4 /*yield*/, this.executeSparqlQuery(query)];
                    case 1:
                        result = _b.sent();
                        linkTypes = (0, responseHandler_1.getLinkTypes)(result);
                        if (!this.options.prepareLabels) return [3 /*break*/, 3];
                        return [4 /*yield*/, attachLabels(linkTypes, this.options.prepareLabels)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/, linkTypes];
                }
            });
        });
    };
    SparqlDataProvider.prototype.elementInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var nonBlankResources, blankNodeResponse, triples, ids, _a, defaultPrefix, dataLabelProperty, elementInfoQuery, query, types, bindings, bindingsWithBlanks, elementModels, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        nonBlankResources = params.elementIds.filter(function (id) { return !BlankNodes.isEncodedBlank(id); });
                        blankNodeResponse = this.options.acceptBlankNodes
                            ? BlankNodes.elementInfo(params.elementIds)
                            : undefined;
                        if (!(nonBlankResources.length > 0)) return [3 /*break*/, 2];
                        ids = nonBlankResources
                            .map(escapeIri)
                            .map(function (id) { return " (".concat(id, ")"); })
                            .join(' ');
                        _a = this.settings, defaultPrefix = _a.defaultPrefix, dataLabelProperty = _a.dataLabelProperty, elementInfoQuery = _a.elementInfoQuery;
                        query = defaultPrefix +
                            resolveTemplate(elementInfoQuery, {
                                ids: ids,
                                dataLabelProperty: dataLabelProperty,
                                propertyConfigurations: this.formatPropertyInfo(),
                            });
                        return [4 /*yield*/, this.executeSparqlConstruct(query)];
                    case 1:
                        triples = _d.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        triples = [];
                        _d.label = 3;
                    case 3:
                        types = this.queryManyElementTypes(this.settings.propertyConfigurations.length > 0 ? params.elementIds : []);
                        bindings = (0, responseHandler_1.triplesToElementBinding)(triples);
                        bindingsWithBlanks = (0, responseHandler_1.prependAdditionalBindings)(bindings, blankNodeResponse);
                        _b = responseHandler_1.getElementsInfo;
                        _c = [bindingsWithBlanks];
                        return [4 /*yield*/, types];
                    case 4:
                        elementModels = _b.apply(void 0, _c.concat([_d.sent(), this.propertyByPredicate,
                            this.openWorldProperties]));
                        if (!this.options.prepareLabels) return [3 /*break*/, 6];
                        return [4 /*yield*/, attachLabels((0, collections_1.objectValues)(elementModels), this.options.prepareLabels)];
                    case 5:
                        _d.sent();
                        _d.label = 6;
                    case 6:
                        if (!this.options.prepareImages) return [3 /*break*/, 8];
                        return [4 /*yield*/, prepareElementImages(this.options.prepareImages, elementModels)];
                    case 7:
                        _d.sent();
                        return [3 /*break*/, 10];
                    case 8:
                        if (!(this.options.imagePropertyUris &&
                            this.options.imagePropertyUris.length)) return [3 /*break*/, 10];
                        return [4 /*yield*/, this.attachImages(elementModels, this.options.imagePropertyUris)];
                    case 9:
                        _d.sent();
                        _d.label = 10;
                    case 10: return [2 /*return*/, elementModels];
                }
            });
        });
    };
    SparqlDataProvider.prototype.attachImages = function (elementsInfo, types) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ids, typesString, query, bindings, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        ids = Object.keys(elementsInfo)
                            .filter(function (id) { return !BlankNodes.isEncodedBlank(id); })
                            .map(escapeIri)
                            .map(function (id) { return " ( ".concat(id, " )"); })
                            .join(' ');
                        typesString = types
                            .map(escapeIri)
                            .map(function (id) { return " ( ".concat(id, " )"); })
                            .join(' ');
                        query = this.settings.defaultPrefix +
                            "\n            SELECT ?inst ?linkType ?image\n            WHERE {{\n                VALUES (?inst) {".concat(ids, "}\n                VALUES (?linkType) {").concat(typesString, "}\n                ").concat(this.settings.imageQueryPattern, "\n            }}\n        ");
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.executeSparqlQuery(query)];
                    case 2:
                        bindings = _a.sent();
                        (0, responseHandler_1.enrichElementsWithImages)(bindings, elementsInfo);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        // tslint:disable-next-line:no-console
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SparqlDataProvider.prototype.linksInfo = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var nonBlankResources, blankNodeResponse, linkConfigurations, bindings, types, ids, linksInfoQuery, bindingsWithBlanks, _a, linksInfo, _b, _c;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        nonBlankResources = params.elementIds.filter(function (id) { return !BlankNodes.isEncodedBlank(id); });
                        blankNodeResponse = this.options.acceptBlankNodes
                            ? BlankNodes.linksInfo(params.elementIds)
                            : undefined;
                        linkConfigurations = this.formatLinkLinks();
                        if (nonBlankResources.length > 0) {
                            ids = nonBlankResources
                                .map(escapeIri)
                                .map(function (id) { return " ( ".concat(id, " )"); })
                                .join(' ');
                            linksInfoQuery = this.settings.defaultPrefix +
                                resolveTemplate(this.settings.linksInfoQuery, {
                                    ids: ids,
                                    linkConfigurations: linkConfigurations,
                                });
                            bindings = this.executeSparqlQuery(linksInfoQuery);
                            types = this.queryManyElementTypes(params.elementIds);
                        }
                        else {
                            bindings = Promise.resolve({
                                head: { vars: [] },
                                results: { bindings: [] },
                            });
                            types = this.queryManyElementTypes([]);
                        }
                        _a = responseHandler_1.prependAdditionalBindings;
                        return [4 /*yield*/, bindings];
                    case 1:
                        bindingsWithBlanks = _a.apply(void 0, [_d.sent(), blankNodeResponse]);
                        _b = responseHandler_1.getLinksInfo;
                        _c = [bindingsWithBlanks];
                        return [4 /*yield*/, types];
                    case 2:
                        linksInfo = _b.apply(void 0, _c.concat([_d.sent(), this.linkByPredicate,
                            this.openWorldLinks]));
                        return [2 /*return*/, linksInfo];
                }
            });
        });
    };
    SparqlDataProvider.prototype.linkTypesOf = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, defaultPrefix, linkTypesOfQuery, linkTypesStatisticsQuery, filterTypePattern, elementIri, forAll, query, linkTypeBindings, linkTypeIds, navigateElementFilterOut, navigateElementFilterIn, foundLinkStats;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.options.acceptBlankNodes &&
                            BlankNodes.isEncodedBlank(params.elementId)) {
                            return [2 /*return*/, Promise.resolve((0, responseHandler_1.getLinksTypesOf)(BlankNodes.linkTypesOf(params)))];
                        }
                        _a = this.settings, defaultPrefix = _a.defaultPrefix, linkTypesOfQuery = _a.linkTypesOfQuery, linkTypesStatisticsQuery = _a.linkTypesStatisticsQuery, filterTypePattern = _a.filterTypePattern;
                        elementIri = escapeIri(params.elementId);
                        forAll = this.formatLinkUnion(params.elementId, undefined, undefined, '?outObject', '?inObject', false);
                        if (forAll.usePredicatePart) {
                            forAll.unionParts.push("{ ".concat(elementIri, " ?link ?outObject }"));
                            forAll.unionParts.push("{ ?inObject ?link ".concat(elementIri, " }"));
                        }
                        query = defaultPrefix +
                            resolveTemplate(linkTypesOfQuery, {
                                elementIri: elementIri,
                                linkConfigurations: forAll.unionParts.join('\nUNION\n'),
                            });
                        return [4 /*yield*/, this.executeSparqlQuery(query)];
                    case 1:
                        linkTypeBindings = _b.sent();
                        linkTypeIds = (0, responseHandler_1.getLinksTypeIds)(linkTypeBindings, this.linkByPredicate, this.openWorldLinks);
                        navigateElementFilterOut = this.options.acceptBlankNodes
                            ? "FILTER (IsIri(?outObject) || IsBlank(?outObject))"
                            : "FILTER IsIri(?outObject)";
                        navigateElementFilterIn = this.options.acceptBlankNodes
                            ? "FILTER (IsIri(?inObject) || IsBlank(?inObject))"
                            : "FILTER IsIri(?inObject)";
                        foundLinkStats = [];
                        return [4 /*yield*/, Promise.all(linkTypeIds.map(function (linkId) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var linkConfig, linkConfigurationOut, linkConfigurationIn, predicate, commaSeparatedDomains, restrictionOut, restrictionIn, statsQuery, bindings, linkStats;
                                var _a;
                                return tslib_1.__generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            linkConfig = this.linkById.get(linkId);
                                            if (!linkConfig || (0, responseHandler_1.isDirectLink)(linkConfig)) {
                                                predicate = escapeIri(linkConfig && (0, responseHandler_1.isDirectLink)(linkConfig) ? linkConfig.path : linkId);
                                                linkConfigurationOut = "".concat(elementIri, " ").concat(predicate, " ?outObject");
                                                linkConfigurationIn = "?inObject ".concat(predicate, " ").concat(elementIri);
                                            }
                                            else {
                                                linkConfigurationOut = this.formatLinkPath(linkConfig.path, elementIri, '?outObject');
                                                linkConfigurationIn = this.formatLinkPath(linkConfig.path, '?inObject', elementIri);
                                            }
                                            if (linkConfig && ((_a = linkConfig.domain) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                                                commaSeparatedDomains = linkConfig.domain
                                                    .map(escapeIri)
                                                    .join(', ');
                                                restrictionOut = filterTypePattern.replace(/[?$]inst\b/g, elementIri);
                                                restrictionIn = filterTypePattern.replace(/[?$]inst\b/g, '?inObject');
                                                linkConfigurationOut += " { ".concat(restrictionOut, " FILTER(?class IN (").concat(commaSeparatedDomains, ")) }");
                                                linkConfigurationIn += " { ".concat(restrictionIn, " FILTER(?class IN (").concat(commaSeparatedDomains, ")) }");
                                            }
                                            statsQuery = defaultPrefix +
                                                resolveTemplate(linkTypesStatisticsQuery, {
                                                    linkId: escapeIri(linkId),
                                                    elementIri: elementIri,
                                                    linkConfigurationOut: linkConfigurationOut,
                                                    linkConfigurationIn: linkConfigurationIn,
                                                    navigateElementFilterOut: navigateElementFilterOut,
                                                    navigateElementFilterIn: navigateElementFilterIn,
                                                });
                                            return [4 /*yield*/, this.executeSparqlQuery(statsQuery)];
                                        case 1:
                                            bindings = _b.sent();
                                            linkStats = (0, responseHandler_1.getLinkStatistics)(bindings);
                                            if (linkStats) {
                                                foundLinkStats.push(linkStats);
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 2:
                        _b.sent();
                        return [2 /*return*/, foundLinkStats];
                }
            });
        });
    };
    SparqlDataProvider.prototype.linkElements = function (params) {
        // for sparql we have rich filtering features and we just reuse filter.
        return this.filter({
            refElementId: params.elementId,
            refElementLinkId: params.linkId,
            linkDirection: params.direction,
            limit: params.limit,
            offset: params.offset,
            languageCode: '',
        });
    };
    SparqlDataProvider.prototype.filter = function (baseParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var params, types, blankFiltration, _a, _b, filterQuery, bindings, bindingsWithBlanks, elementModels, _c, _d;
            var _this = this;
            return tslib_1.__generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        params = tslib_1.__assign({}, baseParams);
                        if (params.limit === undefined) {
                            params.limit = 100;
                        }
                        types = this.querySingleElementTypes(params.refElementId && this.settings.linkConfigurations.length > 0
                            ? params.refElementId
                            : undefined);
                        blankFiltration = this.options.acceptBlankNodes
                            ? BlankNodes.filter(params)
                            : undefined;
                        if (!(blankFiltration && blankFiltration.results.bindings.length > 0)) return [3 /*break*/, 2];
                        _a = responseHandler_1.getFilteredData;
                        _b = [blankFiltration];
                        return [4 /*yield*/, types];
                    case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([_e.sent(), this.linkByPredicate,
                            this.openWorldLinks]))];
                    case 2:
                        filterQuery = this.createFilterQuery(params);
                        return [4 /*yield*/, this.executeSparqlQuery(filterQuery)];
                    case 3:
                        bindings = _e.sent();
                        if (!this.options.acceptBlankNodes) return [3 /*break*/, 5];
                        return [4 /*yield*/, BlankNodes.updateFilterResults(bindings, function (blankQuery) { return _this.executeSparqlQuery(blankQuery); }, this.settings)];
                    case 4:
                        bindingsWithBlanks = _e.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        bindingsWithBlanks = bindings;
                        _e.label = 6;
                    case 6:
                        _c = responseHandler_1.getFilteredData;
                        _d = [bindingsWithBlanks];
                        return [4 /*yield*/, types];
                    case 7:
                        elementModels = _c.apply(void 0, _d.concat([_e.sent(), this.linkByPredicate,
                            this.openWorldLinks]));
                        if (!this.options.prepareLabels) return [3 /*break*/, 9];
                        return [4 /*yield*/, attachLabels((0, collections_1.objectValues)(elementModels), this.options.prepareLabels)];
                    case 8:
                        _e.sent();
                        _e.label = 9;
                    case 9: return [2 /*return*/, elementModels];
                }
            });
        });
    };
    SparqlDataProvider.prototype.createFilterQuery = function (params) {
        if (!params.refElementId && params.refElementLinkId) {
            throw new Error('Cannot execute refElementLink filter without refElement');
        }
        var outerProjection = '?inst ?class ?label ?blankType';
        var innerProjection = '?inst';
        var refQueryPart = '';
        var refQueryTypes = '';
        if (params.refElementId) {
            outerProjection += ' ?link ?direction';
            innerProjection += ' ?link ?direction';
            refQueryPart = this.createRefQueryPart({
                elementId: params.refElementId,
                linkId: params.refElementLinkId,
                direction: params.linkDirection,
            });
            if (this.settings.linkConfigurations.length > 0) {
                outerProjection += ' ?classAll';
                refQueryTypes = this.settings.filterTypePattern.replace(/[?$]class\b/g, '?classAll');
            }
        }
        var elementTypePart = '';
        if (params.elementTypeId) {
            var elementTypeIri = escapeIri(params.elementTypeId);
            elementTypePart = this.settings.filterTypePattern.replace(/[?$]class\b/g, elementTypeIri);
            elementTypePart += ' .';
        }
        var _a = this.settings, defaultPrefix = _a.defaultPrefix, fullTextSearch = _a.fullTextSearch, dataLabelProperty = _a.dataLabelProperty;
        var textSearchPart = '';
        if (params.text) {
            innerProjection += ' ?score';
            textSearchPart += resolveTemplate(fullTextSearch.queryPattern, {
                text: params.text,
                dataLabelProperty: dataLabelProperty,
            });
            if (this.settings.fullTextSearch.extractLabel) {
                textSearchPart += sparqlExtractLabel('?inst', '?extractedLabel');
            }
        }
        var blankNodes = this.options.acceptBlankNodes;
        if (blankNodes) {
            outerProjection += " ".concat(BlankNodes.BLANK_NODE_QUERY_PARAMETERS);
        }
        return "".concat(defaultPrefix, "\n            ").concat(fullTextSearch.prefix, "\n\n        SELECT ").concat(outerProjection, "\n        WHERE {\n            {\n                SELECT DISTINCT ").concat(innerProjection, " WHERE {\n                    ").concat(fullTextSearch.elementFirst ? '' : textSearchPart, "\n                    ").concat(elementTypePart, "\n                    ").concat(refQueryPart, "\n                    ").concat(fullTextSearch.elementFirst ? textSearchPart : '', "\n                    ").concat(this.settings.filterAdditionalRestriction, "\n                }\n                ").concat(textSearchPart ? 'ORDER BY DESC(?score)' : '', "\n                LIMIT ").concat(params.limit, " OFFSET ").concat(params.offset, "\n            }\n            ").concat(refQueryTypes, "\n            ").concat(resolveTemplate(this.settings.filterElementInfoPattern, {
            dataLabelProperty: dataLabelProperty,
        }), "\n            ").concat(blankNodes ? BlankNodes.BLANK_NODE_QUERY : '', "\n        } ").concat(textSearchPart ? 'ORDER BY DESC(?score)' : '', "\n        ");
    };
    SparqlDataProvider.prototype.executeSparqlQuery = function (query) {
        var method = this.options.queryMethod
            ? this.options.queryMethod
            : SparqlQueryMethod.GET;
        return executeSparqlQuery(this.options.endpointUrl, query, method, this.options.queryFunction);
    };
    SparqlDataProvider.prototype.executeSparqlConstruct = function (query) {
        var method = this.options.queryMethod
            ? this.options.queryMethod
            : SparqlQueryMethod.GET;
        return executeSparqlConstruct(this.options.endpointUrl, query, method, this.options.queryFunction);
    };
    SparqlDataProvider.prototype.createRefQueryPart = function (params) {
        var elementId = params.elementId, linkId = params.linkId, direction = params.direction;
        var _a = this.formatLinkUnion(elementId, linkId, direction, '?inst', '?inst', true), unionParts = _a.unionParts, usePredicatePart = _a.usePredicatePart;
        if (usePredicatePart) {
            var refElementIRI = escapeIri(params.elementId);
            var refLinkType = void 0;
            if (linkId) {
                var link = this.linkById.get(linkId);
                refLinkType =
                    link && (0, responseHandler_1.isDirectLink)(link) ? escapeIri(link.path) : escapeIri(linkId);
            }
            var linkPattern = refLinkType || '?link';
            var bindType = refLinkType ? "BIND(".concat(refLinkType, " as ?link)") : '';
            // FILTER ISIRI is used to prevent blank nodes appearing in results
            var blankFilter = this.options.acceptBlankNodes
                ? 'FILTER(isIri(?inst) || isBlank(?inst))'
                : 'FILTER(isIri(?inst))';
            if (!direction || direction === 'out') {
                unionParts.push("{ ".concat(refElementIRI, " ").concat(linkPattern, " ?inst BIND(\"out\" as ?direction) ").concat(bindType, " ").concat(blankFilter, " }"));
            }
            if (!direction || direction === 'in') {
                unionParts.push("{ ?inst ".concat(linkPattern, " ").concat(refElementIRI, " BIND(\"in\" as ?direction) ").concat(bindType, " ").concat(blankFilter, " }"));
            }
        }
        var resultPattern = unionParts.length === 0 ? 'FILTER(false)' : unionParts.join("\nUNION\n");
        var useAllLinksPattern = !linkId && this.settings.filterRefElementLinkPattern.length > 0;
        if (useAllLinksPattern) {
            resultPattern += "\n".concat(this.settings.filterRefElementLinkPattern);
        }
        return resultPattern;
    };
    SparqlDataProvider.prototype.formatLinkUnion = function (refElementIri, linkIri, direction, outElementVar, inElementVar, bindDirection) {
        var linkConfigurations = this.settings.linkConfigurations;
        var fixedIri = escapeIri(refElementIri);
        var unionParts = [];
        var hasDirectLink = false;
        for (var _i = 0, linkConfigurations_1 = linkConfigurations; _i < linkConfigurations_1.length; _i++) {
            var link = linkConfigurations_1[_i];
            if (linkIri && link.id !== linkIri) {
                continue;
            }
            if ((0, responseHandler_1.isDirectLink)(link)) {
                hasDirectLink = true;
            }
            else {
                var linkType = escapeIri(link.id);
                if (!direction || direction === 'out') {
                    var path = this.formatLinkPath(link.path, fixedIri, outElementVar);
                    var boundedDirection = bindDirection
                        ? "BIND(\"out\" as ?direction) "
                        : '';
                    unionParts.push("{ ".concat(path, " BIND(").concat(linkType, " as ?link) ").concat(boundedDirection, "}"));
                }
                if (!direction || direction === 'in') {
                    var path = this.formatLinkPath(link.path, inElementVar, fixedIri);
                    var boundedDirection = bindDirection
                        ? "BIND(\"in\" as ?direction) "
                        : '';
                    unionParts.push("{ ".concat(path, " BIND(").concat(linkType, " as ?link) ").concat(boundedDirection, "}"));
                }
            }
        }
        var usePredicatePart = this.openWorldLinks || hasDirectLink;
        return { unionParts: unionParts, usePredicatePart: usePredicatePart };
    };
    SparqlDataProvider.prototype.formatLinkLinks = function () {
        var unionParts = [];
        var hasDirectLink = false;
        for (var _i = 0, _a = this.settings.linkConfigurations; _i < _a.length; _i++) {
            var link = _a[_i];
            if ((0, responseHandler_1.isDirectLink)(link)) {
                hasDirectLink = true;
            }
            else {
                var linkType = escapeIri(link.id);
                unionParts.push("{ ".concat(this.formatLinkPath(link.path, '?source', '?target'), " BIND(").concat(linkType, " as ?type) }"));
            }
        }
        var usePredicatePart = this.openWorldLinks || hasDirectLink;
        if (usePredicatePart) {
            unionParts.push("{ ?source ?type ?target }");
        }
        return unionParts.join('\nUNION\n');
    };
    SparqlDataProvider.prototype.formatLinkPath = function (path, source, target) {
        return path
            .replace(/[?$]source\b/g, source)
            .replace(/[?$]target\b/g, target);
    };
    SparqlDataProvider.prototype.formatPropertyInfo = function () {
        var unionParts = [];
        var hasDirectProperty = false;
        for (var _i = 0, _a = this.settings.propertyConfigurations; _i < _a.length; _i++) {
            var property = _a[_i];
            if ((0, responseHandler_1.isDirectProperty)(property)) {
                hasDirectProperty = true;
            }
            else {
                var propType = escapeIri(property.id);
                var formatted = this.formatPropertyPath(property.path, '?inst', '?propValue');
                unionParts.push("{ ".concat(formatted, " BIND(").concat(propType, " as ?propType) }"));
            }
        }
        var usePredicatePart = this.openWorldProperties || hasDirectProperty;
        if (usePredicatePart) {
            unionParts.push("{ ?inst ?propType ?propValue }");
        }
        return unionParts.join('\nUNION\n');
    };
    SparqlDataProvider.prototype.formatPropertyPath = function (path, subject, value) {
        return path.replace(/[?$]inst\b/g, subject).replace(/[?$]value\b/g, value);
    };
    SparqlDataProvider.prototype.querySingleElementTypes = function (element) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var types;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.queryManyElementTypes(element ? [element] : [])];
                    case 1:
                        types = _a.sent();
                        return [2 /*return*/, types.get(element)];
                }
            });
        });
    };
    SparqlDataProvider.prototype.queryManyElementTypes = function (elements) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filterTypePattern, ids, queryTemplate, query, response, blankResponse;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (elements.length === 0) {
                            return [2 /*return*/, new Map()];
                        }
                        filterTypePattern = this.settings.filterTypePattern;
                        ids = elements
                            .filter(function (iri) { return !BlankNodes.isEncodedBlank(iri); })
                            .map(function (iri) { return "(".concat(escapeIri(iri), ")"); })
                            .join(' ');
                        queryTemplate = this.settings.defaultPrefix + "SELECT ?inst ?class { VALUES(?inst) { ${ids} } ${filterTypePattern} }";
                        query = resolveTemplate(queryTemplate, { ids: ids, filterTypePattern: filterTypePattern });
                        return [4 /*yield*/, this.executeSparqlQuery(query)];
                    case 1:
                        response = _a.sent();
                        if (this.options.acceptBlankNodes &&
                            elements.find(BlankNodes.isEncodedBlank)) {
                            blankResponse = BlankNodes.getElementTypes(elements);
                            response = (0, responseHandler_1.prependAdditionalBindings)(response, blankResponse);
                        }
                        return [2 /*return*/, (0, responseHandler_1.getElementTypes)(response)];
                }
            });
        });
    };
    return SparqlDataProvider;
}());
exports.SparqlDataProvider = SparqlDataProvider;
function attachLabels(items, fetchLabels) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var resources, _i, items_1, item, labels, _a, items_2, item;
        return tslib_1.__generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    resources = new Set();
                    for (_i = 0, items_1 = items; _i < items_1.length; _i++) {
                        item = items_1[_i];
                        if (BlankNodes.isEncodedBlank(item.id)) {
                            continue;
                        }
                        resources.add(item.id);
                    }
                    return [4 /*yield*/, fetchLabels(resources)];
                case 1:
                    labels = _b.sent();
                    for (_a = 0, items_2 = items; _a < items_2.length; _a++) {
                        item = items_2[_a];
                        if (labels.has(item.id)) {
                            item.label = { values: labels.get(item.id) };
                        }
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function prepareElementImages(fetchImages, elementsInfo) {
    return fetchImages(elementsInfo).then(function (images) {
        for (var iri in images) {
            if (Object.prototype.hasOwnProperty.call(images, iri) &&
                elementsInfo[iri]) {
                elementsInfo[iri].image = images[iri];
            }
        }
    });
}
function resolveTemplate(template, values) {
    var result = template;
    for (var replaceKey in values) {
        if (!values.hasOwnProperty(replaceKey)) {
            continue;
        }
        var replaceValue = values[replaceKey];
        if (replaceValue) {
            result = result.replace(new RegExp('\\${' + replaceKey + '}', 'g'), replaceValue);
        }
    }
    return result;
}
function executeSparqlQuery(endpoint, query, method, queryFunction) {
    var internalQuery;
    if (method === SparqlQueryMethod.GET) {
        internalQuery = queryFunction({
            url: appendQueryParams(endpoint, { query: query }),
            headers: {
                Accept: 'application/sparql-results+json',
            },
            method: 'GET',
        });
    }
    else {
        internalQuery = queryFunction({
            url: endpoint,
            body: query,
            headers: {
                Accept: 'application/sparql-results+json',
                'Content-Type': 'application/sparql-query; charset=UTF-8',
            },
            method: 'POST',
        });
    }
    return internalQuery.then(function (response) {
        if (response.ok) {
            return response.json();
        }
        else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    });
}
exports.executeSparqlQuery = executeSparqlQuery;
function executeSparqlConstruct(endpoint, query, method, queryFunction) {
    var internalQuery;
    if (method === SparqlQueryMethod.GET) {
        internalQuery = queryFunction({
            url: appendQueryParams(endpoint, { query: query }),
            headers: {
                Accept: 'text/turtle',
            },
            method: 'GET',
        });
    }
    else {
        internalQuery = queryFunction({
            url: endpoint,
            body: query,
            headers: {
                Accept: 'text/turtle',
                'Content-Type': 'application/sparql-query; charset=UTF-8',
            },
            method: 'POST',
        });
    }
    return internalQuery
        .then(function (response) {
        if (response.ok) {
            return response.text();
        }
        else {
            var error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    })
        .then(turtle_1.parseTurtleText);
}
exports.executeSparqlConstruct = executeSparqlConstruct;
function appendQueryParams(endpoint, queryParams) {
    if (queryParams === void 0) { queryParams = {}; }
    var initialSeparator = endpoint.indexOf('?') < 0 ? '?' : '&';
    var additionalParams = initialSeparator +
        Object.keys(queryParams)
            .map(function (key) { return "".concat(key, "=").concat(encodeURIComponent(queryParams[key])); })
            .join('&');
    return endpoint + additionalParams;
}
function queryInternal(params) {
    return fetch(params.url, {
        method: params.method,
        body: params.body,
        credentials: 'same-origin',
        mode: 'cors',
        cache: 'default',
        headers: params.headers,
    });
}
function sparqlExtractLabel(subject, label) {
    return "\n        BIND ( str( ".concat(subject, " ) as ?uriStr)\n        BIND ( strafter(?uriStr, \"#\") as ?label3)\n        BIND ( strafter(strafter(?uriStr, \"//\"), \"/\") as ?label6)\n        BIND ( strafter(?label6, \"/\") as ?label5)\n        BIND ( strafter(?label5, \"/\") as ?label4)\n        BIND (if (?label3 != \"\", ?label3,\n            if (?label4 != \"\", ?label4,\n            if (?label5 != \"\", ?label5, ?label6))) as ").concat(label, ")\n    ");
}
function escapeIri(iri) {
    if (typeof iri !== 'string') {
        throw new Error("Cannot escape IRI of type \"".concat(typeof iri, "\""));
    }
    return "<".concat(iri, ">");
}
