"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditLinkLabelForm = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var CLASS_NAME = 'graph-explorer-edit-form';
var EditLinkLabelForm = /** @class */ (function (_super) {
    tslib_1.__extends(EditLinkLabelForm, _super);
    function EditLinkLabelForm(props) {
        var _this = _super.call(this, props) || this;
        var label = _this.computeLabel();
        _this.state = { label: label };
        return _this;
    }
    EditLinkLabelForm.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.link.typeId !== prevProps.link.typeId) {
            var label = this.computeLabel();
            this.setState({ label: label });
        }
    };
    EditLinkLabelForm.prototype.computeLabel = function () {
        var _a = this.props, view = _a.view, link = _a.link;
        var linkType = view.model.getLinkType(link.typeId);
        var template = view.createLinkTemplate(linkType);
        var _b = template.renderLink(link).label, label = _b === void 0 ? {} : _b;
        var labelTexts = label.attrs && label.attrs.text ? label.attrs.text.text : undefined;
        return labelTexts && labelTexts.length > 0
            ? view.selectLabel(labelTexts).value
            : view.formatLabel(linkType.label, linkType.id);
    };
    EditLinkLabelForm.prototype.render = function () {
        var _this = this;
        var _a = this.props, onApply = _a.onApply, onCancel = _a.onCancel;
        var label = this.state.label;
        return (React.createElement("div", { className: CLASS_NAME },
            React.createElement("div", { className: "".concat(CLASS_NAME, "__body") },
                React.createElement("div", { className: "".concat(CLASS_NAME, "__form-row") },
                    React.createElement("label", null, "Link Label"),
                    React.createElement("input", { className: "graph-explorer-form-control", value: label, onChange: function (e) {
                            return _this.setState({ label: e.target.value });
                        } }))),
            React.createElement("div", { className: "".concat(CLASS_NAME, "__controls") },
                React.createElement("button", { className: "graph-explorer-btn graph-explorer-btn-success ".concat(CLASS_NAME, "__apply-button"), onClick: function () { return onApply(label); } }, "Apply"),
                React.createElement("button", { className: "graph-explorer-btn graph-explorer-btn-danger", onClick: function () { return onCancel(); } }, "Cancel"))));
    };
    return EditLinkLabelForm;
}(React.Component));
exports.EditLinkLabelForm = EditLinkLabelForm;
