"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dialog = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var elements_1 = require("../diagram/elements");
var events_1 = require("../viewUtils/events");
var geometry_1 = require("../diagram/geometry");
var draggableHandle_1 = require("../workspace/draggableHandle");
var DEFAULT_WIDTH = 300;
var DEFAULT_HEIGHT = 300;
var MIN_WIDTH = 250;
var MIN_HEIGHT = 250;
var MAX_WIDTH = 800;
var MAX_HEIGHT = 800;
var ELEMENT_OFFSET = 40;
var LINK_OFFSET = 20;
var FOCUS_OFFSET = 20;
var CLASS_NAME = 'graph-explorer-dialog';
var Dialog = /** @class */ (function (_super) {
    tslib_1.__extends(Dialog, _super);
    function Dialog(props) {
        var _this = _super.call(this, props) || this;
        _this.unsubscribeFromTarget = undefined;
        _this.handler = new events_1.EventObserver();
        _this.updateAll = function () { return _this.forceUpdate(); };
        _this.onStartDragging = function (e) {
            _this.preventSelection();
            var size = _this.props.size;
            _this.startSize = {
                x: _this.state.width || size.width,
                y: _this.state.height || size.height,
            };
        };
        _this.onDragHandleBottom = function (e, dx, dy) {
            var height = _this.calculateHeight(_this.startSize.y + dy);
            _this.setState({ height: height });
        };
        _this.onDragHandleRight = function (e, dx) {
            var width = _this.calculateWidth(_this.startSize.x + dx);
            _this.setState({ width: width });
        };
        _this.onDragHandleBottomRight = function (e, dx, dy) {
            var width = _this.calculateWidth(_this.startSize.x + dx);
            var height = _this.calculateHeight(_this.startSize.y + dy);
            _this.setState({ width: width, height: height });
        };
        _this.preventSelection = function () {
            var onMouseUp = function () {
                document.body.classList.remove('graph-explorer--unselectable');
                document.removeEventListener('mouseup', onMouseUp);
            };
            document.addEventListener('mouseup', onMouseUp);
            document.body.classList.add('graph-explorer--unselectable');
        };
        _this.state = {};
        return _this;
    }
    Dialog.prototype.componentDidMount = function () {
        this.listenToTarget(this.props.target);
        this.focusOn();
    };
    Dialog.prototype.componentDidUpdate = function (nextProps) {
        if (nextProps.target !== this.props.target) {
            this.listenToTarget(nextProps.target);
        }
    };
    Dialog.prototype.componentWillUnmount = function () {
        this.listenToTarget(undefined);
    };
    Dialog.prototype.listenToTarget = function (target) {
        var _this = this;
        if (this.unsubscribeFromTarget) {
            this.unsubscribeFromTarget();
            this.unsubscribeFromTarget = undefined;
        }
        if (target) {
            var view = this.props.view;
            if (target instanceof elements_1.Element) {
                this.listenToElement(target);
            }
            else if (target instanceof elements_1.Link) {
                this.listenToLink(target);
            }
            this.handler.listen(view.events, 'changeLanguage', this.updateAll);
            this.unsubscribeFromTarget = function () {
                _this.handler.stopListening();
            };
        }
    };
    Dialog.prototype.listenToElement = function (element) {
        this.handler.listen(element.events, 'changePosition', this.updateAll);
        this.handler.listen(element.events, 'changeSize', this.updateAll);
    };
    Dialog.prototype.listenToLink = function (link) {
        var view = this.props.view;
        var source = view.model.getElement(link.sourceId);
        var target = view.model.getElement(link.targetId);
        this.listenToElement(source);
        this.listenToElement(target);
        this.handler.listen(link.events, 'changeVertices', this.updateAll);
        this.handler.listen(link.events, 'changeLabelBounds', this.updateAll);
    };
    Dialog.prototype.calculatePositionForElement = function (element) {
        var _a = this.props, paperArea = _a.paperArea, size = _a.size;
        var bbox = (0, geometry_1.boundsOf)(element);
        var y0 = paperArea.paperToScrollablePaneCoords(bbox.x, bbox.y).y;
        var _b = paperArea.paperToScrollablePaneCoords(bbox.x + bbox.width, bbox.y + bbox.height), x1 = _b.x, y1 = _b.y;
        return {
            x: x1 + ELEMENT_OFFSET,
            y: (y0 + y1) / 2 - size.height / 2,
        };
    };
    Dialog.prototype.calculatePositionForLink = function (link) {
        var _a = this.props, view = _a.view, paperArea = _a.paperArea;
        var source = view.model.getElement(link.sourceId);
        var target = view.model.getElement(link.targetId);
        if (!source || !target) {
            throw new Error('Source and target are not specified');
        }
        var route = view.getRouting(link.id);
        var verticesDefinedByUser = link.vertices || [];
        var vertices = route ? route.vertices : verticesDefinedByUser;
        var polyline = (0, geometry_1.computePolyline)(source, target, vertices);
        var polylineLength = (0, geometry_1.computePolylineLength)(polyline);
        var targetPoint = (0, geometry_1.getPointAlongPolyline)(polyline, polylineLength / 2);
        var _b = paperArea.paperToScrollablePaneCoords(targetPoint.x, targetPoint.y), x = _b.x, y = _b.y;
        return { y: y + LINK_OFFSET, x: x + LINK_OFFSET };
    };
    Dialog.prototype.calculatePosition = function () {
        var _a = this.props, target = _a.target, paperArea = _a.paperArea, _b = _a.offset, offset = _b === void 0 ? { x: 0, y: 0 } : _b, calculatePosition = _a.calculatePosition;
        if (calculatePosition) {
            var pos = calculatePosition();
            var _c = paperArea.paperToScrollablePaneCoords(pos.x, pos.y), x = _c.x, y = _c.y;
            return { x: x + offset.x, y: y + offset.y };
        }
        if (target instanceof elements_1.Element) {
            return this.calculatePositionForElement(target);
        }
        else if (target instanceof elements_1.Link) {
            return this.calculatePositionForLink(target);
        }
        throw new Error('Unknown target type');
    };
    Dialog.prototype.getViewPortScrollablePoints = function () {
        var paperArea = this.props.paperArea;
        var paperAreaMetrix = paperArea.getAreaMetrics();
        var min = paperArea.clientToScrollablePaneCoords(0, 0);
        var max = paperArea.clientToScrollablePaneCoords(paperAreaMetrix.clientWidth, paperAreaMetrix.clientHeight);
        return { min: min, max: max };
    };
    Dialog.prototype.getDialogScrollablePoints = function () {
        var size = this.props.size;
        var _a = this.calculatePosition(), x = _a.x, y = _a.y;
        var min = {
            x: x - FOCUS_OFFSET,
            y: y - FOCUS_OFFSET,
        };
        var max = {
            x: min.x + size.width + FOCUS_OFFSET * 2,
            y: min.y + size.height + FOCUS_OFFSET * 2,
        };
        return { min: min, max: max };
    };
    Dialog.prototype.focusOn = function () {
        var paperArea = this.props.paperArea;
        var _a = this.getViewPortScrollablePoints(), viewPortMin = _a.min, viewPortMax = _a.max;
        var _b = this.getDialogScrollablePoints(), min = _b.min, max = _b.max;
        var xOffset = 0;
        if (min.x < viewPortMin.x) {
            xOffset = min.x - viewPortMin.x;
        }
        else if (max.x > viewPortMax.x) {
            xOffset = max.x - viewPortMax.x;
        }
        var yOffset = 0;
        if (min.y < viewPortMin.y) {
            yOffset = min.y - viewPortMin.y;
        }
        else if (max.y > viewPortMax.y) {
            yOffset = max.y - viewPortMax.y;
        }
        var curScrollableCenter = {
            x: viewPortMin.x + (viewPortMax.x - viewPortMin.x) / 2,
            y: viewPortMin.y + (viewPortMax.y - viewPortMin.y) / 2,
        };
        var newScrollabalCenter = {
            x: curScrollableCenter.x + xOffset,
            y: curScrollableCenter.y + yOffset,
        };
        var paperCenter = paperArea.scrollablePaneToPaperCoords(newScrollabalCenter.x, newScrollabalCenter.y);
        paperArea.centerTo(paperCenter);
    };
    Dialog.prototype.calculateHeight = function (height) {
        var size = this.props.size;
        var minHeight = Math.min(size.height, MIN_HEIGHT);
        var maxHeight = Math.max(size.height, MAX_HEIGHT);
        return Math.max(minHeight, Math.min(maxHeight, height));
    };
    Dialog.prototype.calculateWidth = function (width) {
        var size = this.props.size;
        var minWidth = Math.min(size.width, MIN_WIDTH);
        var maxWidth = Math.max(size.width, MAX_WIDTH);
        return Math.max(minWidth, Math.min(maxWidth, width));
    };
    Dialog.prototype.render = function () {
        var _this = this;
        var _a = this.props, size = _a.size, caption = _a.caption;
        var _b = this.calculatePosition(), x = _b.x, y = _b.y;
        var width = this.state.width || size.width;
        var height = this.state.height || size.height;
        var style = {
            top: y,
            left: x,
            width: width,
            height: height,
        };
        return (React.createElement("div", { className: CLASS_NAME, style: style },
            React.createElement("button", { className: "".concat(CLASS_NAME, "__close-button"), onClick: function () { return _this.props.onClose(); } }),
            caption ? (React.createElement("div", { className: "graph-explorer-dialog__caption" }, caption)) : null,
            this.props.children,
            React.createElement(draggableHandle_1.DraggableHandle, { className: "".concat(CLASS_NAME, "__bottom-handle"), onBeginDragHandle: this.onStartDragging, onDragHandle: this.onDragHandleBottom }),
            React.createElement(draggableHandle_1.DraggableHandle, { className: "".concat(CLASS_NAME, "__right-handle"), onBeginDragHandle: this.onStartDragging, onDragHandle: this.onDragHandleRight }),
            React.createElement(draggableHandle_1.DraggableHandle, { className: "".concat(CLASS_NAME, "__bottom-right-handle"), onBeginDragHandle: this.onStartDragging, onDragHandle: this.onDragHandleBottomRight })));
    };
    Dialog.defaultProps = {
        size: { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT },
    };
    return Dialog;
}(React.Component));
exports.Dialog = Dialog;
