"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorController = exports.DialogTypes = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var model_1 = require("../data/model");
var commands_1 = require("../diagram/commands");
var elements_1 = require("../diagram/elements");
var geometry_1 = require("../diagram/geometry");
var history_1 = require("../diagram/history");
var view_1 = require("../diagram/view");
var events_1 = require("../viewUtils/events");
var dialog_1 = require("../widgets/dialog");
var connectionsMenu_1 = require("../widgets/connectionsMenu");
var editEntityForm_1 = require("../forms/editEntityForm");
var editElementTypeForm_1 = require("../forms/editElementTypeForm");
var editLinkForm_1 = require("../forms/editLinkForm");
var editLinkLabelForm_1 = require("../forms/editLinkLabelForm");
var halo_1 = require("../widgets/halo");
var haloLink_1 = require("../widgets/haloLink");
var linkStateWidget_1 = require("./linkStateWidget");
var elementDecorator_1 = require("./elementDecorator");
var layout_1 = require("../viewUtils/layout");
var spinner_1 = require("../viewUtils/spinner");
var asyncModel_1 = require("./asyncModel");
var authoringState_1 = require("./authoringState");
var temporaryState_1 = require("./temporaryState");
var editLayer_1 = require("./editLayer");
var validation_1 = require("./validation");
var async_1 = require("../viewUtils/async");
var collections_1 = require("../viewUtils/collections");
var DialogTypes;
(function (DialogTypes) {
    DialogTypes[DialogTypes["ConnectionsMenu"] = 0] = "ConnectionsMenu";
    DialogTypes[DialogTypes["EditEntityForm"] = 1] = "EditEntityForm";
    DialogTypes[DialogTypes["EditLinkForm"] = 2] = "EditLinkForm";
    DialogTypes[DialogTypes["EditEntityTypeForm"] = 3] = "EditEntityTypeForm";
    DialogTypes[DialogTypes["EditLinkLabelForm"] = 4] = "EditLinkLabelForm";
})(DialogTypes = exports.DialogTypes || (exports.DialogTypes = {}));
var EditorController = /** @class */ (function () {
    function EditorController(props) {
        var _this = this;
        this.listener = new events_1.EventObserver();
        this.source = new events_1.EventSource();
        this.events = this.source;
        this._authoringState = authoringState_1.AuthoringState.empty;
        this._validationState = validation_1.ValidationState.empty;
        this._temporaryState = temporaryState_1.TemporaryState.empty;
        this._selection = [];
        this.cancellation = new async_1.Cancellation();
        this.onKeyUp = function (e) {
            var DELETE_KEY_CODE = 46;
            if (e.keyCode === DELETE_KEY_CODE &&
                document.activeElement.localName !== 'input') {
                _this.removeSelectedElements();
            }
        };
        var model = props.model, view = props.view, options = tslib_1.__rest(props, ["model", "view"]);
        this.model = model;
        this.view = view;
        this.options = options;
        this.listener.listen(this.events, 'changeValidationState', function (e) {
            for (var _i = 0, _a = _this.model.elements; _i < _a.length; _i++) {
                var element = _a[_i];
                var previous = e.previous.elements.get(element.iri);
                var current = _this.validationState.elements.get(element.iri);
                if (current !== previous) {
                    element.redraw();
                }
            }
        });
        this.listener.listen(this.events, 'changeAuthoringState', function (e) {
            if (_this.options.validationApi) {
                var changedElements = (0, validation_1.changedElementsToValidate)(e.previous, _this);
                (0, validation_1.validateElements)(changedElements, _this.options.validationApi, _this, _this.cancellation.signal);
            }
        });
        this.view._setElementDecorator(function (element) { return (React.createElement(elementDecorator_1.ElementDecorator, { model: element, view: _this.view, editor: _this, position: element.position })); });
    }
    EditorController.prototype._initializePaperComponents = function (paperArea) {
        var _this = this;
        this.listener.listen(paperArea.events, 'pointerUp', function (e) {
            return _this.onPaperPointerUp(e);
        });
        this.listener.listen(this.model.events, 'changeCells', function () {
            return _this.onCellsChanged();
        });
        this.listener.listen(this.model.events, 'elementEvent', function (e) {
            if (e.key === 'requestedGroupContent') {
                _this.loadGroupContent(e.data.requestedGroupContent.source);
            }
        });
        this.listener.listen(this.model.events, 'loadingStart', function () {
            return _this.setSpinner({});
        });
        this.listener.listen(this.model.events, 'loadingSuccess', function () {
            _this.setSpinner(undefined);
            var widget = React.createElement(linkStateWidget_1.LinkStateWidget, { view: _this.view, editor: _this });
            _this.view.setPaperWidget({
                key: 'states',
                widget: widget,
                attachment: view_1.WidgetAttachment.OverLinks,
            });
        });
        this.listener.listen(this.model.events, 'loadingError', function (_a) {
            var error = _a.error;
            var statusText = error ? error.message : undefined;
            _this.setSpinner({ statusText: statusText, errorOccured: true });
        });
        if (!this.options.disableHalo) {
            this.configureHalo();
            document.addEventListener('keyup', this.onKeyUp);
            this.listener.listen(this.view.events, 'dispose', function () {
                document.removeEventListener('keyup', _this.onKeyUp);
            });
        }
    };
    Object.defineProperty(EditorController.prototype, "inAuthoringMode", {
        get: function () {
            return Boolean(this._metadataApi);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EditorController.prototype, "metadataApi", {
        get: function () {
            return this._metadataApi;
        },
        enumerable: false,
        configurable: true
    });
    EditorController.prototype.setMetadataApi = function (value) {
        var previous = this._metadataApi;
        if (value === previous) {
            return;
        }
        this._metadataApi = value;
        if (Boolean(value) !== Boolean(previous)) {
            // authoring mode changed
            this.source.trigger('changeMode', { source: this });
        }
    };
    Object.defineProperty(EditorController.prototype, "authoringState", {
        get: function () {
            return this._authoringState;
        },
        enumerable: false,
        configurable: true
    });
    EditorController.prototype.setAuthoringState = function (value) {
        var previous = this._authoringState;
        if (previous === value) {
            return;
        }
        this.model.history.execute(this.updateAuthoringState(value));
    };
    EditorController.prototype.updateAuthoringState = function (state) {
        var _this = this;
        var previous = this._authoringState;
        return history_1.Command.create('Create or delete entities and links', function () {
            _this._authoringState = state;
            _this.source.trigger('changeAuthoringState', { source: _this, previous: previous });
            return _this.updateAuthoringState(previous);
        });
    };
    Object.defineProperty(EditorController.prototype, "validationState", {
        get: function () {
            return this._validationState;
        },
        enumerable: false,
        configurable: true
    });
    EditorController.prototype.setValidationState = function (value) {
        var previous = this._validationState;
        if (value === previous) {
            return;
        }
        this._validationState = value;
        this.source.trigger('changeValidationState', { source: this, previous: previous });
    };
    Object.defineProperty(EditorController.prototype, "temporaryState", {
        get: function () {
            return this._temporaryState;
        },
        enumerable: false,
        configurable: true
    });
    EditorController.prototype.setTemporaryState = function (value) {
        var previous = this._temporaryState;
        if (value === previous) {
            return;
        }
        this._temporaryState = value;
        this.source.trigger('changeTemporaryState', { source: this, previous: previous });
    };
    Object.defineProperty(EditorController.prototype, "selection", {
        get: function () {
            return this._selection;
        },
        enumerable: false,
        configurable: true
    });
    EditorController.prototype.setSelection = function (value) {
        var previous = this._selection;
        if (previous === value) {
            return;
        }
        this._selection = value;
        this.source.trigger('changeSelection', { source: this, previous: previous });
    };
    EditorController.prototype.cancelSelection = function () {
        this.setSelection([]);
    };
    EditorController.prototype.removeSelectedElements = function () {
        var itemsToRemove = this.selection;
        if (itemsToRemove.length === 0) {
            return;
        }
        this.cancelSelection();
        this.removeItems(itemsToRemove);
    };
    EditorController.prototype.removeItems = function (items) {
        var batch = this.model.history.startBatch();
        var deletedElementIris = new Set();
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            if (item instanceof elements_1.Element) {
                var event_1 = this.authoringState.elements.get(item.iri);
                if (event_1) {
                    this.discardChange(event_1);
                }
                this.model.removeElement(item.id);
                deletedElementIris.add(item.iri);
            }
            else if (item instanceof elements_1.Link) {
                if (authoringState_1.AuthoringState.isNewLink(this.authoringState, item.data)) {
                    this.deleteLink(item.data);
                }
            }
        }
        if (deletedElementIris.size > 0) {
            var newState = authoringState_1.AuthoringState.deleteNewLinksConnectedToElements(this.authoringState, deletedElementIris);
            this.setAuthoringState(newState);
        }
        batch.store();
    };
    EditorController.prototype.onPaperPointerUp = function (event) {
        if (this.options.disableHalo) {
            return;
        }
        var sourceEvent = event.sourceEvent, target = event.target, triggerAsClick = event.triggerAsClick;
        if (sourceEvent.ctrlKey || sourceEvent.shiftKey || sourceEvent.metaKey) {
            return;
        }
        if (this.dialogTarget && this.dialogType === DialogTypes.EditEntityForm) {
            return;
        }
        if (target instanceof elements_1.Element) {
            this.setSelection([target]);
            target.focus();
        }
        else if (target instanceof elements_1.Link) {
            this.setSelection([target]);
        }
        else if (target instanceof elements_1.LinkVertex) {
            this.setSelection([target.link]);
        }
        else if (!target && triggerAsClick) {
            this.setSelection([]);
            this.hideDialog();
            if (document.activeElement) {
                document.activeElement.blur();
            }
        }
    };
    EditorController.prototype.onCellsChanged = function () {
        var _this = this;
        if (this.selection.length === 0) {
            return;
        }
        var newSelection = this.selection.filter(function (item) {
            return item instanceof elements_1.Element
                ? _this.model.getElement(item.id)
                : item instanceof elements_1.Link
                    ? _this.model.getLinkById(item.id)
                    : false;
        });
        if (newSelection.length < this.selection.length) {
            this.setSelection(newSelection);
        }
    };
    EditorController.prototype.setSpinner = function (props) {
        var widget = props ? React.createElement(LoadingWidget, { spinnerProps: props }) : undefined;
        this.view.setPaperWidget({
            key: LoadingWidget.Key,
            widget: widget,
            attachment: view_1.WidgetAttachment.Viewport,
        });
    };
    EditorController.prototype.configureHalo = function () {
        var _this = this;
        if (this.options.disableHalo) {
            return;
        }
        this.listener.listen(this.events, 'changeSelection', function () {
            var selected = _this.selection.length === 1 ? _this.selection[0] : undefined;
            if (_this.dialogTarget && selected !== _this.dialogTarget) {
                _this.hideDialog();
            }
            _this.bringSelectedElementsToFront();
            _this.renderDefaultHalo();
        });
        this.listener.listen(this.events, 'toggleDialog', function (_a) {
            var isOpened = _a.isOpened;
            _this.renderDefaultHalo();
        });
        this.renderDefaultHalo();
    };
    EditorController.prototype.bringSelectedElementsToFront = function () {
        if (this.selection.length === 0) {
            return;
        }
        this.model.reorderElements((0, collections_1.makeMoveComparator)(this.model.elements, this.selection.filter(function (item) { return item instanceof elements_1.Element; }), collections_1.MoveDirection.ToEnd));
    };
    EditorController.prototype.renderDefaultHalo = function () {
        var _this = this;
        var selected = this.selection.length === 1 ? this.selection[0] : undefined;
        var halo;
        if (selected instanceof elements_1.Element) {
            halo = (React.createElement(halo_1.Halo, { editor: this, metadataApi: this.metadataApi, target: selected, onRemove: function () { return _this.removeSelectedElements(); }, onExpand: function () {
                    _this.model.history.execute((0, commands_1.setElementExpanded)(selected, !selected.isExpanded));
                }, navigationMenuOpened: this.dialogType === DialogTypes.ConnectionsMenu, onToggleNavigationMenu: function () {
                    if (_this.dialogTarget &&
                        _this.dialogType === DialogTypes.ConnectionsMenu) {
                        _this.hideDialog();
                    }
                    else {
                        _this.showConnectionsMenu(selected);
                    }
                    _this.renderDefaultHalo();
                }, onAddToFilter: function () { return selected.addToFilter(); }, onEstablishNewLink: function (point) {
                    return _this.startEditing({
                        target: selected,
                        mode: editLayer_1.EditLayerMode.establishLink,
                        point: point,
                    });
                }, onFollowLink: function (element, e) {
                    return _this.view.onIriClick(element.iri, element, view_1.IriClickIntent.JumpToEntity, e);
                } }));
        }
        else if (selected instanceof elements_1.Link) {
            halo = (React.createElement(haloLink_1.HaloLink, { view: this.view, editor: this, metadataApi: this.metadataApi, target: selected, onEdit: function () { return _this.showEditLinkForm(selected); }, onDelete: function () { return _this.deleteLink(selected.data); }, onSourceMove: function (point) {
                    return _this.startEditing({
                        target: selected,
                        mode: editLayer_1.EditLayerMode.moveLinkSource,
                        point: point,
                    });
                }, onTargetMove: function (point) {
                    return _this.startEditing({
                        target: selected,
                        mode: editLayer_1.EditLayerMode.moveLinkTarget,
                        point: point,
                    });
                }, onEditLabel: function () { return _this.showEditLinkLabelForm(selected); } }));
        }
        this.view.setPaperWidget({
            key: 'halo',
            widget: halo,
            attachment: view_1.WidgetAttachment.OverElements,
        });
    };
    EditorController.prototype.showConnectionsMenu = function (target) {
        var _this = this;
        var dialogType = DialogTypes.ConnectionsMenu;
        var onClose = function () { return _this.hideDialog(); };
        var content = (React.createElement(connectionsMenu_1.ConnectionsMenu, { view: this.view, editor: this, target: target, onAddElements: function (iris, linkType) {
                return _this.onAddElementsInConnectionMenu(iris, target, linkType);
            }, onClose: onClose, suggestProperties: this.options.suggestProperties }));
        this.showDialog({ target: target, dialogType: dialogType, content: content, onClose: onClose });
    };
    EditorController.prototype.showEditEntityForm = function (target) {
        var _this = this;
        var propertyEditor = this.options.propertyEditor;
        var dialogType = DialogTypes.EditEntityForm;
        var onSubmit = function (newData) {
            _this.hideDialog();
            _this.changeEntityData(target.data.id, newData);
        };
        var isIriModified = authoringState_1.AuthoringState.isElementWithModifiedIri(this.authoringState, target.data.id);
        var modelToEdit;
        if (isIriModified) {
            var relatedEvent = this.authoringState.elements.get(target.data.id);
            modelToEdit = tslib_1.__assign(tslib_1.__assign({}, target.data), { id: relatedEvent.newIri });
        }
        else {
            modelToEdit = target.data;
        }
        var onCancel = function () { return _this.hideDialog(); };
        var content = propertyEditor ? (propertyEditor({ elementData: target.data, onSubmit: onSubmit, onCancel: onCancel })) : (React.createElement(editEntityForm_1.EditEntityForm, { view: this.view, entity: modelToEdit, onApply: onSubmit, onCancel: onCancel }));
        this.showDialog({ target: target, dialogType: dialogType, content: content, onClose: onCancel });
    };
    EditorController.prototype.showEditElementTypeForm = function (_a) {
        var _this = this;
        var link = _a.link, source = _a.source, target = _a.target, targetIsNew = _a.targetIsNew;
        var dialogType = DialogTypes.EditEntityTypeForm;
        var onCancel = function () {
            _this.removeTemporaryElement(target);
            _this.removeTemporaryLink(link);
            _this.hideDialog();
        };
        var content = (React.createElement(editElementTypeForm_1.EditElementTypeForm, { editor: this, view: this.view, metadataApi: this.metadataApi, link: link.data, source: source.data, target: { value: target.data, isNew: targetIsNew }, onChangeElement: function (data) {
                var previous = target.data;
                _this.setTemporaryState(temporaryState_1.TemporaryState.deleteElement(_this.temporaryState, previous));
                target.setData(data);
                _this.setTemporaryState(temporaryState_1.TemporaryState.addElement(_this.temporaryState, data));
            }, onChangeLink: function (data) {
                _this.removeTemporaryLink(link);
                var newLink = makeLinkWithDirection(new elements_1.Link({
                    typeId: data.linkTypeId,
                    sourceId: source.id,
                    targetId: target.id,
                    data: tslib_1.__assign(tslib_1.__assign({}, data), { sourceId: source.iri, targetId: target.iri }),
                }), data);
                link = _this.createNewLink({ link: newLink, temporary: true });
            }, onApply: function (elementData, isNewElement, linkData) {
                _this.removeTemporaryElement(target);
                _this.removeTemporaryLink(link);
                var batch = _this.model.history.startBatch(isNewElement ? 'Create new entity' : 'Link to entity');
                _this.model.addElement(target);
                if (isNewElement) {
                    target.setExpanded(true);
                    _this.setAuthoringState(authoringState_1.AuthoringState.addElement(_this._authoringState, target.data));
                }
                else {
                    _this.model.requestLinksOfType();
                }
                var newLink = makeLinkWithDirection(new elements_1.Link({
                    typeId: link.typeId,
                    sourceId: source.id,
                    targetId: target.id,
                    data: tslib_1.__assign(tslib_1.__assign({}, link.data), { sourceId: source.iri, targetId: target.iri }),
                }), linkData);
                _this.createNewLink({ link: newLink });
                batch.store();
                _this.hideDialog();
                if (isNewElement) {
                    _this.showEditEntityForm(target);
                }
            }, onCancel: onCancel }));
        this.showDialog({
            target: target,
            dialogType: dialogType,
            content: content,
            caption: 'Establish New Connection',
            onClose: onCancel,
        });
    };
    EditorController.prototype.showEditLinkForm = function (link) {
        var _this = this;
        var dialogType = DialogTypes.EditLinkForm;
        var source = this.model.getElement(link.sourceId).data;
        var target = this.model.getElement(link.targetId).data;
        var onCancel = function () {
            if (_this.temporaryState.links.has(link.data)) {
                _this.removeTemporaryLink(link);
            }
            _this.hideDialog();
        };
        var content = (React.createElement(editLinkForm_1.EditLinkForm, { editor: this, view: this.view, metadataApi: this.metadataApi, link: link.data, source: source, target: target, onChange: function (data) {
                if (_this.temporaryState.links.has(link.data)) {
                    _this.removeTemporaryLink(link);
                    var newLink = makeLinkWithDirection(link, data);
                    _this.showEditLinkForm(_this.createNewLink({ link: newLink, temporary: true }));
                }
            }, onApply: function (data) {
                if (_this.temporaryState.links.has(link.data)) {
                    _this.removeTemporaryLink(link);
                    var newLink = makeLinkWithDirection(link, data);
                    _this.createNewLink({ link: newLink });
                }
                else {
                    _this.changeLink(link.data, data);
                }
                _this.hideDialog();
            }, onCancel: onCancel }));
        var caption = this.temporaryState.links.has(link.data)
            ? 'Establish New Connection'
            : 'Edit Connection';
        this.showDialog({
            target: link,
            dialogType: dialogType,
            content: content,
            size: { width: 300, height: 160 },
            caption: caption,
            onClose: onCancel,
        });
    };
    // Link editing implementation could be rethought in the future.
    EditorController.prototype.showEditLinkLabelForm = function (link) {
        var _this = this;
        var linkType = this.view.model.getLinkType(link.typeId);
        var template = this.view.createLinkTemplate(linkType);
        var size = { width: 300, height: 145 };
        var onCancel = function () { return _this.hideDialog(); };
        this.showDialog({
            target: link,
            dialogType: DialogTypes.EditLinkLabelForm,
            content: (React.createElement(editLinkLabelForm_1.EditLinkLabelForm, { view: this.view, link: link, onApply: function (label) {
                    template.setLinkLabel(link, label);
                    _this.hideDialog();
                }, onCancel: onCancel })),
            size: size,
            caption: 'Edit Link Label',
            offset: { x: 25, y: -size.height / 2 },
            calculatePosition: function () {
                var _a = link.labelBounds, x = _a.x, y = _a.y, width = _a.width, height = _a.height;
                return { x: x + width, y: y + height / 2 };
            },
            onClose: onCancel,
        });
    };
    EditorController.prototype.showDialog = function (params) {
        var target = params.target, dialogType = params.dialogType, content = params.content, size = params.size, caption = params.caption, offset = params.offset, calculatePosition = params.calculatePosition, onClose = params.onClose;
        this.dialogTarget = target;
        this.dialogType = dialogType;
        var dialog = (React.createElement(dialog_1.Dialog, { view: this.view, target: target, size: size, caption: caption, offset: offset, calculatePosition: calculatePosition, onClose: onClose }, content));
        this.view.setPaperWidget({
            key: 'dialog',
            widget: dialog,
            attachment: view_1.WidgetAttachment.OverElements,
        });
        this.source.trigger('toggleDialog', { isOpened: true });
    };
    EditorController.prototype.hideDialog = function () {
        if (this.dialogTarget) {
            var isTemporaryElement = this.dialogTarget instanceof elements_1.Element &&
                this.temporaryState.elements.has(this.dialogTarget.iri);
            var isTemporaryLink = this.dialogTarget instanceof elements_1.Link &&
                this.temporaryState.links.has(this.dialogTarget.data);
            if (isTemporaryElement || isTemporaryLink) {
                this.resetTemporaryState();
            }
            this.dialogType = undefined;
            this.dialogTarget = undefined;
            this.view.setPaperWidget({
                key: 'dialog',
                widget: undefined,
                attachment: view_1.WidgetAttachment.OverElements,
            });
            this.source.trigger('toggleDialog', { isOpened: false });
        }
    };
    EditorController.prototype.onDragDrop = function (dragged, paperPosition) {
        var batch = this.model.history.startBatch('Drag and drop onto diagram');
        var placedElements = placeElements(this.view, dragged, paperPosition);
        var irisToLoad = placedElements.map(function (elem) { return elem.iri; });
        batch.history.execute((0, asyncModel_1.requestElementData)(this.model, irisToLoad));
        batch.history.execute((0, asyncModel_1.restoreLinksBetweenElements)(this.model));
        batch.store();
        if (placedElements.length > 0) {
            placedElements[placedElements.length - 1].focus();
        }
        this.setSelection(placedElements);
        this.source.trigger('addElements', { elements: placedElements });
    };
    EditorController.prototype.onAddElementsInConnectionMenu = function (elementIris, targetElement, linkType) {
        var _this = this;
        var batch = this.view.model.history.startBatch();
        var elements = elementIris.map(function (iri) { return _this.model.createElement(iri); });
        this.view.performSyncUpdate();
        (0, layout_1.placeElementsAround)({
            model: this.model,
            elements: elements,
            targetElement: targetElement,
            prefferedLinksLength: 300,
        }).then(function () {
            _this.source.trigger('addElements', { elements: elements });
        });
        if (linkType && !linkType.visible) {
            batch.history.execute((0, commands_1.changeLinkTypeVisibility)({
                linkType: linkType,
                visible: true,
                showLabel: true,
                preventLoading: true,
            }));
        }
        batch.history.execute((0, asyncModel_1.requestElementData)(this.model, elementIris));
        batch.history.execute((0, asyncModel_1.restoreLinksBetweenElements)(this.model));
        batch.store();
    };
    EditorController.prototype.loadGroupContent = function (element) {
        var _this = this;
        return this.model.loadEmbeddedElements(element.iri).then(function (models) {
            var batch = _this.model.history.startBatch();
            var elementIris = Object.keys(models);
            var elements = elementIris.map(function (key) {
                return _this.model.createElement(models[key], element.id);
            });
            batch.discard();
            return Promise.all([
                _this.model.requestElementData(elementIris),
                _this.model.requestLinksOfType(),
            ]).then(function () {
                _this.view.performSyncUpdate();
                (0, layout_1.applyLayout)(_this.model, (0, layout_1.forceLayout)({
                    model: _this.model,
                    group: element.id,
                }));
                _this.model.triggerChangeGroupContent(element.id);
            });
        });
    };
    EditorController.prototype.createNewEntity = function (_a) {
        var elementModel = _a.elementModel, temporary = _a.temporary;
        var batch = this.model.history.startBatch('Create new entity');
        var element = this.model.createElement(elementModel);
        element.setExpanded(true);
        if (temporary) {
            this.setTemporaryState(temporaryState_1.TemporaryState.addElement(this.temporaryState, element.data));
            batch.discard();
        }
        else {
            this.setAuthoringState(authoringState_1.AuthoringState.addElement(this._authoringState, element.data));
            batch.store();
        }
        return element;
    };
    EditorController.prototype.changeEntityData = function (targetIri, newData) {
        var elements = this.model.elements.filter(function (el) { return el.iri === targetIri; });
        if (elements.length === 0) {
            return;
        }
        var oldData = elements[0].data;
        var batch = this.model.history.startBatch('Edit entity');
        var newState = authoringState_1.AuthoringState.changeElement(this._authoringState, oldData, newData);
        // get created authoring event by either old or new IRI (in case of new entities)
        var event = newState.elements.get(targetIri) || newState.elements.get(newData.id);
        this.model.history.execute((0, commands_1.setElementData)(this.model, targetIri, event.after));
        this.setAuthoringState(newState);
        batch.store();
    };
    EditorController.prototype.deleteEntity = function (elementIri) {
        var _this = this;
        var state = this.authoringState;
        var elements = this.model.elements.filter(function (el) { return el.iri === elementIri; });
        if (elements.length === 0) {
            return;
        }
        var batch = this.model.history.startBatch('Delete entity');
        var model = elements[0].data;
        var event = state.elements.get(elementIri);
        // remove new connected links
        var linksToRemove = new Set();
        for (var _i = 0, elements_2 = elements; _i < elements_2.length; _i++) {
            var element = elements_2[_i];
            for (var _a = 0, _b = element.links; _a < _b.length; _a++) {
                var link = _b[_a];
                if (link.data && authoringState_1.AuthoringState.isNewLink(state, link.data)) {
                    linksToRemove.add(link.id);
                }
            }
        }
        linksToRemove.forEach(function (linkId) { return _this.model.removeLink(linkId); });
        if (event) {
            this.discardChange(event);
        }
        this.setAuthoringState(authoringState_1.AuthoringState.deleteElement(state, model));
        batch.store();
    };
    EditorController.prototype.createNewLink = function (params) {
        var base = params.link, temporary = params.temporary;
        var existingLink = this.model.findLink(base.typeId, base.sourceId, base.targetId);
        if (existingLink) {
            throw Error('The link already exists');
        }
        var batch = this.model.history.startBatch('Create new link');
        var addedLink = this.model.addLink(base);
        if (!temporary) {
            this.model.createLinks(base.data);
        }
        var links = this.model.links.filter(function (link) {
            return (0, model_1.sameLink)(link.data, base.data);
        });
        if (links.length > 0) {
            if (temporary) {
                this.setTemporaryState(temporaryState_1.TemporaryState.addLink(this.temporaryState, base.data));
                batch.discard();
            }
            else {
                this.setAuthoringState(authoringState_1.AuthoringState.addLink(this._authoringState, base.data));
                batch.store();
            }
        }
        else {
            batch.discard();
        }
        return addedLink;
    };
    EditorController.prototype.changeLink = function (oldData, newData) {
        var _this = this;
        var batch = this.model.history.startBatch('Change link');
        if ((0, model_1.sameLink)(oldData, newData)) {
            this.model.history.execute((0, commands_1.setLinkData)(this.model, oldData, newData));
            this.setAuthoringState(authoringState_1.AuthoringState.changeLink(this._authoringState, oldData, newData));
        }
        else {
            var newState = this._authoringState;
            newState = authoringState_1.AuthoringState.deleteLink(newState, oldData);
            newState = authoringState_1.AuthoringState.addLink(newState, newData);
            if (authoringState_1.AuthoringState.isNewLink(this._authoringState, oldData)) {
                this.model.links
                    .filter(function (link) { return (0, model_1.sameLink)(link.data, oldData); })
                    .forEach(function (link) { return _this.model.removeLink(link.id); });
            }
            this.model.createLinks(newData);
            this.setAuthoringState(newState);
        }
        batch.store();
    };
    EditorController.prototype.moveLinkSource = function (params) {
        var link = params.link, newSource = params.newSource;
        var batch = this.model.history.startBatch('Move link to another element');
        this.changeLink(link.data, tslib_1.__assign(tslib_1.__assign({}, link.data), { sourceId: newSource.iri }));
        var newLink = this.model.findLink(link.typeId, newSource.id, link.targetId);
        newLink.setVertices(link.vertices);
        batch.store();
        return newLink;
    };
    EditorController.prototype.moveLinkTarget = function (params) {
        var link = params.link, newTarget = params.newTarget;
        var batch = this.model.history.startBatch('Move link to another element');
        this.changeLink(link.data, tslib_1.__assign(tslib_1.__assign({}, link.data), { targetId: newTarget.iri }));
        var newLink = this.model.findLink(link.typeId, link.sourceId, newTarget.id);
        newLink.setVertices(link.vertices);
        batch.store();
        return newLink;
    };
    EditorController.prototype.deleteLink = function (model) {
        var _this = this;
        var state = this.authoringState;
        if (authoringState_1.AuthoringState.isDeletedLink(state, model)) {
            return;
        }
        var batch = this.model.history.startBatch('Delete link');
        var newState = authoringState_1.AuthoringState.deleteLink(state, model);
        if (authoringState_1.AuthoringState.isNewLink(state, model)) {
            this.model.links
                .filter(function (_a) {
                var data = _a.data;
                return (0, model_1.sameLink)(data, model);
            })
                .forEach(function (link) { return _this.model.removeLink(link.id); });
        }
        this.setAuthoringState(newState);
        batch.store();
    };
    EditorController.prototype.startEditing = function (params) {
        var _this = this;
        var target = params.target, mode = params.mode, point = params.point;
        var onFinishEditing = function () {
            _this.view.setPaperWidget({
                key: 'editLayer',
                widget: undefined,
                attachment: view_1.WidgetAttachment.OverElements,
            });
        };
        var editLayer = (React.createElement(editLayer_1.EditLayer, { view: this.view, editor: this, metadataApi: this.metadataApi, mode: mode, target: target, point: point, onFinishEditing: onFinishEditing }));
        this.view.setPaperWidget({
            key: 'editLayer',
            widget: editLayer,
            attachment: view_1.WidgetAttachment.OverElements,
        });
    };
    EditorController.prototype.resetTemporaryState = function () {
        var _this = this;
        if (this.temporaryState.elements.size) {
            this.model.elements.forEach(function (element) {
                if (_this.temporaryState.elements.has(element.iri)) {
                    _this.removeTemporaryElement(element);
                }
            });
        }
        if (this.temporaryState.links.size) {
            this.model.links.forEach(function (link) {
                if (_this.temporaryState.links.get(link.data)) {
                    _this.removeTemporaryLink(link);
                }
            });
        }
    };
    EditorController.prototype.removeTemporaryElement = function (element) {
        var batch = this.model.history.startBatch();
        this.model.removeElement(element.id);
        batch.discard();
        this.setTemporaryState(temporaryState_1.TemporaryState.deleteElement(this.temporaryState, element.data));
    };
    EditorController.prototype.removeTemporaryLink = function (link) {
        this.model.removeLink(link.id);
        this.setTemporaryState(temporaryState_1.TemporaryState.deleteLink(this.temporaryState, link.data));
    };
    EditorController.prototype.discardChange = function (event) {
        var _this = this;
        var newState = authoringState_1.AuthoringState.discard(this._authoringState, event);
        if (newState === this._authoringState) {
            return;
        }
        var batch = this.model.history.startBatch('Discard change');
        if (event.type === authoringState_1.AuthoringKind.ChangeElement) {
            if (event.deleted) {
                /* nothing */
            }
            else if (event.before) {
                this.model.history.execute((0, commands_1.setElementData)(this.model, event.after.id, event.before));
            }
            else {
                this.model.elements
                    .filter(function (el) { return el.iri === event.after.id; })
                    .forEach(function (el) { return _this.model.removeElement(el.id); });
            }
        }
        else if (event.type === authoringState_1.AuthoringKind.ChangeLink) {
            if (event.deleted) {
                /* nothing */
            }
            else if (event.before) {
                this.model.history.execute((0, commands_1.setLinkData)(this.model, event.after, event.before));
            }
            else {
                this.model.links
                    .filter(function (_a) {
                    var data = _a.data;
                    return (0, model_1.sameLink)(data, event.after);
                })
                    .forEach(function (link) { return _this.model.removeLink(link.id); });
            }
        }
        this.setAuthoringState(newState);
        batch.store();
    };
    return EditorController;
}());
exports.EditorController = EditorController;
var LoadingWidget = /** @class */ (function (_super) {
    tslib_1.__extends(LoadingWidget, _super);
    function LoadingWidget() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoadingWidget.prototype.render = function () {
        var _a = this.props, spinnerProps = _a.spinnerProps, paperArea = _a.paperArea;
        var areaMetrics = paperArea.getAreaMetrics();
        var paneWidth = areaMetrics.clientWidth;
        var paneHeight = areaMetrics.clientHeight;
        var x = spinnerProps.statusText ? paneWidth / 3 : paneWidth / 2;
        var position = { x: x, y: paneHeight / 2 };
        return (React.createElement("div", { className: "graph-explorer-loading-widget" },
            React.createElement("svg", { width: paneWidth, height: paneHeight },
                React.createElement(spinner_1.Spinner, tslib_1.__assign({ position: position }, spinnerProps)))));
    };
    LoadingWidget.Key = 'loadingWidget';
    return LoadingWidget;
}(React.Component));
function placeElements(view, dragged, position) {
    var elements = dragged.map(function (item) { return view.model.createElement(item); });
    for (var _i = 0, elements_3 = elements; _i < elements_3.length; _i++) {
        var element = elements_3[_i];
        // intialally anchor element at top left corner to preserve canvas scroll state,
        // measure it and only then move to center-anchored position
        element.setPosition(position);
    }
    view.performSyncUpdate();
    var x = position.x, y = position.y;
    var isFirst = true;
    for (var _a = 0, elements_4 = elements; _a < elements_4.length; _a++) {
        var element = elements_4[_a];
        var _b = (0, geometry_1.boundsOf)(element), width = _b.width, height = _b.height;
        if (width === 0) {
            width = 100;
        }
        if (height === 0) {
            height = 50;
        }
        if (isFirst) {
            isFirst = false;
            x -= width / 2;
            y -= height / 2;
        }
        element.setPosition({ x: x, y: y });
        y += height + 20;
    }
    return elements;
}
function makeLinkWithDirection(original, data) {
    if (!(data.sourceId === original.data.sourceId ||
        data.sourceId === original.data.targetId)) {
        throw new Error('New link source IRI is unrelated to original link');
    }
    if (!(data.targetId === original.data.sourceId ||
        data.targetId === original.data.targetId)) {
        throw new Error('New link target IRI is unrelated to original link');
    }
    var sourceId = data.sourceId === original.data.sourceId
        ? original.sourceId
        : original.targetId;
    var targetId = data.targetId === original.data.targetId
        ? original.targetId
        : original.sourceId;
    return new elements_1.Link({ typeId: data.linkTypeId, sourceId: sourceId, targetId: targetId, data: data });
}
