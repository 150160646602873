"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prependAdditionalBindings = exports.getLinkTypeInfo = exports.emptyElementInfo = exports.getLinkCount = exports.getPropertyModel = exports.getInstCount = exports.getLocalizedString = exports.enrichElement = exports.isDirectProperty = exports.isDirectLink = exports.getFilteredData = exports.getLinkStatistics = exports.getLinksTypeIds = exports.getLinksTypesOf = exports.getLinksInfo = exports.getElementTypes = exports.enrichElementsWithImages = exports.getElementsInfo = exports.triplesToElementBinding = exports.getLinkTypes = exports.getPropertyInfo = exports.getClassInfo = exports.flattenClassTree = exports.getClassTree = void 0;
var tslib_1 = require("tslib");
var sparqlModels_1 = require("./sparqlModels");
var model_1 = require("../model");
var collections_1 = require("../../viewUtils/collections");
var LABEL_URI = 'http://www.w3.org/2000/01/rdf-schema#label';
var RDF_TYPE_URI = 'http://www.w3.org/1999/02/22-rdf-syntax-ns#type';
var EMPTY_MAP = new Map();
function getClassTree(response) {
    var treeNodes = createClassMap(response.results.bindings);
    var allNodes = [];
    // createClassMap ensures we get both elements and parents and we can use treeNodes[treeNode.parent] safely
    treeNodes.forEach(function (node) {
        allNodes.push(node);
        node.parents.forEach(function (parent) {
            treeNodes.get(parent).children.push(node);
        });
        node.parents = undefined;
    });
    var withoutCycles = breakCyclesAndCalculateCounts(allNodes);
    var leafs = new Set();
    for (var _i = 0, withoutCycles_1 = withoutCycles; _i < withoutCycles_1.length; _i++) {
        var node = withoutCycles_1[_i];
        for (var _a = 0, _b = node.children; _a < _b.length; _a++) {
            var child = _b[_a];
            leafs.add(child.id);
        }
    }
    var tree = withoutCycles.filter(function (node) { return !leafs.has(node.id); });
    return tree;
}
exports.getClassTree = getClassTree;
function flattenClassTree(classTree) {
    var all = [];
    var visitClasses = function (classes) {
        for (var _i = 0, classes_1 = classes; _i < classes_1.length; _i++) {
            var model = classes_1[_i];
            all.push(model);
            visitClasses(model.children);
        }
    };
    visitClasses(classTree);
    return all;
}
exports.flattenClassTree = flattenClassTree;
function createClassMap(bindings) {
    var treeNodes = new Map();
    for (var _i = 0, bindings_1 = bindings; _i < bindings_1.length; _i++) {
        var binding = bindings_1[_i];
        if (!(0, sparqlModels_1.isRdfIri)(binding.class)) {
            continue;
        }
        var classIri = binding.class.value;
        var node = treeNodes.get(classIri);
        if (!node) {
            node = createEmptyModel(classIri);
            treeNodes.set(classIri, node);
        }
        if (binding.label) {
            appendLabel(node.label, getLocalizedString(binding.label));
        }
        if (binding.parent) {
            var parentIri = binding.parent.value;
            node.parents.add(parentIri);
        }
        if (binding.instcount) {
            node.count = getInstCount(binding.instcount);
        }
    }
    // ensuring parent will always be there
    for (var _a = 0, bindings_2 = bindings; _a < bindings_2.length; _a++) {
        var binding = bindings_2[_a];
        if (binding.parent) {
            var parentIri = binding.parent.value;
            if (!treeNodes.has(parentIri)) {
                treeNodes.set(parentIri, createEmptyModel(parentIri));
            }
        }
    }
    function createEmptyModel(iri) {
        return {
            id: iri,
            children: [],
            label: { values: [] },
            count: undefined,
            parents: new Set(),
        };
    }
    return treeNodes;
}
function breakCyclesAndCalculateCounts(tree) {
    var visiting = new Set();
    function reduceChildren(acc, node) {
        if (visiting.has(node.id)) {
            // prevent unbounded recursion
            return acc;
        }
        // no more to count
        if (!node.children) {
            return;
        }
        // ensure all children have their counts completed;
        visiting.add(node.id);
        node.children = node.children.reduce(reduceChildren, []);
        visiting.delete(node.id);
        // we have to preserve no data here. If nor element nor childs have no count information,
        // we just pass undefined upwards.
        var childCount;
        node.children.forEach(function (_a) {
            var count = _a.count;
            if (count === undefined) {
                return;
            }
            childCount = childCount === undefined ? count : childCount + count;
        });
        if (childCount !== undefined) {
            node.count =
                node.count === undefined ? childCount : node.count + childCount;
        }
        acc.push(node);
        return acc;
    }
    return tree.reduce(reduceChildren, []);
}
function getClassInfo(response) {
    var classes = {};
    for (var _i = 0, _a = response.results.bindings; _i < _a.length; _i++) {
        var binding = _a[_i];
        if (!binding.class) {
            continue;
        }
        var id = binding.class.value;
        var model = classes[id];
        if (model) {
            appendLabel(model.label, getLocalizedString(binding.label));
            var instanceCount = getInstCount(binding.instcount);
            if (instanceCount !== undefined) {
                model.count = Math.max(model.count, instanceCount);
            }
        }
        else {
            var label = getLocalizedString(binding.label);
            classes[id] = {
                id: id,
                children: [],
                label: { values: label ? [label] : [] },
                count: getInstCount(binding.instcount),
            };
        }
    }
    var classesList = [];
    for (var id in classes) {
        if (!classes.hasOwnProperty(id)) {
            continue;
        }
        var model = classes[id];
        classesList.push(model);
    }
    return classesList;
}
exports.getClassInfo = getClassInfo;
function getPropertyInfo(response) {
    var models = {};
    for (var _i = 0, _a = response.results.bindings; _i < _a.length; _i++) {
        var sProperty = _a[_i];
        var sPropertyTypeId = sProperty.property.value;
        if (models[sPropertyTypeId]) {
            if (sProperty.label) {
                var label = models[sPropertyTypeId].label;
                if (label.values.length === 1 && !label.values[0].language) {
                    label.values = [];
                }
                label.values.push(getLocalizedString(sProperty.label));
            }
        }
        else {
            models[sPropertyTypeId] = getPropertyModel(sProperty);
        }
    }
    return models;
}
exports.getPropertyInfo = getPropertyInfo;
function getLinkTypes(response) {
    var sInst = response.results.bindings;
    var linkTypes = [];
    var linkTypesMap = {};
    for (var _i = 0, sInst_1 = sInst; _i < sInst_1.length; _i++) {
        var sLink = sInst_1[_i];
        var sInstTypeId = sLink.link.value;
        if (linkTypesMap[sInstTypeId]) {
            if (sLink.label) {
                var label = linkTypesMap[sInstTypeId].label;
                if (label.values.length === 1 && !label.values[0].language) {
                    label.values = [];
                }
                label.values.push(getLocalizedString(sLink.label));
            }
        }
        else {
            linkTypesMap[sInstTypeId] = getLinkTypeInfo(sLink);
            linkTypes.push(linkTypesMap[sInstTypeId]);
        }
    }
    return linkTypes;
}
exports.getLinkTypes = getLinkTypes;
function triplesToElementBinding(triples) {
    var map = {};
    var convertedResponse = {
        head: {
            vars: ['inst', 'class', 'label', 'blankType', 'propType', 'propValue'],
        },
        results: {
            bindings: [],
        },
    };
    for (var _i = 0, triples_1 = triples; _i < triples_1.length; _i++) {
        var triple = triples_1[_i];
        var trippleId = triple.subject.value;
        if (!map[trippleId]) {
            map[trippleId] = createAndPushBinding(triple);
        }
        if (triple.predicate.value === LABEL_URI && (0, sparqlModels_1.isRdfLiteral)(triple.object)) {
            // Label
            if (map[trippleId].label) {
                map[trippleId] = createAndPushBinding(triple);
            }
            map[trippleId].label = triple.object;
        }
        else if (
        // Class
        triple.predicate.value === RDF_TYPE_URI &&
            (0, sparqlModels_1.isRdfIri)(triple.object) &&
            (0, sparqlModels_1.isRdfIri)(triple.predicate)) {
            if (map[trippleId].class) {
                map[trippleId] = createAndPushBinding(triple);
            }
            map[trippleId].class = triple.object;
        }
        else if (!(0, sparqlModels_1.isRdfBlank)(triple.object) && (0, sparqlModels_1.isRdfIri)(triple.predicate)) {
            // Property
            if (map[trippleId].propType) {
                map[trippleId] = createAndPushBinding(triple);
            }
            map[trippleId].propType = triple.predicate;
            map[trippleId].propValue = triple.object;
        }
    }
    function createAndPushBinding(tripple) {
        var binding = {
            inst: tripple.subject,
        };
        convertedResponse.results.bindings.push(binding);
        return binding;
    }
    return convertedResponse;
}
exports.triplesToElementBinding = triplesToElementBinding;
function getElementsInfo(response, types, propertyByPredicate, openWorldProperties) {
    if (types === void 0) { types = EMPTY_MAP; }
    if (propertyByPredicate === void 0) { propertyByPredicate = EMPTY_MAP; }
    if (openWorldProperties === void 0) { openWorldProperties = true; }
    var instancesMap = {};
    for (var _i = 0, _a = response.results.bindings; _i < _a.length; _i++) {
        var binding = _a[_i];
        if (!(0, sparqlModels_1.isRdfIri)(binding.inst)) {
            continue;
        }
        var iri = binding.inst.value;
        var model = instancesMap[iri];
        if (!model) {
            model = emptyElementInfo(iri);
            instancesMap[iri] = model;
        }
        enrichElement(model, binding);
    }
    if (!openWorldProperties || propertyByPredicate.size > 0) {
        for (var iri in instancesMap) {
            if (!Object.hasOwnProperty.call(instancesMap, iri)) {
                continue;
            }
            var model = instancesMap[iri];
            var modelTypes = types.get(model.id);
            model.properties = mapPropertiesByConfig(model, modelTypes, propertyByPredicate, openWorldProperties);
        }
    }
    return instancesMap;
}
exports.getElementsInfo = getElementsInfo;
function mapPropertiesByConfig(model, modelTypes, propertyByPredicate, openWorldProperties) {
    var mapped = {};
    for (var propertyIri in model.properties) {
        if (!Object.hasOwnProperty.call(model.properties, propertyIri)) {
            continue;
        }
        var properties = propertyByPredicate.get(propertyIri);
        if (properties && properties.length > 0) {
            for (var _i = 0, properties_1 = properties; _i < properties_1.length; _i++) {
                var property = properties_1[_i];
                if (typeMatchesDomain(property, modelTypes)) {
                    mapped[property.id] = model.properties[propertyIri];
                }
            }
        }
        else if (openWorldProperties) {
            mapped[propertyIri] = model.properties[propertyIri];
        }
    }
    return mapped;
}
function enrichElementsWithImages(response, elementsInfo) {
    var respElements = response.results.bindings;
    for (var _i = 0, respElements_1 = respElements; _i < respElements_1.length; _i++) {
        var respEl = respElements_1[_i];
        var elementInfo = elementsInfo[respEl.inst.value];
        if (elementInfo) {
            elementInfo.image = respEl.image.value;
        }
    }
}
exports.enrichElementsWithImages = enrichElementsWithImages;
function getElementTypes(response) {
    var types = new Map();
    for (var _i = 0, _a = response.results.bindings; _i < _a.length; _i++) {
        var binding = _a[_i];
        if ((0, sparqlModels_1.isRdfIri)(binding.inst) && (0, sparqlModels_1.isRdfIri)(binding.class)) {
            var element = binding.inst.value;
            var type = binding.class.value;
            (0, collections_1.getOrCreateSetInMap)(types, element).add(type);
        }
    }
    return types;
}
exports.getElementTypes = getElementTypes;
function getLinksInfo(response, types, linkByPredicateType, openWorldLinks) {
    if (types === void 0) { types = EMPTY_MAP; }
    if (linkByPredicateType === void 0) { linkByPredicateType = EMPTY_MAP; }
    if (openWorldLinks === void 0) { openWorldLinks = true; }
    var sparqlLinks = response.results.bindings;
    var links = new collections_1.HashMap(model_1.hashLink, model_1.sameLink);
    for (var _i = 0, sparqlLinks_1 = sparqlLinks; _i < sparqlLinks_1.length; _i++) {
        var binding = sparqlLinks_1[_i];
        var model = {
            sourceId: binding.source.value,
            linkTypeId: binding.type.value,
            targetId: binding.target.value,
            properties: {},
        };
        if (links.has(model)) {
            // this can only happen due to error in sparql or when merging properties
            if (binding.propType) {
                var existing = links.get(model);
                mergeProperties(existing.properties, binding.propType, binding.propValue);
            }
        }
        else {
            if (binding.propType) {
                mergeProperties(model.properties, binding.propType, binding.propValue);
            }
            var linkConfigs = linkByPredicateType.get(model.linkTypeId);
            if (linkConfigs && linkConfigs.length > 0) {
                for (var _a = 0, linkConfigs_1 = linkConfigs; _a < linkConfigs_1.length; _a++) {
                    var linkConfig = linkConfigs_1[_a];
                    if (typeMatchesDomain(linkConfig, types.get(model.sourceId))) {
                        var mappedModel = isDirectLink(linkConfig)
                            ? tslib_1.__assign(tslib_1.__assign({}, model), { linkTypeId: linkConfig.id }) : model;
                        links.set(mappedModel, mappedModel);
                    }
                }
            }
            else if (openWorldLinks) {
                links.set(model, model);
            }
        }
    }
    var linkArray = [];
    links.forEach(function (value) { return linkArray.push(value); });
    return linkArray;
}
exports.getLinksInfo = getLinksInfo;
function getLinksTypesOf(response) {
    var sparqlLinkTypes = response.results.bindings.filter(function (b) { return !(0, sparqlModels_1.isRdfBlank)(b.link); });
    return sparqlLinkTypes.map(function (sLink) { return getLinkCount(sLink); });
}
exports.getLinksTypesOf = getLinksTypesOf;
function getLinksTypeIds(response, linkByPredicateType, openWorldLinks) {
    if (linkByPredicateType === void 0) { linkByPredicateType = EMPTY_MAP; }
    if (openWorldLinks === void 0) { openWorldLinks = true; }
    var linkTypes = [];
    for (var _i = 0, _a = response.results.bindings; _i < _a.length; _i++) {
        var binding = _a[_i];
        if (!(0, sparqlModels_1.isRdfIri)(binding.link)) {
            continue;
        }
        var linkConfigs = linkByPredicateType.get(binding.link.value);
        if (linkConfigs && linkConfigs.length > 0) {
            for (var _b = 0, linkConfigs_2 = linkConfigs; _b < linkConfigs_2.length; _b++) {
                var linkConfig = linkConfigs_2[_b];
                var mappedLinkType = isDirectLink(linkConfig)
                    ? linkConfig.id
                    : binding.link.value;
                linkTypes.push(mappedLinkType);
            }
        }
        else if (openWorldLinks) {
            linkTypes.push(binding.link.value);
        }
    }
    return linkTypes;
}
exports.getLinksTypeIds = getLinksTypeIds;
function getLinkStatistics(response) {
    for (var _i = 0, _a = response.results.bindings; _i < _a.length; _i++) {
        var binding = _a[_i];
        if ((0, sparqlModels_1.isRdfIri)(binding.link)) {
            return getLinkCount(binding);
        }
    }
    return undefined;
}
exports.getLinkStatistics = getLinkStatistics;
function getFilteredData(response, sourceTypes, linkByPredicateType, openWorldLinks) {
    if (linkByPredicateType === void 0) { linkByPredicateType = EMPTY_MAP; }
    if (openWorldLinks === void 0) { openWorldLinks = true; }
    var instancesMap = {};
    var resultTypes = new Map();
    var outPredicates = new Map();
    var inPredicates = new Map();
    for (var _i = 0, _a = response.results.bindings; _i < _a.length; _i++) {
        var binding = _a[_i];
        if (!(0, sparqlModels_1.isRdfIri)(binding.inst) && !(0, sparqlModels_1.isRdfBlank)(binding.inst)) {
            continue;
        }
        var iri = binding.inst.value;
        var model = instancesMap[iri];
        if (!model) {
            model = emptyElementInfo(iri);
            instancesMap[iri] = model;
        }
        enrichElement(model, binding);
        if ((0, sparqlModels_1.isRdfIri)(binding.classAll)) {
            (0, collections_1.getOrCreateSetInMap)(resultTypes, iri).add(binding.classAll.value);
        }
        if (!openWorldLinks && binding.link && binding.direction) {
            var predicates = binding.direction.value === 'out' ? outPredicates : inPredicates;
            (0, collections_1.getOrCreateSetInMap)(predicates, model.id).add(binding.link.value);
        }
    }
    if (!openWorldLinks) {
        for (var _b = 0, _c = Object.keys(instancesMap); _b < _c.length; _b++) {
            var id = _c[_b];
            var model = instancesMap[id];
            var targetTypes = resultTypes.get(model.id);
            var doesMatchesDomain = matchesDomainForLink(sourceTypes, outPredicates.get(model.id), linkByPredicateType) &&
                matchesDomainForLink(targetTypes, inPredicates.get(model.id), linkByPredicateType);
            if (!doesMatchesDomain) {
                delete instancesMap[id];
            }
        }
    }
    return instancesMap;
}
exports.getFilteredData = getFilteredData;
function matchesDomainForLink(types, predicates, linkByPredicateType) {
    if (!predicates) {
        return true;
    }
    var hasMatch = false;
    predicates.forEach(function (predicate) {
        var matched = linkByPredicateType.get(predicate);
        if (matched) {
            for (var _i = 0, matched_1 = matched; _i < matched_1.length; _i++) {
                var link = matched_1[_i];
                if (typeMatchesDomain(link, types)) {
                    hasMatch = true;
                }
            }
        }
    });
    return hasMatch;
}
function isDirectLink(link) {
    // link configuration is path-based if includes any variables
    var pathBased = /[?$][a-zA-Z]+\b/.test(link.path);
    return !pathBased;
}
exports.isDirectLink = isDirectLink;
function isDirectProperty(property) {
    // property configuration is path-based if includes any variables
    var pathBased = /[?$][a-zA-Z]+\b/.test(property.path);
    return !pathBased;
}
exports.isDirectProperty = isDirectProperty;
function typeMatchesDomain(config, types) {
    if (!config.domain || config.domain.length === 0) {
        return true;
    }
    else if (!types) {
        return false;
    }
    else {
        for (var _i = 0, _a = config.domain; _i < _a.length; _i++) {
            var type = _a[_i];
            if (types.has(type)) {
                return true;
            }
        }
        return false;
    }
}
/**
 * Modifies properties with merging with new values, couls be new peroperty or new value for existing properties.
 * @param properties
 * @param propType
 * @param propValue
 */
function mergeProperties(properties, propType, propValue) {
    var property = properties[propType.value];
    if ((0, sparqlModels_1.isRdfIri)(propValue)) {
        if (!property) {
            property = { type: 'uri', values: [] };
        }
        if ((0, model_1.isIriProperty)(property) &&
            property.values.every(function (_a) {
                var value = _a.value;
                return value !== propValue.value;
            })) {
            property.values = tslib_1.__spreadArray(tslib_1.__spreadArray([], property.values, true), [propValue], false);
        }
    }
    else if ((0, sparqlModels_1.isRdfLiteral)(propValue)) {
        if (!property) {
            property = { type: 'string', values: [] };
        }
        var propertyValue_1 = getLocalizedString(propValue);
        if ((0, model_1.isLiteralProperty)(property) &&
            property.values.every(function (value) { return !isLocalizedEqual(value, propertyValue_1); })) {
            property.values = tslib_1.__spreadArray(tslib_1.__spreadArray([], property.values, true), [propertyValue_1], false);
        }
    }
    properties[propType.value] = property;
}
function enrichElement(element, sInst) {
    if (!element) {
        return;
    }
    if (sInst.label) {
        var label_1 = getLocalizedString(sInst.label);
        if (element.label.values.every(function (value) { return !isLocalizedEqual(value, label_1); })) {
            element.label.values.push(label_1);
        }
    }
    if (sInst.class &&
        element.types.indexOf(sInst.class.value) < 0) {
        element.types.push(sInst.class.value);
    }
    if (sInst.propType && sInst.propType.value !== LABEL_URI) {
        mergeProperties(element.properties, sInst.propType, sInst.propValue);
    }
}
exports.enrichElement = enrichElement;
function appendLabel(container, newLabel) {
    if (!newLabel) {
        return;
    }
    for (var _i = 0, _a = container.values; _i < _a.length; _i++) {
        var existing = _a[_i];
        if (isLocalizedEqual(existing, newLabel)) {
            return;
        }
    }
    container.values.push(newLabel);
}
function isLocalizedEqual(left, right) {
    return left.language === right.language && left.value === right.value;
}
function getLocalizedString(label) {
    if (label) {
        return {
            value: label.value,
            language: label['xml:lang'],
            datatype: label.datatype ? { value: label.datatype } : undefined,
        };
    }
    else {
        return undefined;
    }
}
exports.getLocalizedString = getLocalizedString;
function getInstCount(instcount) {
    return instcount ? +instcount.value : undefined;
}
exports.getInstCount = getInstCount;
function getPropertyModel(node) {
    var label = getLocalizedString(node.label);
    return {
        id: node.property.value,
        label: { values: label ? [label] : [] },
    };
}
exports.getPropertyModel = getPropertyModel;
function getLinkCount(sLinkType) {
    return {
        id: sLinkType.link.value,
        inCount: getInstCount(sLinkType.inCount),
        outCount: getInstCount(sLinkType.outCount),
    };
}
exports.getLinkCount = getLinkCount;
function emptyElementInfo(id) {
    var elementInfo = {
        id: id,
        label: { values: [] },
        types: [],
        properties: {},
    };
    return elementInfo;
}
exports.emptyElementInfo = emptyElementInfo;
function getLinkTypeInfo(sLinkInfo) {
    if (!sLinkInfo) {
        return undefined;
    }
    var label = getLocalizedString(sLinkInfo.label);
    return {
        id: sLinkInfo.link.value,
        label: { values: label ? [label] : [] },
        count: getInstCount(sLinkInfo.instcount),
    };
}
exports.getLinkTypeInfo = getLinkTypeInfo;
function prependAdditionalBindings(base, additional) {
    if (!additional) {
        return base;
    }
    return {
        head: { vars: base.head.vars },
        results: {
            bindings: tslib_1.__spreadArray(tslib_1.__spreadArray([], additional.results.bindings, true), base.results.bindings, true),
        },
    };
}
exports.prependAdditionalBindings = prependAdditionalBindings;
