"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Halo = void 0;
var tslib_1 = require("tslib");
var React = require("react");
var geometry_1 = require("../diagram/geometry");
var authoringState_1 = require("../editor/authoringState");
var events_1 = require("../viewUtils/events");
var async_1 = require("../viewUtils/async");
var spinner_1 = require("../viewUtils/spinner");
var CLASS_NAME = 'graph-explorer-halo';
var Halo = /** @class */ (function (_super) {
    tslib_1.__extends(Halo, _super);
    function Halo(props) {
        var _this = _super.call(this, props) || this;
        _this.listener = new events_1.EventObserver();
        _this.targetListener = new events_1.EventObserver();
        _this.queryDebouncer = new async_1.Debouncer();
        _this.queryCancellation = new async_1.Cancellation();
        _this.onElementEvent = function (data) {
            if (data.changePosition || data.changeSize || data.changeExpanded) {
                _this.forceUpdate();
            }
            if (data.changeData) {
                _this.queryAllowedActions();
            }
        };
        _this.onEstablishNewLink = function (e) {
            var point = _this.props.paperArea.pageToPaperCoords(e.pageX, e.pageY);
            _this.props.onEstablishNewLink(point);
        };
        _this.state = {};
        return _this;
    }
    Halo.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, editor = _a.editor, target = _a.target;
        this.listener.listen(editor.events, 'changeAuthoringState', function () {
            _this.queryAllowedActions();
        });
        this.listenToElement(target);
        this.queryAllowedActions();
    };
    Halo.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.target !== this.props.target) {
            this.listenToElement(this.props.target);
            this.queryAllowedActions();
        }
    };
    Halo.prototype.componentWillUnmount = function () {
        this.listener.stopListening();
        this.listenToElement(undefined);
        this.queryDebouncer.dispose();
        this.queryCancellation.abort();
    };
    Halo.prototype.listenToElement = function (element) {
        this.targetListener.stopListening();
        if (element) {
            this.targetListener.listenAny(element.events, this.onElementEvent);
        }
    };
    Halo.prototype.queryAllowedActions = function () {
        var _this = this;
        this.queryDebouncer.call(function () {
            _this.queryCancellation.abort();
            _this.queryCancellation = new async_1.Cancellation();
            _this.canLink(_this.props.target);
        });
    };
    Halo.prototype.canLink = function (target) {
        var _this = this;
        var _a = this.props, metadataApi = _a.metadataApi, editor = _a.editor;
        if (!metadataApi) {
            this.setState({ canLink: false });
            return;
        }
        var event = editor.authoringState.elements.get(target.iri);
        if (event && event.deleted) {
            this.setState({ canLink: false });
        }
        else {
            this.setState({ canLink: undefined });
            var signal = this.queryCancellation.signal;
            async_1.CancellationToken.mapCancelledToNull(signal, metadataApi.canLinkElement(target.data, signal)).then(function (canLink) {
                if (canLink === null) {
                    return;
                }
                if (_this.props.target.iri === target.iri) {
                    _this.setState({ canLink: canLink });
                }
            });
        }
    };
    Halo.prototype.render = function () {
        var _a = this.props, paperArea = _a.paperArea, editor = _a.editor, target = _a.target, navigationMenuOpened = _a.navigationMenuOpened, onToggleNavigationMenu = _a.onToggleNavigationMenu, onAddToFilter = _a.onAddToFilter, onExpand = _a.onExpand, onFollowLink = _a.onFollowLink;
        if (!target) {
            return React.createElement("div", { className: CLASS_NAME, style: { display: 'none' } });
        }
        var bbox = (0, geometry_1.boundsOf)(target);
        var _b = paperArea.paperToScrollablePaneCoords(bbox.x, bbox.y), x0 = _b.x, y0 = _b.y;
        var _c = paperArea.paperToScrollablePaneCoords(bbox.x + bbox.width, bbox.y + bbox.height), x1 = _c.x, y1 = _c.y;
        var MARGIN = 5;
        var style = {
            left: x0 - MARGIN,
            top: y0 - MARGIN,
            width: x1 - x0 + MARGIN * 2,
            height: y1 - y0 + MARGIN * 2,
        };
        return (React.createElement("div", { className: CLASS_NAME, style: style },
            this.renderRemoveOrDeleteButton(),
            onToggleNavigationMenu && (React.createElement("div", { className: "".concat(CLASS_NAME, "__navigate ") +
                    "".concat(CLASS_NAME, "__navigate--").concat(navigationMenuOpened ? 'closed' : 'open'), role: "button", title: "Open a dialog to navigate to connected elements", onClick: onToggleNavigationMenu })),
            onFollowLink && (React.createElement("a", { className: "".concat(CLASS_NAME, "__folow"), href: target.iri, role: "button", title: "Jump to resource", onClick: function (e) { return onFollowLink(target, e); } })),
            onAddToFilter && (React.createElement("div", { className: "".concat(CLASS_NAME, "__add-to-filter"), role: "button", title: "Search for connected elements", onClick: onAddToFilter })),
            onExpand && (React.createElement("div", { className: "".concat(CLASS_NAME, "__expand ") +
                    "".concat(CLASS_NAME, "__expand--").concat(target.isExpanded ? 'closed' : 'open'), role: "button", title: "Expand an element to reveal additional properties", onClick: onExpand })),
            editor.inAuthoringMode ? this.renderEstablishNewLinkButton() : null));
    };
    Halo.prototype.renderRemoveOrDeleteButton = function () {
        var _a = this.props, editor = _a.editor, target = _a.target, onRemove = _a.onRemove;
        if (!onRemove) {
            return null;
        }
        var isNewElement = authoringState_1.AuthoringState.isNewElement(editor.authoringState, target.iri);
        return (React.createElement("div", { className: isNewElement ? "".concat(CLASS_NAME, "__delete") : "".concat(CLASS_NAME, "__remove"), role: "button", title: isNewElement
                ? 'Delete new element'
                : 'Remove an element from the diagram', onClick: onRemove }));
    };
    Halo.prototype.renderEstablishNewLinkButton = function () {
        var onEstablishNewLink = this.props.onEstablishNewLink;
        var canLink = this.state.canLink;
        if (!onEstablishNewLink) {
            return null;
        }
        if (canLink === undefined) {
            return (React.createElement("div", { className: "".concat(CLASS_NAME, "__establish-connection-spinner") },
                React.createElement(spinner_1.HtmlSpinner, { width: 20, height: 20 })));
        }
        var title = canLink
            ? 'Establish connection'
            : 'Establishing connection is unavailable for the selected element';
        return (React.createElement("button", { className: "".concat(CLASS_NAME, "__establish-connection"), title: title, onMouseDown: this.onEstablishNewLink, disabled: !canLink }));
    };
    return Halo;
}(React.Component));
exports.Halo = Halo;
