"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getContentFittingBoxForLayout = exports.forceLayout = exports.removeOverlaps = exports.placeElementsAround = exports.calculateAveragePosition = exports.applyLayout = exports.calculateLayout = exports.biasFreePadded = exports.padded = exports.uniformGrid = exports.translateToPositiveQuadrant = exports.groupRemoveOverlaps = exports.groupForceLayout = void 0;
var cola = require("webcola");
var geometry_1 = require("../diagram/geometry");
var events_1 = require("./events");
var paperArea_1 = require("../diagram/paperArea");
function groupForceLayout(params) {
    var layout = new cola.Layout()
        .nodes(params.nodes)
        .links(params.links)
        .avoidOverlaps(params.avoidOvelaps)
        .convergenceThreshold(1e-9)
        .jaccardLinkLengths(params.preferredLinkLength)
        .handleDisconnected(true);
    layout.start(30, 0, 10, undefined, false);
}
exports.groupForceLayout = groupForceLayout;
function groupRemoveOverlaps(nodes) {
    var nodeRectangles = [];
    for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
        var node = nodes_1[_i];
        nodeRectangles.push(new cola.Rectangle(node.x, node.x + node.width, node.y, node.y + node.height));
    }
    cola.removeOverlaps(nodeRectangles);
    for (var i = 0; i < nodeRectangles.length; i++) {
        var node = nodes[i];
        var rectangle = nodeRectangles[i];
        node.x = rectangle.x;
        node.y = rectangle.y;
    }
}
exports.groupRemoveOverlaps = groupRemoveOverlaps;
function translateToPositiveQuadrant(positions, offset) {
    var minX = Infinity, minY = Infinity;
    positions.forEach(function (position) {
        minX = Math.min(minX, position.x);
        minY = Math.min(minY, position.y);
    });
    var x = offset.x, y = offset.y;
    positions.forEach(function (position, key) {
        positions.set(key, {
            x: position.x - minX + x,
            y: position.y - minY + y,
        });
    });
}
exports.translateToPositiveQuadrant = translateToPositiveQuadrant;
function uniformGrid(params) {
    return function (cellIndex) {
        var row = Math.floor(cellIndex / params.rows);
        var column = cellIndex - row * params.rows;
        return {
            x: column * params.cellSize.x,
            y: row * params.cellSize.y,
            width: params.cellSize.x,
            height: params.cellSize.y,
        };
    };
}
exports.uniformGrid = uniformGrid;
function padded(nodes, padding, transform) {
    if (padding) {
        for (var _i = 0, nodes_2 = nodes; _i < nodes_2.length; _i++) {
            var node = nodes_2[_i];
            node.x -= padding.x;
            node.y -= padding.y;
            node.width += 2 * padding.x;
            node.height += 2 * padding.y;
        }
    }
    transform();
    if (padding) {
        for (var _a = 0, nodes_3 = nodes; _a < nodes_3.length; _a++) {
            var node = nodes_3[_a];
            node.x += padding.x;
            node.y += padding.y;
            node.width -= 2 * padding.x;
            node.height -= 2 * padding.y;
        }
    }
}
exports.padded = padded;
function biasFreePadded(nodes, padding, transform) {
    var nodeSizeMap = new Map();
    var possibleCompression = { x: Infinity, y: Infinity };
    for (var _i = 0, nodes_4 = nodes; _i < nodes_4.length; _i++) {
        var node = nodes_4[_i];
        nodeSizeMap.set(node.id, { width: node.width, height: node.height });
        var maxSide = Math.max(node.width, node.height);
        var compressionX = node.width ? maxSide / node.width : 1;
        var compressionY = node.height ? maxSide / node.height : 1;
        possibleCompression.x = Math.min(1 + (compressionX - 1), possibleCompression.x);
        possibleCompression.y = Math.min(1 + (compressionY - 1), possibleCompression.y);
        node.height = maxSide;
        node.width = maxSide;
    }
    padded(nodes, padding, function () { return transform(); });
    var fittingBox = getContentFittingBoxForLayout(nodes);
    for (var _a = 0, nodes_5 = nodes; _a < nodes_5.length; _a++) {
        var node = nodes_5[_a];
        var size = nodeSizeMap.get(node.id);
        node.x = (node.x - fittingBox.x) / possibleCompression.x + fittingBox.x;
        node.y = (node.y - fittingBox.y) / possibleCompression.y + fittingBox.y;
        node.height = size.height;
        node.width = size.width;
    }
}
exports.biasFreePadded = biasFreePadded;
function calculateLayout(params) {
    var grouping = (0, geometry_1.computeGrouping)(params.model.elements);
    var layoutFunction = params.layoutFunction, model = params.model, fixedElements = params.fixedElements, selectedElements = params.selectedElements;
    if (selectedElements && selectedElements.size <= 1) {
        return {
            positions: new Map(),
            nestedLayouts: [],
            keepAveragePosition: false,
        };
    }
    return internalRecursion(params.group);
    function internalRecursion(group) {
        var elementsToProcess = group
            ? grouping.get(group)
            : model.elements.filter(function (el) { return el.group === undefined; });
        var elements = selectedElements
            ? elementsToProcess.filter(function (el) { return selectedElements.has(el); })
            : elementsToProcess;
        var nestedLayouts = [];
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var element = elements_1[_i];
            if (grouping.has(element.id)) {
                nestedLayouts.push(internalRecursion(element.id));
            }
        }
        var nodes = [];
        var nodeById = {};
        for (var _a = 0, elements_2 = elements; _a < elements_2.length; _a++) {
            var element = elements_2[_a];
            var _b = (0, geometry_1.boundsOf)(element), x = _b.x, y = _b.y, width = _b.width, height = _b.height;
            var node = {
                id: element.id,
                x: x,
                y: y,
                width: width,
                height: height,
                fixed: fixedElements && fixedElements.has(element) ? 1 : 0,
            };
            nodeById[element.id] = node;
            nodes.push(node);
        }
        var links = [];
        for (var _c = 0, _d = model.links; _c < _d.length; _c++) {
            var link = _d[_c];
            if (!model.isSourceAndTargetVisible(link)) {
                continue;
            }
            var source = model.sourceOf(link);
            var target = model.targetOf(link);
            var sourceNode = nodeById[source.id];
            var targetNode = nodeById[target.id];
            if (sourceNode && targetNode) {
                links.push({ source: sourceNode, target: targetNode });
            }
        }
        layoutFunction(nodes, links, group);
        var positions = new Map();
        for (var _e = 0, nodes_6 = nodes; _e < nodes_6.length; _e++) {
            var node = nodes_6[_e];
            positions.set(node.id, { x: node.x, y: node.y });
        }
        return {
            positions: positions,
            group: group,
            nestedLayouts: nestedLayouts,
            keepAveragePosition: Boolean(selectedElements),
        };
    }
}
exports.calculateLayout = calculateLayout;
function applyLayout(model, layout) {
    var _a = layout, positions = _a.positions, group = _a.group, nestedLayouts = _a.nestedLayouts, keepAveragePosition = _a.keepAveragePosition;
    var elements = model.elements.filter(function (_a) {
        var id = _a.id;
        return positions.has(id);
    });
    for (var _i = 0, nestedLayouts_1 = nestedLayouts; _i < nestedLayouts_1.length; _i++) {
        var nestedLayout = nestedLayouts_1[_i];
        applyLayout(model, nestedLayout);
    }
    if (group) {
        var offset = (0, paperArea_1.getContentFittingBox)(elements, []);
        translateToPositiveQuadrant(positions, offset);
    }
    var averagePosition = keepAveragePosition
        ? calculateAveragePosition(elements)
        : undefined;
    for (var _b = 0, elements_3 = elements; _b < elements_3.length; _b++) {
        var element = elements_3[_b];
        element.setPosition(positions.get(element.id));
    }
    if (keepAveragePosition) {
        var newAveragePosition = calculateAveragePosition(elements);
        var averageDiff_1 = {
            x: averagePosition.x - newAveragePosition.x,
            y: averagePosition.y - newAveragePosition.y,
        };
        positions.forEach(function (position, elementId) {
            var element = model.getElement(elementId);
            element.setPosition({
                x: position.x + averageDiff_1.x,
                y: position.y + averageDiff_1.y,
            });
        });
    }
}
exports.applyLayout = applyLayout;
function calculateAveragePosition(position) {
    var xSum = 0;
    var ySum = 0;
    for (var _i = 0, position_1 = position; _i < position_1.length; _i++) {
        var element = position_1[_i];
        xSum += element.position.x + element.size.width / 2;
        ySum += element.position.y + element.size.height / 2;
    }
    return {
        x: xSum / position.length,
        y: ySum / position.length,
    };
}
exports.calculateAveragePosition = calculateAveragePosition;
function placeElementsAround(params) {
    var model = params.model, elements = params.elements, targetElement = params.targetElement, prefferedLinksLength = params.prefferedLinksLength;
    var targetElementBounds = (0, geometry_1.boundsOf)(targetElement);
    var targetPosition = {
        x: targetElementBounds.x + targetElementBounds.width / 2,
        y: targetElementBounds.y + targetElementBounds.height / 2,
    };
    var outgoingAngle = 0;
    if (targetElement.links.length > 0) {
        var averageSourcePosition = calculateAveragePosition(targetElement.links.map(function (link) {
            var linkSource = model.sourceOf(link);
            return linkSource !== targetElement ? linkSource : model.targetOf(link);
        }));
        var vectorDiff = {
            x: targetPosition.x - averageSourcePosition.x,
            y: targetPosition.y - averageSourcePosition.y,
        };
        if (vectorDiff.x !== 0 || vectorDiff.y !== 0) {
            outgoingAngle = Math.atan2(vectorDiff.y, vectorDiff.x);
        }
    }
    var step = Math.min(Math.PI / elements.length, Math.PI / 6);
    var elementsSteck = [].concat(elements);
    var placeElementFromSteck = function (curAngle, element) {
        if (element) {
            var size = element.size;
            element.setPosition({
                x: targetPosition.x +
                    prefferedLinksLength * Math.cos(curAngle) -
                    size.width / 2,
                y: targetPosition.y +
                    prefferedLinksLength * Math.sin(curAngle) -
                    size.height / 2,
            });
        }
    };
    var isOddLength = elementsSteck.length % 2 === 0;
    if (isOddLength) {
        for (var angle = step / 2; elementsSteck.length > 0; angle += step) {
            placeElementFromSteck(outgoingAngle - angle, elementsSteck.pop());
            placeElementFromSteck(outgoingAngle + angle, elementsSteck.pop());
        }
    }
    else {
        placeElementFromSteck(outgoingAngle, elementsSteck.pop());
        for (var angle = step; elementsSteck.length > 0; angle += step) {
            placeElementFromSteck(outgoingAngle - angle, elementsSteck.pop());
            placeElementFromSteck(outgoingAngle + angle, elementsSteck.pop());
        }
    }
    return new Promise(function (resolve) {
        var listener = new events_1.EventObserver();
        listener.listen(model.events, "changeCells", function () {
            listener.stopListening();
            removeOverlaps({
                model: model,
                padding: { x: 15, y: 15 },
            });
            resolve(true);
        });
    });
}
exports.placeElementsAround = placeElementsAround;
function removeOverlaps(params) {
    var padding = params.padding, model = params.model, group = params.group, fixedElements = params.fixedElements, selectedElements = params.selectedElements;
    return calculateLayout({
        model: model,
        group: group,
        fixedElements: fixedElements,
        selectedElements: selectedElements,
        layoutFunction: function (nodes) {
            padded(nodes, padding, function () { return groupRemoveOverlaps(nodes); });
        },
    });
}
exports.removeOverlaps = removeOverlaps;
function forceLayout(params) {
    var model = params.model, group = params.group, fixedElements = params.fixedElements, selectedElements = params.selectedElements;
    return calculateLayout({
        model: model,
        group: group,
        fixedElements: fixedElements,
        selectedElements: selectedElements,
        layoutFunction: function (nodes, links) {
            if (fixedElements && fixedElements.size > 0) {
                biasFreePadded(nodes, { x: 50, y: 50 }, function () {
                    return groupForceLayout({
                        nodes: nodes,
                        links: links,
                        preferredLinkLength: 200,
                        avoidOvelaps: true,
                    });
                });
            }
            else {
                groupForceLayout({ nodes: nodes, links: links, preferredLinkLength: 200 });
                biasFreePadded(nodes, { x: 50, y: 50 }, function () {
                    return groupRemoveOverlaps(nodes);
                });
            }
        },
    });
}
exports.forceLayout = forceLayout;
function getContentFittingBoxForLayout(nodes) {
    var minX = Infinity, minY = Infinity;
    var maxX = -Infinity, maxY = -Infinity;
    for (var _i = 0, nodes_7 = nodes; _i < nodes_7.length; _i++) {
        var node = nodes_7[_i];
        var x = node.x, y = node.y, width = node.width, height = node.height;
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x + width);
        maxY = Math.max(maxY, y + height);
    }
    return {
        x: Number.isFinite(minX) ? minX : 0,
        y: Number.isFinite(minY) ? minY : 0,
        width: Number.isFinite(minX) && Number.isFinite(maxX) ? maxX - minX : 0,
        height: Number.isFinite(minY) && Number.isFinite(maxY) ? maxY - minY : 0,
    };
}
exports.getContentFittingBoxForLayout = getContentFittingBoxForLayout;
