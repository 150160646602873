"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventObserver = exports.EventSource = void 0;
var EventSource = /** @class */ (function () {
    function EventSource() {
        this.listeners = new Map();
    }
    EventSource.prototype.on = function (eventKey, listener) {
        var listeners = this.listeners.get(eventKey);
        if (!listeners) {
            listeners = [];
            this.listeners.set(eventKey, listeners);
        }
        listeners.push(listener);
    };
    EventSource.prototype.onAny = function (listener) {
        var listeners = this.anyListeners;
        if (!listeners) {
            listeners = [];
            this.anyListeners = listeners;
        }
        listeners.push(listener);
    };
    EventSource.prototype.off = function (eventKey, listener) {
        var listeners = this.listeners.get(eventKey);
        if (!listeners) {
            return;
        }
        var index = listeners.indexOf(listener);
        if (index >= 0) {
            listeners.splice(index, 1);
        }
    };
    EventSource.prototype.offAny = function (listener) {
        var listeners = this.anyListeners;
        if (!listeners) {
            return;
        }
        var index = listeners.indexOf(listener);
        if (index >= 0) {
            listeners.splice(index, 1);
        }
    };
    EventSource.prototype.trigger = function (eventKey, data) {
        var _a;
        var listeners = this.listeners.get(eventKey);
        if (listeners) {
            for (var _i = 0, listeners_1 = listeners; _i < listeners_1.length; _i++) {
                var listener = listeners_1[_i];
                listener(data, eventKey);
            }
        }
        if (this.anyListeners) {
            for (var _b = 0, _c = this.anyListeners; _b < _c.length; _b++) {
                var anyListener = _c[_b];
                anyListener((_a = {}, _a[eventKey] = data, _a), eventKey);
            }
        }
    };
    return EventSource;
}());
exports.EventSource = EventSource;
var EventObserver = /** @class */ (function () {
    function EventObserver() {
        this.unsubscribeByKey = new Map();
        this.onDispose = [];
    }
    EventObserver.prototype.listen = function (events, eventKey, listener) {
        events.on(eventKey, listener);
        this.onDispose.push(function () { return events.off(eventKey, listener); });
    };
    EventObserver.prototype.listenAny = function (events, listener) {
        events.onAny(listener);
        this.onDispose.push(function () { return events.offAny(listener); });
    };
    EventObserver.prototype.listenOnce = function (events, eventKey, listener) {
        var handled = false;
        var onceListener = function (data, key) {
            handled = true;
            events.off(eventKey, onceListener);
            listener(data, key);
        };
        events.on(eventKey, onceListener);
        this.onDispose.push(function () {
            if (handled) {
                return;
            }
            events.off(eventKey, onceListener);
        });
    };
    EventObserver.prototype.stopListening = function () {
        for (var _i = 0, _a = this.onDispose; _i < _a.length; _i++) {
            var unsubscribe = _a[_i];
            unsubscribe();
        }
        this.onDispose.length = 0;
        this.unsubscribeByKey.forEach(function (unsubscribers) {
            for (var _i = 0, unsubscribers_1 = unsubscribers; _i < unsubscribers_1.length; _i++) {
                var unsubscribe = unsubscribers_1[_i];
                unsubscribe();
            }
        });
        this.unsubscribeByKey.clear();
    };
    return EventObserver;
}());
exports.EventObserver = EventObserver;
